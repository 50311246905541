import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    withStyles,
    Grid,
    Button,
    Tooltip,
    Typography,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Dialog, IconButton, makeStyles
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    ArchiveRounded,
    CloseRounded, GamepadRounded, GraphicEqRounded, PauseRounded,
    PlayArrowRounded, PlayArrowSharp, RefreshRounded, Settings, SettingsApplicationsRounded,
    StopRounded
} from "@material-ui/icons";
import {loadMentorStats, toggleStatsDialog} from "../../../../sagas/trainingSession/actions";
import {
    GAME_ABANDONED,
    GAME_ENDED,
    GAME_STARTED, GAME_STOPPED, GAME_TIMEOVER, MENTOR, SESSION_ARCHIVED,
    SESSION_ENDED,
    SESSION_PAUSED,
    SESSION_STARTED,
    SESSION_STOPPED
} from "../../../../constants/params";
import clsx from "clsx";
import {Doughnut} from "react-chartjs-2";
import {LoadingComponent} from "../../../loading/loaders";
import 'chartjs-plugin-datalabels';
import {Transition} from "../../../../themes/effects";
import {logout} from "../../../../sagas/trainingSession/apis";
import GameStatsDonutChart from "../stats/charts/GameStatsDonutChart";
import GameSettings from "./GameSettings";
import {TRAINING_SESSION_GAME_SETTINGS_CHANGE} from "../../../../sagas/trainingSession/constants";
import {ReactComponent as PodiumIcon} from "../../../../images/podium.svg";
import Podium from "../stats/Podium";
import {withRouter} from "react-router";
const styles = (theme)=>({
    main: {
        minHeight: 800,
        background: "transparent",
        color: "white",
        maxWidth: "1280"
    },
    btn:{
        maxWidth: 300,
        color: "#fff"
    },
    pauseBtn:{
        backgroundColor: "#6d6a6a",
        "&:hover":{
            backgroundColor: "#3c3c3c",
        }
    },
    stopBtn:{
        backgroundColor: "#dd7036",
        "&:hover":{
            backgroundColor: "#e05a13",
        }
    },
    endBtn:{
        backgroundColor: "#dd3636",
        "&:hover":{
            backgroundColor: "#d91414",
        },
        width: "600px",
        marginTop: "3rem",
        [theme.breakpoints.down("xs")]:{
            width: 250
        }
    },
    startBtn:{
        backgroundColor:"#257e30",
        width: "600px",
        "&:hover":{
            cursor: "pointer",
            backgroundColor:"#057913",
        }
    },
    restartBtn:{
        backgroundColor:"#257e30",
        "&:hover":{
            cursor: "pointer",
            backgroundColor:"#057913",
        }
    },
    newSessionBtn:{

    },
    sessionController:{
        marginTop: "2rem"
    },
    currentStatusTitle:{
        color: "#ffffffc2",
        background:"#efcd07",
        padding: "1rem",
        "&.notStarted":{
            background: "#a0a0a0"
        },
        "&.started":{
            background: "#257e30"
        },
        "&.ended":{
            background: "#dd3636"
        },
        "&.paused":{
            background: "#6d6a6a"
        },
        "&.stopped":{
            background: "#dd7036"
        },
        "&.archived":{
            background: "#2d2d2d"
        }
    },
    statusTitle:{
        color: "#ffffff",
        fontWeight: "bold"
    },
    gameSettingsBtn:{
        position: "absolute",
        left: 5,
        top: 5,
        [theme.breakpoints.down("xs")]:{
            "& .btnLabel":{
                display: "none"
            },
            "& span":{
                marginRight: 0
            }
        }
    },
    content:{
        [theme.breakpoints.up("md")]:{
            marginTop: "4rem"
        },
        [theme.breakpoints.down("sm")]:{
            marginTop: "4rem"
        }
    },
    archiveSessionBtn:{
        backgroundColor: "#b8b7b7",
        "&:hover":{
            backgroundColor: "#949393"
        }
    }
});

const useStyles = makeStyles(styles);

function ControlRoom(props){
    const {t,classes,match} = props;
    const dispatch = useDispatch();
    const {user,trainingSession,socket,stats,players} = useSelector((state)=>state.trainingSessionState);
    const mentorStats = stats.mentor;
    const mentorStatsData = Array.isArray(mentorStats.data)?mentorStats.data:[];
    const sessionStatus = trainingSession.status;
    const forMentor = (user && user.type === MENTOR) || match.path.match(/admin(\/)?$/ig);
    const changeSessionStatus = (status,otherData)=>{
        socket.sendMessage("mentorChangeSessionStatus",{
            status: status,
            ...otherData
        });
    }
    useEffect(()=>{
        if (!mentorStats || mentorStats.loading===null){
            dispatch(loadMentorStats({
                id: trainingSession.id,
                forMentor
            }));
        }
    });
    const dataChartStats = {
        [GAME_STARTED]: {
            label: t("label.game.status."+GAME_STARTED),
            bgColor: "rgba(255, 99, 132, 0.5)",
            bdColor: "rgba(255, 99, 132, 1)",
            count: 0
        },
        [GAME_STOPPED]: {
            label: t("label.game.status."+GAME_STOPPED),
            bgColor: 'rgba(54, 162, 235, 0.5)',
            bdColor: 'rgba(54, 162, 235, 1)',
            count: 0
        },
        [GAME_ENDED]: {
            label: t("label.game.status."+GAME_ENDED),
            bgColor: 'rgba(255, 206, 86, 0.5)',
            bdColor: 'rgba(255, 206, 86, 1)',
            count: 0
        },
        [GAME_TIMEOVER]: {
            label: t("label.game.status."+GAME_TIMEOVER),
            bgColor: 'rgba(75, 192, 192, 0.5)',
            bdColor: 'rgba(75, 192, 192, 1)',
            count: 0
        },
        [GAME_ABANDONED]: {
            label: t("label.game.status."+GAME_ABANDONED),
            bgColor: 'rgba(153, 102, 255, 0.5)',
            bdColor: 'rgba(153, 102, 255, 1)',
            count: 0
        },
    };
    let totalGames = 0;
    mentorStatsData.forEach((statItem)=>{
        const status = statItem.status;
        if (dataChartStats.hasOwnProperty(status)){
            dataChartStats[status].count = statItem.count||0;
            totalGames+=dataChartStats[status].count;
        }
    });

    const [showConfirmNewSession,setShowConfirmNewSession] = useState(false);
    const [showConfirmArchiveSession,setShowConfirmArchiveSession] = useState(false);
    const [showGameSettingsBoard,setShowGameSettingsBoard] = useState(false);

    return <React.Fragment>
        <Button onClick={(e)=>{
            setShowGameSettingsBoard(true);
        }} color={"secondary"} variant={"contained"} startIcon={<Settings/>} className={classes.gameSettingsBtn}><span className={"btnLabel"}>{t("label.training_session.game_settings")}</span></Button>
        <Grid container className={classes.content} justify={"center"} alignItems={"center"} direction={"column"}>
            <Grid item container direction={"column"}>
                {/*<Typography color={"secondary"} style={{fontWeight: "bold",fontSize:"1.5rem"}} align={"center"}>{t("label.training_session.stats")}</Typography>*/}
                {/*<Grid item container justify={"flex-start"}>*/}
                {/*    <Button variant={"contained"} color={"secondary"} onClick={(e)=>{*/}
                {/*        dispatch(toggleStatsDialog(true));*/}
                {/*    }}><GraphicEqRounded/> {t("label.training_session.stats")}</Button>*/}
                {/*</Grid>*/}
                <Grid item container direction={"row"} className={classes.statsBlock}>
                    <Grid xs={12} sm={12} item className={classes.gameStats}>
                        {mentorStats.loading === false?<GameStatsDonutChart data={dataChartStats}/>:<LoadingComponent/>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.sessionController} direction={"column"} alignItems={"center"}>
                <h2 className={classes.currentStatusTitle+" "+(sessionStatus||"notStarted")}>{t("label.training_session.status.title")}: <span className={classes.statusTitle+" "+(sessionStatus||"notStarted")}>{t("label.training_session.status."+(sessionStatus||"not_started"))}</span></h2>
                {sessionStatus===null && <Grid item>
                    <Button onClick={(e)=>changeSessionStatus(SESSION_STARTED)} variant={"contained"} className={clsx(classes.btn,{[classes.startBtn]:true})}><PlayArrowRounded fontSize={"large"}/> {t("label.training_session.start_session")}</Button>
                </Grid>}
                {![null,SESSION_ENDED,SESSION_ARCHIVED].includes(sessionStatus) && <Grid item container justify={"center"} direction={"row"}>
                    {[SESSION_PAUSED,SESSION_STOPPED].includes(sessionStatus) &&
                    <Button onClick={(e)=>changeSessionStatus(SESSION_STARTED)} variant={"contained"} className={clsx(classes.btn,{[classes.restartBtn]:true})}><RefreshRounded fontSize={"large"}/> {t("label.training_session.restart_session")}</Button>}
                    {sessionStatus===SESSION_STARTED && <Button onClick={(e)=>changeSessionStatus(SESSION_PAUSED)} variant={"contained"} className={clsx(classes.btn,{[classes.pauseBtn]:true})}><PauseRounded fontSize={"large"}/> {t("label.training_session.pause_session")}</Button>}
                    {[SESSION_PAUSED,SESSION_STARTED].includes(sessionStatus) && <Button onClick={(e)=>changeSessionStatus(SESSION_STOPPED)} variant={"contained"} className={clsx(classes.btn,{[classes.stopBtn]:true})}><StopRounded fontSize={"large"}/> {t("label.training_session.stop_session")}</Button>}
                </Grid>}
                {![SESSION_ARCHIVED,SESSION_ENDED].includes(sessionStatus) && <Grid item>
                    <Button onClick={(e)=>changeSessionStatus(SESSION_ENDED)} variant={"contained"} className={clsx(classes.btn,{[classes.endBtn]:true})}><CloseRounded fontSize={"large"}/> {t("label.training_session.end_session")}</Button>
                </Grid>}
                {[SESSION_ARCHIVED,SESSION_ENDED].includes(sessionStatus) && <Grid spacing={1} item container direction={"column"} justify={"center"} alignItems={"center"}>
                    {sessionStatus === SESSION_ENDED && <Grid item xs={12}>
                        <Button onClick={(e)=>setShowConfirmArchiveSession(true)} color={"primary"} variant={"contained"} className={clsx(classes.btn,{[classes.archiveSessionBtn]:true})}><ArchiveRounded fontSize={"large"}/> {t("label.training_session.archive_session")}</Button>
                    </Grid>}
                    <Grid item xs={12}>
                        <Button onClick={(e)=>setShowConfirmNewSession(true)} color={"primary"} variant={"contained"} className={clsx(classes.btn,{[classes.newSessionBtn]:true})}><PlayArrowSharp fontSize={"large"}/> {t("label.training_session.start_new_session")}</Button>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Podium/>*/}
                    {/*</Grid>*/}
                </Grid>}
            </Grid>
            <Dialog
                open={showConfirmArchiveSession}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle className={classes.popupTitle}>{t("title.training_session.archive_session")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("text.training_session.confirm_archive_session")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={(e)=>{
                        setShowConfirmArchiveSession(false);
                        changeSessionStatus(SESSION_ARCHIVED);
                    }} type={"button"} color="secondary">
                        <PlayArrowRounded fontSize={"large"}/> {t("label.confirm")}
                    </Button>
                    <Button variant={"contained"} onClick={(e)=>{
                        setShowConfirmArchiveSession(false);
                    }} type={"button"} color="default">
                        <CloseRounded fontSize={"large"}/> {t("label.close")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showConfirmNewSession}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle className={classes.popupTitle}>{t("title.training_session.create_new_session")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("text.training_session.confirm_create_new_session")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={(e)=>{
                        setShowConfirmNewSession(false);
                        changeSessionStatus(SESSION_ARCHIVED,{createNewSession:true});
                    }} type={"button"} color="secondary">
                        <PlayArrowRounded fontSize={"large"}/> {t("label.confirm")}
                    </Button>
                    <Button variant={"contained"} onClick={(e)=>{
                        setShowConfirmNewSession(false);
                    }} type={"button"} color="default">
                        <CloseRounded fontSize={"large"}/> {t("label.close")}
                    </Button>
                </DialogActions>
            </Dialog>
            {showGameSettingsBoard && <GameSettings setShowGameSettingsBoard={(value)=>{
                setShowGameSettingsBoard(value);
                if (!value){
                    dispatch({
                        type: TRAINING_SESSION_GAME_SETTINGS_CHANGE.CLOSE
                    });
                }
            }}/>}
        </Grid>
    </React.Fragment>;
}

export default withRouter(withStyles(styles)(withTranslation()(ControlRoom)));