import {routerMiddleware} from "connected-react-router";
import {persistStore} from "redux-persist";
import { createStore, applyMiddleware } from 'redux';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas";
import rootReducer from './reducers';

import { composeWithDevTools } from 'redux-devtools-extension';
import {LOGOUT} from "./auth/constants";
export default (history, initialState) => {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [sagaMiddleware];

    if (['development',"dev"].includes(process.env.NODE_ENV)) {
        const loggerMiddleware = createLogger();
        middleware.push(loggerMiddleware);
    }

    const store = createStore((state,action)=>{
        if (action.type===LOGOUT.SUCCESS){
            return rootReducer(history)(undefined,action);
        }
        return rootReducer(history)(state,action);
    }, initialState, composeWithDevTools(
        applyMiddleware(routerMiddleware(history), ...middleware)
    ));

    sagaMiddleware.run(rootSaga);

    const persistor = persistStore(store);
    return { store, persistor };
}