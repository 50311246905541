import * as React from "react";
import { Create, Edit,List, SimpleForm, TextInput,FunctionField, DateInput, ReferenceManyField, Datagrid, TextField, DateField,SelectField, EditButton,useTranslate,BooleanInput,NumberInput,required } from 'react-admin';
import {withTranslation} from "react-i18next";
import {
    Button,
    Container,
    CssBaseline,
    FormControl,
    FormLabel,
    Grid,
    InputAdornment,
    MuiThemeProvider
} from "@material-ui/core";
import {
    AccountCircle,
    Link,
    DateRange,
    Security,
    TextFields,
    Settings,
    PanoramaFishEye,
    Visibility
} from "@material-ui/icons";
import {useEffect, useState} from "react";
import SwitchBase from "@material-ui/core/internal/SwitchBase";
import {PrimarySwitch} from "../../../themes/components/switch";
import {BACKOFFICE, BACKOFFICE_TRAINING_SESSION_VIEW} from "../../../constants/links";
import TrainingSession from "../../trainingSession/TrainingSession";
import {theme} from "../../../themes/muiTheme";
import {useHistory, useRouteMatch} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {resetTrainingSession} from "../../../sagas/trainingSession/actions";
import {LoadingCircular} from "../../loading/loaders";


const TrainingSessionViewButton = (props) => {
    console.log(props);
    const translate = useTranslate();
    const history = useHistory();
    const showTrainingSession = (e)=>{
        history.push(BACKOFFICE_TRAINING_SESSION_VIEW.replace(":id",props.record.id));
    }
   return <Button size={"small"} variant={"contained"} color={"primary"} startIcon={<Visibility/>} onClick={showTrainingSession}>
       {translate("label.see")}
   </Button>;
}
export function TrainingSessionList(props){
    console.log(props);
    const translate = useTranslate();
    return <List bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="slug" />
            <DateField source="createdAt" />
            {/*<DateField source="startAt" />*/}
            {/*<DateField source="endAt" />*/}
            <TextField source="title" />
            <TextField source="description" />
            <TextField source="maxTokenPerStep" />
            <TextField source="tokensPerGame" />
            <FunctionField label={translate("resources.training-sessions.fields.status")} render={(row)=>{
                return translate("label.training_session.status."+row.status);
            }} />
            <TrainingSessionViewButton/>
            <EditButton variant={"contained"}/>
        </Datagrid>
    </List>
}

export function TrainingSessionCreate(props){
    return <Create {...props}>
        <SimpleForm>
            <TrainingSessionForm isCreateForm={true}/>
        </SimpleForm>
    </Create>
}

export function TrainingSessionEdit(props){
    return <Edit {...props}>
        <SimpleForm>
            <TrainingSessionForm/>
        </SimpleForm>
    </Edit>
}

export function TrainingSessionForm(props){
    const {isCreateForm} = props;
    const translate = useTranslate();
    const [playerPassword,setPlayerPassword] = useState(false);
    const [mentorPassword,setMentorPassword] = useState(false);
    const onFieldChange = (value,fieldName)=>{
        switch (fieldName){
            case "playerPasswordSwitch":
                setPlayerPassword(value);
                break;
            case "mentorPasswordSwitch":
                setMentorPassword(value);
                break;
            default:
                break;
        }
    }
    return <Container maxWidth={"md"}>
            <TextInput InputProps={{
                startAdornment:<InputAdornment position="start">
                    <Link />
                </InputAdornment>
            }} fullWidth source="slug" validate={required()} required={true} label={translate("resources.training-sessions.fields.slug")}/>
            {/*<DateInput InputProps={{*/}
            {/*    startAdornment:<InputAdornment position="start">*/}
            {/*        <DateRange />*/}
            {/*    </InputAdornment>*/}
            {/*}} fullWidth source="startAt" label={translate("resources.training-sessions.fields.startAt")}/>*/}
            {/*<DateInput InputProps={{*/}
            {/*    startAdornment:<InputAdornment position="start">*/}
            {/*        <DateRange />*/}
            {/*    </InputAdornment>*/}
            {/*}} fullWidth source="endAt" label={translate("resources.training-sessions.fields.endAt")}/>*/}
            {!isCreateForm && <NumberInput InputProps={{
                startAdornment:<InputAdornment position="start">
                    <Settings />
                </InputAdornment>
            }} source={"maxTokenPerStep"} fullWidth label={translate("resources.training-sessions.fields.maxTokenPerStep")}/>}
            {!isCreateForm && <NumberInput InputProps={{
                startAdornment:<InputAdornment position="start">
                    <Settings />
                </InputAdornment>
            }} source={"tokensPerGame"} fullWidth label={translate("resources.training-sessions.fields.tokensPerGame")}/>}
            <FormControl>
                <FormLabel>{translate("resources.training-sessions.fields.playerPassword")}</FormLabel>
                <PrimarySwitch onChange={(e,checked)=>onFieldChange(checked,"playerPasswordSwitch")}/>
            </FormControl>
            <TextInput InputProps={{
                startAdornment:<InputAdornment position="start">
                    <Security />
                </InputAdornment>,
                type: "password"
            }} disabled={!playerPassword} source={""} fullWidth name={"playerPassword"} label={translate("resources.training-sessions.fields.playerPassword")}/>
            <FormControl>
                <FormLabel>{translate("resources.training-sessions.fields.mentorPassword")}</FormLabel>
                <PrimarySwitch onChange={(e,checked)=>onFieldChange(checked,"mentorPasswordSwitch")}/>
            </FormControl>
            <TextInput source={""} InputProps={{
                startAdornment:<InputAdornment position="start">
                    <Security />
                </InputAdornment>,
                type: "password"
            }} disabled={!mentorPassword} fullWidth name={"mentorPassword"} label={translate("resources.training-sessions.fields.mentorPassword")}/>
            <TextInput InputProps={{
                startAdornment:<InputAdornment position="start">
                    <TextFields />
                </InputAdornment>
            }} fullWidth source="title" label={translate("resources.training-sessions.fields.title")} />
            <TextInput InputProps={{
                startAdornment:<InputAdornment position="start">
                    <TextFields />
                </InputAdornment>,
                rows:3,
                multiline: true
            }} fullWidth source="description" label={translate("resources.training-sessions.fields.description")} />
    </Container>;
}

export function TrainingSessionView(props){
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const {initTrainingSession}  = useSelector((state)=>state.trainingSessionState);
    const [resetSession,setResetSession] = useState(false);
    useEffect(()=>{
        console.log(resetSession);
        if (initTrainingSession && !resetSession){
            dispatch(resetTrainingSession());
        }
        setResetSession(true);
    },[]);
    return resetSession?<MuiThemeProvider theme={theme}>
        <TrainingSession boTrainingSessionId={match.params.id} isAdminBO={true}/>
    </MuiThemeProvider>:<LoadingCircular/>
}
