import {LOGIN, LOGOUT, UPDATE_USER} from "./constants";

export const login = (payload)=>({
    type: LOGIN.REQUEST,
    payload,
});

export const handleInputFieldChange = (payload)=>({
    type: LOGIN.INPUT_FIELD_CHANGE,
    payload
});

export const logout = ()=>({
    type: LOGOUT.REQUEST
});

export const updateUser = (payload)=>({
    type: UPDATE_USER,
    payload
});