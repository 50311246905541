import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    withStyles,
    Snackbar
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, withRouter} from "react-router";
import Login from "./components/Login";
import {
    addMessageToWaitingRoom,
    changeSessionStatus, getGamesInPlaying,
    getPlayers,
    getSession, gotoNewSession,
    initSocket, loadMentorStats,
    login,
    updatePlayers
} from "../../sagas/trainingSession/actions";
import LoadingAction from "../loading/LoadingAction";
import Error404 from "../exception/Error404";
import Dashboard from "./components/Dashboard";
import * as links from "../../constants/links";
import * as appParams from "../../constants/params";
import TrainingSessionSocket from "../../services/TrainingSessionSocket";
import moment from "moment";
import {
    TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
    TRAINING_SESSION_END_GAME,
    TRAINING_SESSION_GAME_ROOM_NEXT_STEP, TRAINING_SESSION_GAME_SETTINGS_CHANGE,
    TRAINING_SESSION_JOIN_GAME,
    TRAINING_SESSION_JOIN_WAITING_ROOM, TRAINING_SESSION_QUIT,
    TRAINING_SESSION_QUIT_WAITING_ROOM, TRAINING_SESSION_SOCKET_CONNECTION,
    TRAINING_SESSION_START_GAME, TRAINING_SESSION_START_SEE_GAME
} from "../../sagas/trainingSession/constants";
import {
    MENTOR,
    PLAYER,
    PLAYER_STATUS_DISCONNECTED,
    SESSION_ENDED, SESSION_FROZEN,
    SESSION_PAUSED,
    SESSION_STARTED, SESSION_STOPPED
} from "../../constants/params";
import {
    AccessibilityNew, AttachFile,
    CloseRounded,
    ExitToAppRounded,
    NewReleasesRounded,
    PlayArrowOutlined, PlayArrowRounded
} from "@material-ui/icons";
import {Transition} from "../../themes/effects";
import {quit} from "../../sagas/trainingSession/actions";
import {snackbarError} from "../../sagas/app/actions";
const styles = {
    trainingSessionContainer:{

    },
    endGameMessage:{
        background: "#3f8b2c !important",
        "& .textMessage":{
            fontWeight: "bold",
            fontStyle: "italic"
        }
    },
    statusChangeMessage:{
        background: "#49a9e2 !important",
        color: "white",
        "& .textMessage":{
            fontWeight: "bold",
            fontStyle: "italic",
            padding: "10px",
            display: "block",
            borderRadius: 4
        },
        "&.notStarted .textMessage":{
            background: "#a0a0a0"
        },
        "&.started .textMessage":{
            background: "#257e30"
        },
        "&.ended .textMessage":{
            background: "#dd3636"
        },
        "&.paused .textMessage":{
            background: "#6d6a6a"
        },
        "&.frozen .textMessage":{
            background: "#949292"
        },
        "&.stopped .textMessage":{
            background: "#dd7036"
        }
    }
}
function TrainingSession(props){
    const dispatch = useDispatch();
    const {match,t,classes,isAdminBO} = props;
    const {user,canLogged,trainingSession,initTrainingSession,checkLoginInProcess,socket,jwtToken,players,error,gotoNewTrainingSession}  = useSelector((state)=>state.trainingSessionState);
    const [showNewSessionAvailable,setShowNewSessionAvailable] = useState(false);
    const [newSession,setNewSession] = useState(null);
    const trainingSessionId = match.params.id;
    const forMentor = (user && user.type === MENTOR) || match.path.match(/admin(\/)?$/ig);
    useEffect(()=>{
        if (!initTrainingSession){
            dispatch(getSession({
                id:trainingSessionId,
                forMentor
            }));
        }
        else if (trainingSession && trainingSession.id && canLogged===null && !checkLoginInProcess){
            console.log(match.path);
            dispatch(login({
                trainingSessionId:trainingSession.id,
                data:{
                    type: match.path === links.TRAINING_SESSION_PLAYER?appParams.PLAYER:appParams.MENTOR,
                    gotoNewSession:gotoNewTrainingSession?1:0,
                    isAdminBO:isAdminBO?1:0,
                },
                forMentor
            }))
        }
        else if (!socket && canLogged && user){
            const socket = TrainingSessionSocket.initWSService(trainingSession.id,{jwtToken:jwtToken},{
                onConnect: ()=>{
                    dispatch({
                        type: TRAINING_SESSION_SOCKET_CONNECTION.SUCCESS
                    });
                    dispatch({
                        type: TRAINING_SESSION_SOCKET_CONNECTION
                    });
                    dispatch(getPlayers({
                        id:trainingSession.id,
                        forMentor
                    }));
                    dispatch({
                        type: TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
                        payload: {
                            data: {
                                item: {
                                    message: "text.welcome_user_connected",
                                    translatable: true,
                                    translationVars: {user: user.nickname},
                                    date: moment()
                                }
                            }
                        }
                    });
                },
                onError: ()=>{
                    dispatch({
                        type: TRAINING_SESSION_SOCKET_CONNECTION.FAIL
                    });
                },
                onMessage: (message)=>{
                    const action = message.action;
                    const data = message.data;
                    console.log(data);
                    switch (action){
                        case "joinGameRoom":
                            dispatch({
                                type: TRAINING_SESSION_JOIN_GAME.SUCCESS,
                                payload:{
                                    data:data
                                }
                            });
                            break;
                        case "startGame":
                            dispatch({
                                type: TRAINING_SESSION_START_GAME,
                                payload:{
                                    data:data
                                }
                            });
                            break;
                        case "seeGameSuccess":
                            dispatch({
                                type: TRAINING_SESSION_START_SEE_GAME,
                                payload:{
                                    data: data
                                }
                            });
                            break;
                        case "seeGameFail":
                            dispatch(snackbarError({
                                message: t("label.training_session.see_game_fail")
                            }));
                            break;
                        case "startOtherGame":
                            dispatch(getGamesInPlaying({
                                id: trainingSession.id,
                                forMentor
                            }));
                            break;
                        case "nextStep":
                            dispatch({
                                type :TRAINING_SESSION_GAME_ROOM_NEXT_STEP,
                                payload:{
                                    data: data
                                }
                            });
                            break;
                        case "endGame":
                            dispatch({
                                type :TRAINING_SESSION_END_GAME,
                                payload:{
                                    data: data
                                }
                            });
                            break;
                        case "joinedWaitingRoom":
                            dispatch({
                                type :TRAINING_SESSION_JOIN_WAITING_ROOM,
                                payload:{
                                    data: data,
                                    requestRoomInterval: setInterval(()=>{
                                        socket.sendMessage("requestRoom",{});
                                    },10000)
                                }
                            });
                            break;
                        case "quitedWaitingRoom":
                            dispatch({
                                type :TRAINING_SESSION_QUIT_WAITING_ROOM,
                                payload:{
                                    data: data
                                }
                            });
                            break;
                        case "userJoinedWaitingRoom":
                            if (typeof data === "object" && data.length){
                                data.forEach((player)=>{
                                    dispatch({
                                        type: TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
                                        payload: {
                                            data: {
                                                item: {
                                                    message: "text.welcome_user_waiting_room_chat",
                                                    translatable: true,
                                                    date: moment(),
                                                    translationVars: {user: player.nickname}
                                                }
                                            }
                                        }
                                    });
                                });
                            }
                            break;
                        case "updateScoreBoard":
                            dispatch(getPlayers({
                                id:trainingSession.id,
                                forMentor
                            }));
                            break;
                        case "sessionStatusChanged":
                            const newStatus = data.newStatus;
                            const currentStatus = trainingSession.status;
                            let statusMessage = "";
                            switch (newStatus){
                                case SESSION_PAUSED:
                                    statusMessage = "label.training_session.paused_by_mentor";
                                    break;
                                case SESSION_STARTED:
                                    statusMessage = currentStatus===null?"label.training_session.started_by_mentor":"label.training_session.restarted_by_mentor";
                                    break;
                                case SESSION_ENDED:
                                    statusMessage = "label.training_session.ended_by_mentor";
                                    if (user.type === PLAYER){

                                    }
                                    break;
                                case SESSION_FROZEN:
                                    statusMessage = "label.training_session.frozen_by_mentor";
                                    break;
                                case SESSION_STOPPED:
                                    statusMessage = "label.training_session.stopped_by_mentor";
                                    break;
                                default:
                                    break;
                            }
                            dispatch(changeSessionStatus({
                                data:data
                            }));
                            if (statusMessage!==""){
                                dispatch(addMessageToWaitingRoom({
                                    data: {
                                        item: {
                                            message: statusMessage,
                                            translatable: true,
                                            date: moment(),
                                            className: classes.statusChangeMessage+" "+newStatus
                                        }
                                    }
                                }));
                            }
                            break;
                        case "reloadMentorStats":
                            dispatch(loadMentorStats({
                                id: trainingSession.id
                            }));
                            break;
                        case "gotoNewSessionOrLogout":
                            setShowNewSessionAvailable(true);
                            setNewSession(data.newSession);
                            break;
                        case "gameSettingsChangeSuccess":
                            dispatch({
                                type: TRAINING_SESSION_GAME_SETTINGS_CHANGE.SUCCESS,
                                payload: {
                                    data: data
                                }
                            });
                            break;
                        case "gameSettingsChangeFail":
                            dispatch({
                                type: TRAINING_SESSION_GAME_SETTINGS_CHANGE.FAIL,
                                payload: {
                                    data: data
                                }
                            });
                            break;
                        case "endOtherGame":
                            const {winner,players,gameId} = data;
                            dispatch(getGamesInPlaying({
                                id: trainingSession.id,
                                forMentor
                            }));
                            dispatch(addMessageToWaitingRoom({
                                data: {
                                    item: {
                                        message: typeof winner === "object" && winner.length===2?"text.game.a_draw_b":"text.game.a_win_b",
                                        translatable: true,
                                        translationVars:  typeof winner === "object" && winner.length===2?{player1: players[0].nickname,player2: players[1].nickname}:{winner: players[0].nickname,loser: players[1].nickname},
                                        date: moment(),
                                        className: classes.endGameMessage
                                    }
                                }
                            }));
                            break;
                        default:
                            break;
                    }
                },
                onDisconnect: ()=>{
                    dispatch({
                        type: TRAINING_SESSION_SOCKET_CONNECTION.DISCCONNECT
                    });
                },
                onUserDisconnect: (data)=>{
                    const disconnectedUser = data.data;
                    if (disconnectedUser.type === PLAYER){
                        dispatch(updatePlayers({
                            disconnectedUsers: [disconnectedUser]
                        }));
                        dispatch({
                            type: TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
                            payload: {
                                data: {
                                    item: {
                                        message: "text.say_goodbye",
                                        translatable: true,
                                        translationVars: {user: disconnectedUser.nickname},
                                        date: moment(),
                                    }
                                }
                            }
                        });
                    }
                },
                onNewUserConnect: (data)=>{
                    const newUser = data.data;
                    if (newUser.type === PLAYER){
                        dispatch(updatePlayers({
                            newUsers: [newUser]
                        }));
                        dispatch({
                            type: TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
                            payload: {
                                data: {
                                    item: {
                                        message: "text.welcome_user_connected",
                                        translatable: true,
                                        date: moment(),
                                        translationVars: {user: newUser.nickname}
                                    }
                                }
                            }
                        });
                    }
                }
            });
            dispatch(initSocket(socket));
        }
    });
    const buildContent = ()=>{
        if (trainingSession && canLogged!==null){
            if (canLogged===false || !user){
                return <Login />
            }
            else{
                return <Dashboard/>;
            }
        }
        else if (trainingSession === false){
            return <Error404/>;
        }
        return <LoadingAction/>
    };
    return <div className={classes.trainingSessionContainer}>
        {buildContent()}
        {showNewSessionAvailable && newSession!==null && <Dialog
            open={true}
            TransitionComponent={Transition}
            keepMounted
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <DialogTitle className={classes.popupTitle}>{t("title.training_session.new_session")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("text.training_session.confirm_goto_new_session_or_logout")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={(e)=>{
                    setShowNewSessionAvailable(false);
                    dispatch(gotoNewSession(
                        {
                            data: {
                                item: newSession
                            }
                        }
                    ));
                }} type={"button"} color="secondary">
                    <PlayArrowRounded fontSize={"large"}/> {t("label.training_session.goto_new_session")}
                </Button>
                <Button variant={"contained"} onClick={(e)=>{
                    setShowNewSessionAvailable(false);
                    dispatch(quit({
                        trainingSessionId: trainingSession.id
                    }));
                }} type={"button"} color="default">
                    <CloseRounded fontSize={"large"}/> {t("label.training_session.logout")}
                </Button>
            </DialogActions>
        </Dialog>}
        {error && error.socket && <Snackbar open={true} autoHideDuration={6000}>
            <Alert severity="success">
                {t(error.socket)}
            </Alert>
        </Snackbar>}
    </div>;
}

export default withRouter(withStyles(styles)(withTranslation()(TrainingSession)));
