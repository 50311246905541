import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN  from "./translations/en/translates.json";
import translationsFR  from "./translations/fr/translates.json";
import raTranslationsEN from 'ra-language-english';
import raTranslationsFR from 'ra-language-french';
import _ from "lodash";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        messages: _.merge(raTranslationsEN,translationsEN)
    },
    fr: {
        messages: _.merge(raTranslationsFR,translationsFR)
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: process.env.REACT_APP_DEFAULT_LANGUAGE,
        defaultNS: "messages",
        supportedLngs:Object.keys(resources),
        ns: ["messages"],
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
             wait: false,
             useSuspense: false
        }
    });

export default i18n;