import React, {useEffect, useState} from "react";
import {withRouter,Redirect} from "react-router";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import {
    AccountCircleRounded,
    LockRounded,
    SecurityRounded,
    VisibilityOffRounded,
    VisibilityRounded
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as colors from "../../constants/colors";
import {login} from "../../sagas/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import * as links from "../../constants/links";
const styles = (theme)=>({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#ffd02f",
        minHeight: 600,
        borderRadius: 6,
        position: "relative",
        width: "100%",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%',
        marginTop: "1rem",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        minHeight: "3.5rem"
    },
    formContainer:{
        marginTop: "2rem",
        borderRadius: 6,
        padding: "2rem 1rem",
        border: "1px solid #00000026"
    },
    textField:{
        color: colors.DARK_COLOR,
        "& fieldset":{
            color: colors.DARK_COLOR
        },
        "& input,& label,& legend span,& label.Mui-focused":{
            color: colors.DARK_COLOR
        },
        "& $notchedOutline":{
            borderColor:colors.DARK_COLOR
        },
        "&>div":{
            background:"#fff",
        }
    },
});
function Login(props){
    const {classes,t,history} = props;
    const error = false;
    const checkLoginInProcess = false;
    const [username,setUsername] = useState("");
    const [password,setPassword] = useState("");
    const [showPassword,setShowPassword] = useState(false);
    const {authenticated} = useSelector((state)=>state.authState);
    const [checkLogin,setCheckLogin] = useState(false);
    const routing = useSelector((state)=>state.router);
    const dispatch = useDispatch();
    const [nextUrlAfterLogin,setNextUrlAfterLogin] = useState(null);
    const handleSubmit = (e)=>{
        e.preventDefault();
        setCheckLogin(true);
        loginUser();
    }
    const loginUser = (auto=false)=>{
        let nextUrlAfterLogin;
        if (routing && routing.location && routing.location.state && routing.location.state.from && routing.location.state.from.pathname && routing.location.state.from.pathname!==links.LOGIN){
            nextUrlAfterLogin = routing.location.state.from.pathname;
            setNextUrlAfterLogin(nextUrlAfterLogin);
        }
        dispatch(login({
            username: username,
            password: password,
            auto:auto,
            nextUrlAfterLogin: nextUrlAfterLogin
        }));
    }
    useEffect(()=>{
        if (!checkLogin && !authenticated){
            setCheckLogin(true);
            loginUser(true);
        }
    },[]);
    if (authenticated){
        return <Redirect to={{pathname:links.HOME,nextUrl:nextUrlAfterLogin}}/>
    }
    return <Grid item xs={12} sm={12} md={5} lg={4} className={clsx(classes.loginBlock,classes.paper)}>
        <Avatar className={classes.avatar}>
            <LockRounded style={{color: "white"}} />
        </Avatar>
        <Typography component="h1" variant="h3" className={classes.connectionTitle}>
            {t("label.connection")}
        </Typography>
        <Container maxWidth={"sm"} className={classes.formContainer}>
            <form className={classes.form} method={"POST"} onSubmit={(e)=>handleSubmit(e)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            inputProps={
                                {
                                    autocomplete: false,
                                    // form:{
                                    //     autocomplete: false
                                    // }
                                }
                            }
                            InputProps={{
                                required:true,
                                startAdornment: <InputAdornment position="start">
                                    <AccountCircleRounded />
                                </InputAdornment>
                            }}
                            error={!!error && !!error.account}
                            helperText={!!error && !!error.account?t(error.account):null}
                            name="username"
                            variant="outlined"
                            fullWidth
                            id="nickName"
                            label={t("label.username")}
                            value={username}
                            onChange={(e)=>setUsername(e.target.value)}
                            autoFocus
                            className={clsx(classes.textField)}
                            prefix={"user"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="password"
                            error={!!error && !!error.password}
                            helperText={!!error && !!error.password?t(error.password):null}
                            label={t("label.password")}
                            type={showPassword?"text":"password"}
                            id="password"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                            inputProps={
                                {
                                    autocomplete: false,
                                    // form:{
                                    //     autocomplete: false
                                    // }
                                }
                            }
                            InputProps={{
                                required:true,
                                startAdornment: <InputAdornment position="start">
                                    <SecurityRounded />
                                </InputAdornment>,
                                endAdornment: <InputAdornment className={classes.passwordVisibilityIcon} onClick={()=>{
                                    setShowPassword(!showPassword);
                                }} position="end">
                                    {showPassword?<VisibilityRounded/>:<VisibilityOffRounded/>}
                                </InputAdornment>
                            }}
                            className={clsx(classes.textField)}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size={"large"}
                    onClick={(e)=>{
                        if (!!checkLoginInProcess){
                            e.preventDefault();
                        }
                    }}
                >
                    {checkLoginInProcess && <CircularProgress className={classes.loadingLogin} size={"2rem"} color={"secondary"}/>} {t("label.login_register")}
                </Button>
            </form>
        </Container>
    </Grid>;
}

export default withRouter(withStyles(styles)(withTranslation()(Login)));