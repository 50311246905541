import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {withStyles, Dialog, AppBar, Toolbar, IconButton,DialogContent,DialogTitle, Typography, Tabs, Tab, Grid,Box,TextField} from "@material-ui/core";
import {CloseRounded, HelpRounded, PeopleAlt, PeopleAltRounded, PersonRounded} from "@material-ui/icons";
import {Transition} from "../../../../themes/effects";
import {useDispatch, useSelector} from "react-redux";
import {toggleStatsDialog} from "../../../../sagas/trainingSession/actions";
import {MENTOR} from "../../../../constants/params";
import PercentageTable from "./PercentageTable";
import _ from "lodash";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PercentageChart from "./charts/PercentageChart";
const styles = {
    dialog: {

    },
    dialogPaper:{
        background: "#221f1f"
    },
    tabs:{
        //borderBottom:"1px solid #ffffff4d",
        background: "#221f1f",
        marginBottom: "1rem"
    },
    content:{
        background: "#221f1f",
        "& .tab-content":{
            background: "#221f1f",
            padding: 0
        },
    },
    playersList:{
        marginBottom: "1rem"
    },
    percentageChart:{
        marginTop: "1rem",
        marginBottom: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        background: "#dbd6cd",
        position: "relative"
    }
}

function a11yProps(index) {
    return {
        id: `stats-tab-${index}`,
        'aria-controls': `stats-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    console.log(value,index);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`stats-tabpanel-${index}`}
            aria-labelledby={`stats-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={"tab-content"}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function StatsTable(props){
    const {classes,t}  = props;
    const {stats,trainingSession,user,players} = useSelector((state)=>state.trainingSessionState);
    const open = !!stats.showStatsDialog;
    const {gameSettings} = trainingSession;
    const playerItems = players.items||[];
    const dispatch = useDispatch();
    const availableTabs = [];
    const isMentor = user.type === MENTOR;
    if (isMentor || gameSettings.personalStats || gameSettings.otherPlayersStats){
        availableTabs.push(0);
    }
    if (isMentor || gameSettings.competitionStats){
        availableTabs.push(1);
    }
    if (isMentor || gameSettings.aiStats){
        availableTabs.push(2);
    }
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (e, newValue) => {
        setSelectedTab(newValue);
    };
    const aiPercentages = {};
    let initStatsPlayer = null;
    let playerOptions = playerItems.map((playerItem)=>{
        if (playerItem.id === user.id){
            initStatsPlayer = {
                value: playerItem.id,
                title: playerItem.nickname
            };
        }
        return {
            value: playerItem.id,
            title: playerItem.nickname
        }
    });
    playerOptions = playerOptions.filter((playerOption)=>{
        return isMentor || (gameSettings.personalStats && playerOption.value===user.id) || (gameSettings.otherPlayersStats && playerOption.value!==user.id)
    });
    playerOptions.sort((a,b)=>{
       return a.title.localeCompare(b.title);
    });
    if ((!initStatsPlayer || playerOptions.findIndex((item)=>item.value===initStatsPlayer.value)===-1) && playerOptions.length>0){
        initStatsPlayer = playerOptions[0];
    }
    const [statsPlayer,setStatsPlayer] = useState(initStatsPlayer);

    const sessionPercentages = {};
    const playersStats = {};
    playerItems.forEach((playerItem)=>{
        playersStats[playerItem.id]={
           winnerStats: playerItem.otherInfos.winner_step_stats||{},
           loserStats: playerItem.otherInfos.loser_step_stats||{},
        };
    });
    const playerPercentages = {};
    const playerStats = statsPlayer!==null && playersStats.hasOwnProperty(statsPlayer.value)?playersStats[statsPlayer.value]:{};
    for (const value of _.range(1,trainingSession.tokensPerGame+1)) {
        playerPercentages[value] = {value:null,totalMatches:0};
        aiPercentages[value] = {value:null};
        sessionPercentages[value] = {value:null,totalMatches:0};
        if (Object.keys(playerStats)){
            const totalPlayerWinner = playerStats.winnerStats && playerStats.winnerStats[value]?playerStats.winnerStats[value]:0;
            const totalPlayerLoser = playerStats.loserStats && playerStats.loserStats[value]?playerStats.loserStats[value]:0;
            if (totalPlayerLoser || totalPlayerWinner){
                const totalMatches = totalPlayerWinner+totalPlayerLoser;
                playerPercentages[value] = {value:Math.round(totalPlayerWinner*100/totalMatches),totalMatches:totalMatches};
            }
        }
        let totalWinner = 0;
        let totalLoser = 0;
        Object.keys(playersStats).forEach((playerId)=>{
            const pStats = playersStats[playerId];
            const totalPlayerWinner = pStats.winnerStats && pStats.winnerStats[value]?pStats.winnerStats[value]:0;
            const totalPlayerLoser = pStats.loserStats && pStats.loserStats[value]?pStats.loserStats[value]:0;
            totalWinner+=totalPlayerWinner;
            totalLoser+=totalPlayerLoser;
        });
        if (totalWinner || totalLoser){
            const totalMatches = totalLoser+totalWinner;
            sessionPercentages[value] = {value:Math.round(totalWinner*100/(totalMatches)), totalMatches: totalMatches};
        }
    }
    aiPercentages["3"] = {value:100};
    //aiPercentages["5"] = {value:2};
    aiPercentages["6"] = {value:100};
    aiPercentages["9"] = {value:100};
    aiPercentages["12"] = {value:100};
    aiPercentages["15"] = {value:100};
    aiPercentages["18"] = {value:100};
    const percentageOptions = {
        responsive: true,
        aspectRatio:4,
        legend: {
            display:false
        },
        tooltips: {
            callbacks: {
                label: (item) => `${item.yLabel}%`,
            },
        },
        scales:{
            xAxes: [{
                ticks: {
                    min: 1,
                    stepSize:1
                },
                scaleLabel:{
                    display:true,
                    labelString: t("label.training_session.enemy_remaining_tokens")
                }
            }],
            yAxes: [{
                ticks: {
                    callback: function(label, index, labels) {
                        const value = parseInt(label);
                        return value%20===0?label:"";
                    },
                    min: 0,
                    stepSize:10,
                    max: 100
                },
                scaleLabel:{
                    display:true,
                    labelString: t("label.training_session.victory_percentage") + " (%)"
                }
            }]
        }
    };
    const handleClose = () => {
        dispatch(toggleStatsDialog(false));
    };

    useEffect(()=>{
        if (selectedTab>=availableTabs.length){
            setSelectedTab(0);
        }
        if (statsPlayer && playerOptions.length && playerOptions.findIndex((item)=>item.value===playerOptions.value)===-1){
            setStatsPlayer(playerOptions[0]);
        }
    },[open]);

    return <Dialog maxWidth={"lg"} PaperProps={{className:classes.dialogPaper}} open={open} onClose={handleClose} className={classes.dialog} TransitionComponent={Transition} keepMounted>
        <DialogTitle>
            <Grid className={classes.tabs}>
                <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                    {(isMentor || gameSettings.personalStats || gameSettings.otherPlayersStats) && <Tab label={t("label.training_session.stats_dialog.personal")} icon={<PersonRounded fontSize={"large"}/>} {...a11yProps(availableTabs.findIndex((item)=>item===0))} />}
                    {(isMentor || gameSettings.competitionStats) && <Tab label={t("label.training_session.stats_dialog.session")}  icon={<PeopleAltRounded fontSize={"large"}/>} {...a11yProps(availableTabs.findIndex((item)=>item===1))} />}
                    {(isMentor || gameSettings.aiStats) &&<Tab label={t("label.training_session.stats_dialog.ai")}  icon={<HelpRounded fontSize={"large"}/>} {...a11yProps(availableTabs.findIndex((item)=>item===2))} />}
                </Tabs>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid container className={classes.content}>
                {(isMentor || gameSettings.personalStats || gameSettings.otherPlayersStats) && <TabPanel container value={selectedTab} index={availableTabs.findIndex((item)=>item===0)}>
                    {playerOptions.length>0 ?
                        <React.Fragment>
                            <Autocomplete
                                className={classes.playersList}
                                options={playerOptions}
                                value={statsPlayer}
                                getOptionLabel={(option) => option.title}
                                style={{ width: 300 }}
                                disableClearable={true}
                                onChange={(e,value)=>{
                                    setStatsPlayer(value);
                                }}
                                loadingText={t("label.loading")}
                                noOptionsText={t("text.list.no_results")}
                                renderInput={(params) => <TextField margin={"dense"} {...params} label={t("label.training_session.select_player")} variant="filled" />}
                            />
                            <PercentageTable hasTotalMatches={true} data={playerPercentages}/>
                            <PercentageChart className={classes.percentageChart}  options={percentageOptions} title={t("label.training_session.stats_dialog.personal_percentage_victory_per_step")} data={Object.keys(playerPercentages).map((token)=>{
                                return {
                                    label: token,
                                    value: playerPercentages[token].value
                                }
                            })}/>
                        </React.Fragment>
                        :
                        <Typography>{t("label.training_session.no_players")}</Typography>
                    }
                </TabPanel>}
                {(isMentor || gameSettings.competitionStats) && <TabPanel container value={selectedTab} index={availableTabs.findIndex((item)=>item===1)}>
                    <PercentageTable hasTotalMatches={true} data={sessionPercentages}/>
                    <PercentageChart className={classes.percentageChart} options={percentageOptions} title={t("label.training_session.stats_dialog.session_percentage_victory_per_step")} data={Object.keys(sessionPercentages).map((token)=>{
                        return {
                            label: token,
                            value: sessionPercentages[token].value
                        }
                    })}/>
                </TabPanel>}
                {(isMentor || gameSettings.aiStats) && <TabPanel container value={selectedTab} index={availableTabs.findIndex((item)=>item===2)}>
                    <PercentageTable hasTotalMatches={false} data={aiPercentages}/>
                    <PercentageChart className={classes.percentageChart} options={percentageOptions} title={t("label.training_session.stats_dialog.ai_percentage_victory_per_step")} data={Object.keys(aiPercentages).map((token)=>{
                        return {
                            label: token,
                            value: aiPercentages[token].value
                        }
                    })}/>
                </TabPanel>}
            </Grid>
        </DialogContent>
    </Dialog>;
}


export default withStyles(styles)(withTranslation()(StatsTable))