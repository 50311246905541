import React from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Avatar from "react-avatar";

const styles = {

}
function AppAvatar(props){
    const {t,classes,...otherProps} = props;
    return <Avatar round size={"3.5rem"} {...otherProps} />
}

export default withStyles(styles)(withTranslation()(AppAvatar));
