import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {withTranslation} from "react-i18next";
import clsx from "clsx";
import * as colors from "../../../constants/colors";
import imgTraining from "../../../images/formation.jpg";
import logo from "../../../images/logo.png";
import logoPartner1 from "../../../images/partners/logo-Kaleidi.jpg";
import logoPartner2 from "../../../images/partners/logo-UBO.png";
import logoPartner3 from "../../../images/partners/Logo_universite_montpellier.png";
import logoPartner4 from "../../../images/partners/MMI.png";
import {
    SecurityRounded,
    VisibilityRounded,
    VisibilityOffRounded,
    LockRounded,
    AccountCircleRounded
} from "@material-ui/icons";
import Carousel from "react-material-ui-carousel";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../../sagas/trainingSession/actions";
import {withRouter} from "react-router";
import * as links from "../../../constants/links";
import * as appParams from "../../../constants/params";
import {LoadingCircular} from "../../loading/loaders";
const styles = (theme) => ({
    container:{
        [theme.breakpoints.down("sm")]:{
            flexDirection: "column-reverse",
            alignItems: "center"
        }
    },
    loginBlock:{

    },
    presentation:{

    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "#ffd02f",
        minHeight: 600,
        borderRadius: 6,
        position: "relative",
        width: "100%",
        maxWidth: 400
    },
    textField:{
        "& fieldset":{
            color: colors.DARK_COLOR
        },
        "& input,& label,& legend span,& label.Mui-focused":{
            color: colors.DARK_COLOR
        },
        "& $notchedOutline":{
            borderColor:colors.DARK_COLOR
        },
        "&>div":{
            background:"#fff",
        }
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%',
        marginTop: "1rem",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        minHeight: "3.5rem"
    },
    formContainer:{
        backgroundColor: "rgba(255,255,255,.6)",
        marginTop: "2rem",
        borderRadius: 6,
        padding: "2rem 1rem",
        maxWidth: 300,
        border: "1px solid #00000026"
    },
    connectionTitle:{
        position: "absolute",
        top:"4.25rem"
    },
    logoPlaisirMath:{
        marginTop: "1rem"
    },
    imgTraining:{
        marginTop: "3.5rem",
        borderRadius: 6
    },
    trainingDescription:{
        marginTop: "1rem",
        color: colors.DARK_COLOR
    },
    passwordVisibilityIcon:{
        "&:hover":{
            cursor: "pointer"
        }
    },
    logoSlider:{
        textAlign: "center",
        marginTop: "1rem",
        "& img":{
            maxWidth: "80%",
            maxHeight: "80px"
        }
    },
    loadingLogin:{
        marginRight: ".5rem"
    }
});

function Login(props) {
    const {classes,t,match} = props;
    const {canLogged,checkLoginInProcess,user,trainingSession,error} = useSelector((state)=>state.trainingSessionState);
    const [showPassword,setShowPassword] = useState(false);
    const [password,setPassword] = useState("");
    const [nickname,setNickname] = useState("");
    const bannerItems = [logoPartner1,logoPartner2,logoPartner3,logoPartner4];
    const dispatch = useDispatch();
    const isPlayerLogin = match.path === links.TRAINING_SESSION_PLAYER;
    const hasPassword = isPlayerLogin?trainingSession.hasPlayerPassword:trainingSession.hasMentorPassword;
    const handleSubmit = (e)=>{
        e.preventDefault();
        const data  = {
            nickname: nickname,
            type: isPlayerLogin?appParams.PLAYER:appParams.MENTOR
        }
        if (hasPassword){
            data.password = password;
        }
        dispatch(login({
            trainingSessionId: trainingSession.id,
            data:data,
            forMentor: match.path.match(/admin(\/)?$/ig)
        }));
    }
    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={2} sm={12} xs={12} justify={"center"} alignItems={"stretch"} className={classes.container}>
                <Grid item xs={12} sm={12} md={5} lg={4} className={clsx(classes.presentation,classes.paper)}>
                    <img className={classes.logoPlaisirMath} src={logo} alt={"logo"}/>
                    <img className={classes.imgTraining} src={imgTraining} alt={"formation"} width={"100%"}/>
                    <Typography className={classes.trainingDescription}>
                        {t("text.game_description")}
                    </Typography>
                    <Carousel className={classes.logoSlider} navButtonsAlwaysInvisible={true} interval={5000}>
                        {
                            bannerItems.map( (item, i) => <img key={i} src={item} /> )
                        }
                    </Carousel>
                </Grid>
                <Grid md={1}/>
                <Grid item xs={12} sm={12} md={5} lg={4} className={clsx(classes.loginBlock,classes.paper)}>
                    <Avatar className={classes.avatar}>
                        <LockRounded style={{color: "white"}} />
                    </Avatar>
                    <Typography component="h1" variant="h3" className={classes.connectionTitle}>
                        {t("label.connection")}
                    </Typography>
                    <Container maxWidth={"sm"} className={classes.formContainer}>
                        <form className={classes.form} method={"POST"} onSubmit={(e)=>handleSubmit(e)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={
                                            {
                                                autocomplete: false,
                                                // form:{
                                                //     autocomplete: false
                                                // }
                                            }
                                        }
                                        InputProps={{
                                            required:true,
                                            startAdornment: <InputAdornment position="start">
                                                <AccountCircleRounded />
                                            </InputAdornment>
                                        }}
                                        error={!!error && !!error.account}
                                        helperText={!!error && !!error.account?t(error.account):null}
                                        name="nickName"
                                        variant="outlined"
                                        fullWidth
                                        id="nickName"
                                        label={t("label.nickname")}
                                        value={nickname}
                                        onChange={(e)=>setNickname(e.target.value)}
                                        autoFocus
                                        className={clsx(classes.textField)}
                                        prefix={"user"}
                                    />
                                </Grid>
                                {hasPassword && <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="password"
                                        error={!!error && !!error.password}
                                        helperText={!!error && !!error.password?t(error.password):null}
                                        label={t("label.password")}
                                        type={showPassword?"text":"password"}
                                        id="password"
                                        value={password}
                                        onChange={(e)=>setPassword(e.target.value)}
                                        inputProps={
                                            {
                                                autocomplete: false,
                                                // form:{
                                                //     autocomplete: false
                                                // }
                                            }
                                        }
                                        InputProps={{
                                            required:true,
                                            startAdornment: <InputAdornment position="start">
                                                <SecurityRounded />
                                            </InputAdornment>,
                                            endAdornment: <InputAdornment className={classes.passwordVisibilityIcon} onClick={()=>{
                                                setShowPassword(!showPassword);
                                            }} position="end">
                                                {showPassword?<VisibilityRounded/>:<VisibilityOffRounded/>}
                                            </InputAdornment>
                                        }}
                                        className={clsx(classes.textField)}
                                    />
                                </Grid>}
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                size={"large"}
                                onClick={(e)=>{
                                    if (!!checkLoginInProcess){
                                        e.preventDefault();
                                    }
                                }}
                            >
                                {checkLoginInProcess && <CircularProgress className={classes.loadingLogin} size={"2rem"} color={"secondary"}/>} {t("label.login_register")}
                            </Button>
                        </form>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}

export default withRouter(withStyles(styles)(withTranslation()(Login)));
