import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    TableCell,
    CircularProgress,
    Grid,
    Tooltip,
    Card, CardHeader, CardContent
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getPlayers} from "../../../../sagas/trainingSession/actions";
import Alert from '@material-ui/lab/Alert';
import {PLAYER_STATUS_DISCONNECTED} from "../../../../constants/params";
import {AccountCircleRounded} from "@material-ui/icons";
import * as _ from "lodash";
import * as colors from "../../../../constants/colors";
import moment from "moment";
import clsx from "clsx";
const styles = (theme)=>({
    paper:{
        background: colors.PRIMARY_COLOR_DARK,
        minWidth: 300,
        padding: 0,
        minHeight: 500,
        maxHeight: 500,
        overflow: "auto",
        marginTop: "1rem",
        [theme.breakpoints.between(650,"sm")]:{
            marginTop: 0,
            minHeight: "100%"
        },
        [theme.breakpoints.down(600)]: {
            width: "100%",
            flexDirection: "column"
        }
    },
    card:{
        color: "white",
        height: "100%"
    },
    cardHeader:{
        background: "transparent"
    },
    cardContent:{
        background: "transparent"
    },
    messageList:{
        listStyle: "none",
        margin: 0,
        padding:0
    },
    messageItem:{
        marginTop: 5,
        padding: "10px 5px 10px 10px",
        borderRadius: 6,
        background: "#777",
        "&:nth-child(2n+1)":{
            background: colors.DARK_COLOR,
        }
    },
    date:{
        display: "block"
    },
    textMessage:{
        color: colors.TEXT_SECONDARY_COLOR
    }
});

function WaitingRoomChatBox(props){
    const {classes,t} = props;
    const dispatch = useDispatch();
    const {waitingRoom} = useSelector((state)=>state.trainingSessionState);
    const {messages} = waitingRoom;
    console.log(messages);
    const sortedMessages = messages.sort((a,b)=>{
        return moment(b.date).valueOf() - moment(a.date).valueOf();
    }).slice(0,20);
    return (
        <Paper className={classes.paper}>
            <Card className={classes.card} elevation={0}>
                <CardHeader className={classes.cardHeader} title={t("label.training_session.announcements")}/>
                <CardContent className={classes.cardContent}>
                    {sortedMessages && sortedMessages.length>0 && <ul className={classes.messageList}>
                        {sortedMessages.map((messageItem)=>{
                            return <li className={clsx(classes.messageItem,{[messageItem.className]:!!messageItem.className})}>
                                <span className={classes.date}>{messageItem.date.format("DD/MM/YYYY HH:mm")}</span>
                                <span className={classes.textMessage+" textMessage"}>{!!messageItem.translatable?t(messageItem.message,messageItem.translationVars):messageItem.message}</span>
                            </li>;
                        })}
                    </ul>}
                </CardContent>
            </Card>
        </Paper>
    );
}

export default withStyles(styles)(withTranslation()(WaitingRoomChatBox));