export const LOGIN = {
    REQUEST: "LOGIN_REQUEST",
    SUCCESS: "LOGIN_SUCCESS",
    FAIL: "LOGIN_FAIL",
    CLOSE_SNACKBAR: "LOGIN_CLOSE_SNACKBAR",
    INPUT_FIELD_CHANGE: "LOGIN_INPUT_FIELD_CHANGE"
};

export const LOGOUT = {
    REQUEST: "LOGOUT_REQUEST",
    SUCCESS: "LOGOUT_SUCCESS",
    FAIL: "LOGOUT_FAIL",
}

export const UPDATE_USER = "UPDATE_USER";