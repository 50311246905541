import {withTranslation} from "react-i18next";
import {
    CircularProgress, Grid,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles,
    Select,
    MenuItem,
    InputLabel,
    Switch, DialogTitle, DialogContent, Dialog, DialogActions, Button, FormControl
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GreenSwitch} from "../../../../themes/components/switch";
import {Transition} from "../../../../themes/effects";
import {CheckRounded, CloseRounded, PlayArrowRounded} from "@material-ui/icons";
import {updateGameSettings} from "../../../../sagas/trainingSession/actions";
import {snackbarError, snackbarSuccess} from "../../../../sagas/app/actions";
import {TRAINING_SESSION_GAME_SETTINGS_CHANGE} from "../../../../sagas/trainingSession/constants";
import {FIELD_SELECT, FIELD_SWITCH} from "../../../../constants/fields";
import {
    GAME_SETTINGS_DASHBOARD_STATS_AI,
    GAME_SETTINGS_DASHBOARD_STATS_PERSONAL,
    GAME_SETTINGS_DASHBOARD_STATS_SESSION,
    GAME_SETTINGS_SHOW_REMAINING_TOKENS,
    GAME_SETTINGS_STATS_AI,
    GAME_SETTINGS_STATS_DASHBOARD,
    GAME_SETTINGS_STATS_OTHER_PLAYERS,
    GAME_SETTINGS_STATS_PERSONAL,
    GAME_SETTINGS_STATS_SESSION,
    GAME_SETTINGS_TOKEN_DISPLAY_MODE,
    GAME_SETTINGS_TOKEN_DISPLAY_MODE_LINE,
    GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL, MENTOR
} from "../../../../constants/params";
const styles = {
    table:{
    },
    title:{
        color: "white",
        fontSize: "1.2rem"
    }
}

const gameSettingsInit= [
    {
        code: GAME_SETTINGS_STATS_DASHBOARD,
        title: "label.training_session.settings.dashboard_stats",
        value: "",
        field: {
            type: FIELD_SELECT,
            required: false,
            label:"label.no_parameter",
            choices: [
                {
                    value: GAME_SETTINGS_DASHBOARD_STATS_PERSONAL,
                    label: "label.training_session.settings.personal_stats",
                },
                {
                    value: GAME_SETTINGS_DASHBOARD_STATS_SESSION,
                    label: "label.training_session.settings.session_stats",
                },
                {
                    value: GAME_SETTINGS_DASHBOARD_STATS_AI,
                    label: "label.training_session.settings.ai_stats",
                }
            ],
            multiple: false
        }
    },
    {
        code: GAME_SETTINGS_STATS_PERSONAL,
        title: "label.training_session.settings.personal_stats",
        value: false,
        field: {
            required: false,
            type: FIELD_SWITCH,
        }
    },
    {
        code: GAME_SETTINGS_STATS_OTHER_PLAYERS,
        title: "label.training_session.settings.other_players_stats",
        value: false,
        field: {
            required: false,
            type: FIELD_SWITCH,
        }
    },
    {
        code: GAME_SETTINGS_STATS_SESSION,
        title: "label.training_session.settings.session_stats",
        value: false,
        field: {
            required: false,
            type: FIELD_SWITCH,
        }
    },
    {
        code: GAME_SETTINGS_STATS_AI,
        title: "label.training_session.settings.ai_stats",
        field: {
            required: false,
            type: FIELD_SWITCH,
            value: false,
        }
    },
    {
        code: GAME_SETTINGS_TOKEN_DISPLAY_MODE,
        title: "label.training_session.settings.token_display_mode",
        value: GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL,
        field: {
            type: FIELD_SELECT,
            required: true,
            label:"label.no_parameter",
            choices: [
                {
                    value: GAME_SETTINGS_TOKEN_DISPLAY_MODE_LINE,
                    label: "label.training_session.settings.token_display_mode_line",
                },
                {
                    value: GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL,
                    label: "label.training_session.settings.token_display_mode_spiral",
                }
            ],
            multiple: false
        }
    },
    {
        code: GAME_SETTINGS_SHOW_REMAINING_TOKENS,
        title: "label.training_session.settings.remaining_tokens",
        value: false,
        field: {
            required: false,
            type: FIELD_SWITCH,
        }
    }
];

function GameSettings(props){
    const {t, classes,setShowGameSettingsBoard} = props;
    const {user,trainingSession,socket,stats} = useSelector((state)=>state.trainingSessionState);
    const {gameSettings,changeGameSettingInProcess,changeGameSettingError} = trainingSession;
    const [currentGameSettings,setCurrentGameSettings] = useState(gameSettingsInit);
    const dispatch = useDispatch();
    const forMentor = user && user.type === MENTOR;
    useEffect(()=>{
        changeGameSetting(gameSettings);
    },[]);

    useEffect(()=>{
        if (changeGameSettingInProcess===false){
            if (!changeGameSettingError){
                dispatch(snackbarSuccess({
                    message: t("label.training_session.settings.update_success")
                }));
                setShowGameSettingsBoard(false);
            }
            else{
                dispatch(snackbarError({
                    message: t("label.training_session.settings.update_failed")
                }));
            }
        }
    },[changeGameSettingInProcess,changeGameSettingError]);

    const changeGameSetting = (settings)=>{
        return setCurrentGameSettings(currentGameSettings.map(item=>{
            if (settings && settings.hasOwnProperty(item.code)){
                item.value = settings[item.code];
            }
            return item;
        }));
    }

    const handleChange = (...props)=>{
        console.log(props);
        const e = props[0];
        const name = e.target.name;
        let value;
        switch (name){
            case GAME_SETTINGS_STATS_DASHBOARD:
            case GAME_SETTINGS_TOKEN_DISPLAY_MODE:
                value = e.target.value;
                break;
            default:
                value = props[1];
                break;
        }
        changeGameSetting({
            [name]: value
        });
    }

    const renderField = (row)=>{
        const {field,code,value} = row;
        const {type,required} = field;
        switch (type){
            case FIELD_SWITCH:
                return <GreenSwitch
                    checked={value}
                    onChange={handleChange}
                    color="secondary"
                    size={"medium"}
                    name={code}
                    required={!!required}
                />;
            case FIELD_SELECT:
                const {choices,multiple,label} = field;
                return <FormControl className={classes.selectControl}>
                    <Select fullWidth color={"secondary"} onChange={handleChange} required={!!required} value={value} displayEmpty multiple={!!multiple} name={code}>
                        {!required && <MenuItem value={""}>{t(label??"label.select")}</MenuItem>}
                        {!!choices && choices.length > 0 && choices.map(choice=>{
                            return <MenuItem value={choice.value}>{t(choice.label)}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            default:
                break;
        }
        return "";
    }

    return <Dialog
        maxWidth={"lg"}
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e)=>{
            setShowGameSettingsBoard(false);
        }}
    >
        <DialogTitle className={classes.popupTitle}>{t("title.training_session.game_settings")}</DialogTitle>
        <DialogContent><Table className={classes.table}>
        <TableBody>
            {currentGameSettings.length>0?currentGameSettings.map((row)=>{
                return <TableRow>
                    <TableCell className={classes.title}>{t(row.title)}</TableCell>
                    <TableCell>{renderField(row)}</TableCell>
                </TableRow>
            }):<TableRow>
                <TableCell colSpan={2}>{t("text.list.no_results")}</TableCell>
            </TableRow>}
        </TableBody>
    </Table></DialogContent>
        <DialogActions>
            <Button variant={"contained"} onClick={(e)=>{
                //setShowGameSettingsBoard(false);
                dispatch(updateGameSettings({
                    settings:currentGameSettings,
                    id: trainingSession.id,
                    forMentor
                }));
                // socket.sendMessage("changeGameSettings",{
                //     data: currentGameSettings
                // });
            }} type={"button"} color="secondary">
                <CheckRounded fontSize={"large"}/> {t("label.confirm")}
            </Button>
            <Button variant={"contained"} onClick={(e)=>{
                setShowGameSettingsBoard(false);
            }} type={"button"} color="default">
                <CloseRounded fontSize={"large"}/> {t("label.close")}
            </Button>
        </DialogActions>
    </Dialog>
}

export default withStyles(styles)(withTranslation()(GameSettings));