import {LOADING, SNACKBAR} from "./constants";

export const loading = (payload)=>({
    type: payload?LOADING.START:LOADING.STOP,
    payload
});

export const snackbarError = (payload)=>({
    type: payload===false?SNACKBAR.HIDE_ERROR:SNACKBAR.SHOW_ERROR,
    payload
});

export const snackbarSuccess = (payload)=>({
    type: payload===false?SNACKBAR.HIDE_SUCCESS:SNACKBAR.SHOW_SUCCESS,
    payload
});