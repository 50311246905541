import React from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {Line} from "react-chartjs-2";
import * as colors from "../../../../../constants/colors";
import clsx from "clsx";
const styles = {
    chart:{
        minHeight: 250
    }
}

function PercentageChart(props){
    const {data,classes,t,title,options,containerProps,...chartProps} = props;
    const dataChart = {
        labels: data.map((item)=>item.label),
        datasets: [
            {
                label: "%",
                data: data.map((item)=>item.value||0),
                fill: false,
                backgroundColor: colors.PRIMARY_COLOR,
                borderColor: colors.PRIMARY_COLOR_DARK,
            },
        ],
    }

    const chartOptions = {
        responsive:true,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        title: {
            display: !!title,
            text: title,
            fontSize: 20
        },
        plugins: {
            datalabels: {
                display: false,
            },
        },
        tooltips:{
            callbacks: {
                label: function(tooltipItem) {
                    console.log(tooltipItem);
                    return tooltipItem.yLabel;
                },
                display:false
            }
        },
        maintainAspectRatio:false,
        ...options
    }

    return <div className={clsx(classes.chart,{[props.className]:!!props.className})} {...containerProps}>
        <Line data={dataChart} options={chartOptions} {...chartProps} />
    </div>
}

export default withStyles(styles)(withTranslation()(PercentageChart))