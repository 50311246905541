import {withStyles,Switch} from "@material-ui/core";
import {purple, green, red,common} from '@material-ui/core/colors';
import * as colors from "../../constants/colors";
export const PurpleSwitch = withStyles({
    switchBase: {
        color: purple[300],
        '&$checked': {
            color: purple[500],
        },
        '&$checked + $track': {
            backgroundColor: purple[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

export const PrimarySwitch = withStyles({
    switchBase: {
        color: colors.PRIMARY_COLOR_LIGHT,
        '&$checked': {
            color: colors.PRIMARY_COLOR,
        },
        '&$checked + $track': {
            backgroundColor: colors.PRIMARY_COLOR,
        },
    },
    checked: {},
    track: {},
})(Switch);

export const SecondarySwitch = withStyles({
    switchBase: {
        color: colors.SECONDARY_COLOR_LIGHT,
        '&$checked': {
            color: colors.SECONDARY_COLOR,
        },
        '&$checked + $track': {
            backgroundColor: colors.SECONDARY_COLOR,
        },
    },
    checked: {},
    track: {},
})(Switch);

export const GreenSwitch = withStyles({
    track: {
    },
    checked: {
    },
    switchBase: {
        color: green[100],
        "& $track":{
            opacity: 0.5,
            backgroundColor: red[700],
        },
        '&$checked': {
            color: green[600],
        },
        '&$checked + $track': {
            opacity: .75,
            backgroundColor: green[600],
        },
    },
})(Switch);