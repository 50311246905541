import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {useTranslation} from "react-i18next";
import {makeStyles, Select, MenuItem, FormControl, Grid} from "@material-ui/core";
import * as colors from "../../constants/colors";
const styles = {
    copyright: {
        color: colors.SECONDARY_COLOR
    },
}
const useStyles = makeStyles(styles);

export function Copyright(props) {
    const {t,i18n} = useTranslation();
    const classes = useStyles();
    return (
        <Grid container direction={"column"} alignItems={"center"}>
            <Typography className={classes.copyright} variant="body2" color="textSecondary" align="center">
                <span>{'©'}{new Date().getFullYear()} - </span>
                <Link color="inherit" href="/">
                    {t("label.plaisir_math")}
                </Link>
            </Typography>
        </Grid>
    );
}