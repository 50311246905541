import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authReducer from './auth/reducer';
import appReducer from './app/reducer';
import homeReducer from './home/reducer';
import trainingSessionReducer from './trainingSession/reducer';
import {
    adminReducer
} from 'react-admin';
import {LOGOUT} from "./auth/constants";

const rootPersistConfig = {
    key: 'root',
    storage: storage,
};
const commonPersistConfig = {
    key: 'commonState',
    storage: storage,
};

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    routing: connectRouter(history),
    authState: authReducer,
    appState: appReducer,
    admin: adminReducer,
    //homeState: homeReducer,
    trainingSessionState: trainingSessionReducer
});

export default (history) => {
    return persistReducer({ ...rootPersistConfig, ...commonPersistConfig }, rootReducer(history));
}
