import React, {useEffect} from "react";
import {useHistory, withRouter,Route} from "react-router";
import {createMuiTheme, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import { Admin,Resource,fetchUtils,defaultTheme,Layout } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import Dashboard from "./components/Dashboard";
import Error404 from "../exception/Error404";
import Menu from "./components/Menu";
import {useDispatch, useSelector} from "react-redux";
import {
    TrainingSessionCreate,
    TrainingSessionEdit,
    TrainingSessionList,
    TrainingSessionView
} from "./resources/trainingSession";
import * as links from "../../constants/links";
import {createBrowserHistory} from "history";
import {theme} from "../../themes/muiTheme";
import {pink, red, yellow} from "@material-ui/core/colors";
import _ from "lodash";
import {ParameterEdit, ParameterList} from "./resources/parameter";
import {List, School, Settings} from "@material-ui/icons";
import AdminBar from "./components/AdminBar";
import * as colors from "../../constants/colors";
import {ProfileEdit} from "./components/Profile";
import DataProvider from "./providers/DataProvider";
import {BACKOFFICE_TRAINING_SESSION_VIEW, BACKOFFICE_USER_PROFILE} from "../../constants/links";
import {logout} from "../../sagas/auth/actions";

const styles = {
    adminContainer:{
        marginTop: 20,
        "& .layout":{
            borderRadius: 20
        },
        "& main":{
            marginTop: "1rem"
        }
    }
};
const initialState = {
    theme: 'dark',
    grid: 5,
};

const history = createBrowserHistory({basename: links.BACKOFFICE});

const adminTheme = createMuiTheme({
    ...defaultTheme,
    palette: {
        type: 'light',
        primary: {
            main: colors.PRIMARY_COLOR,
            light: colors.PRIMARY_COLOR_LIGHT,
            dark: colors.PRIMARY_COLOR_DARK
        },
        secondary: {
            main: colors.SECONDARY_COLOR,
            light: colors.PRIMARY_COLOR,
            dark: colors.SECONDARY_COLOR_DARK
        },
        text:{
            primary: colors.TEXT_PRIMARY_COLOR,
            secondary: "#bababa",
            disabled: colors.TEXT_DISABLED_COLOR
        },
        background:{
            paper: "#fff",
            default: "#fff",
        },
    },
    sidebar:{
        width: 300
    }
});

const AdminLayout = (props) => <Layout {...props} menu={Menu} appBar={AdminBar} />;



const httpClient = (url, options = {},jwtToken,dispatch) => {
    console.log(options);
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set("Authorization",'Bearer '+jwtToken);
    return fetchUtils.fetchJson(url, options).catch((error)=>{
        if (error.status === 401){
            dispatch(logout());
        }
        else{
            throw error;
        }
    });
}

function AdminHome(props){
    const {t,classes,i18n} = props;
    const dispatch = useDispatch();
    const i18nProvider = {
        translate: (key, options) => {
            let translated = t(key);
            if (options){
                Object.keys(options).forEach((key)=>{
                   if (key!=="_"){
                       translated = _.replace(translated,"%{"+key+"}",options[key]);
                   }
                });
            }
            return translated;
        },
        changeLocale: locale => i18n.changeLangue(locale),
        getLocale: () => i18n.language,
    }
    const {jwtToken,user} = useSelector(state=>state.authState);
    useEffect(()=>{
        console.log(props.history.location.pathname,history.location.pathname);
        const currentExternePathName = props.history.location.pathname;
        const newExternePathName = currentExternePathName.substr(currentExternePathName.search(links.BACKOFFICE)===0?links.BACKOFFICE.length:0);
        const pathname = history.location.pathname;
        const newPathName = pathname.substr(pathname.search(links.BACKOFFICE)===0?links.BACKOFFICE.length:0);
        if (newExternePathName!==newPathName){
            props.history.replace(links.BACKOFFICE+newPathName);
        }
        if (history.location.pathname.search(links.BACKOFFICE)===0){
            history.replace(newPathName);
        }
    });

    return <div className={classes.adminContainer}>
        <Admin
            theme={adminTheme}
            dataProvider={DataProvider(simpleRestProvider(process.env.REACT_APP_API_BASE_URL,(apiUrl,options)=>httpClient(apiUrl,options,jwtToken,dispatch)))}
            title={t("backoffice.title.dashboard_admin")}
            i18nProvider={i18nProvider}
            authProvider={false}
            history={history}
            initialState={initialState}
            dashboard={Dashboard}
            //disableTelemetry={true}
            catchAll={Error404}
            layout={AdminLayout}
            customRoutes={[
                <Route
                    key={"user-profile"}
                    path={BACKOFFICE_USER_PROFILE}
                    component={ProfileEdit}
                />,
                <Route
                    key={"training-session-view"}
                    path={BACKOFFICE_TRAINING_SESSION_VIEW}
                    component={TrainingSessionView}
                />
            ]}
        >
            <Resource name="training-sessions" icon={<School/>} create={TrainingSessionCreate} edit={TrainingSessionEdit} list={TrainingSessionList} options={{
                label: t("backoffice.menu.training_session")
            }}/>
            <Resource name="parameters" edit={ParameterEdit} icon={<Settings/>} list={ParameterList} options={{
                label: t("backoffice.menu.parameter")
            }}/>
        </Admin>
    </div>;
}

export default withRouter(withStyles(styles)(withTranslation()(AdminHome)));
