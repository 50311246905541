import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/sagas';
import homeSaga from './home/sagas';
import trainingSessionSagas from './trainingSession/sagas';

export default function* rootSaga() {
    yield all([
        fork(authSaga),
        //fork(homeSaga),
        fork(trainingSessionSagas),
    ]);
}