import React, {useState} from "react";
import {
    AppBar,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    withStyles,
    TextField,
    Button,
    Snackbar,
    Select, MenuItem, FormControl
} from '@material-ui/core';
import {Alert} from "@material-ui/lab";
import {withTranslation} from "react-i18next";
import {HomeOutlined} from "@material-ui/icons";
import {Link} from "react-router-dom";
import * as links from "../../constants/links";
import * as colors from "../../constants/colors";
import {useDispatch, useSelector} from "react-redux";
import * as authActions from "../../sagas/auth/actions";
import {handleInputFieldChange, logout} from "../../sagas/auth/actions";
import axios from "axios";
import {quit} from "../../sagas/trainingSession/actions";
import frIcon from "../../images/flags/fr.png";
import ukIcon from "../../images/flags/uk.png";
const styles = {
    logo:{
        height: 48,
        marginRight: 5
    },
    homeIcon:{
        "& svg":{
            fontSize: "2rem",
            color: "white"
        }
    },
    homeLink:{
        textDecoration: "none",
        color: "white",
        "&:hover,&:hover svg":{
            color: colors.SECONDARY_COLOR
        }
    },
    userBlock:{
        flexGrow: 1
    },
    userInfos:{
        justifyContent: "flex-end"
    },
    input:{

    },
    appBar:{
        background: colors.PRIMARY_COLOR_DARK,
        height: 64
    },
    selectLanguage:{
        marginBottom: ".5rem",
        "& .MuiFilledInput-inputMarginDense":{
            paddingTop: 10
        }
    },
    flag:{
        height: 25,
        marginRight: 5,
        marginTop: -2
    },
    selectLanguageMenu:{
        "& .MuiSelect-selectMenu":{
            display: "flex",
            alignItems: "center",
            flexDirection: "row"
        },
        marginRight: 10
    }
};

function Menu(props){
    const {classes,t,i18n} = props;
    const dispatch = useDispatch();
    const authState= useSelector((state)=>state.authState);
    const {error,authenticated,loading} = authState;
    const loggedUser = authState.user;

    const {user,trainingSession} = useSelector((state)=>state.trainingSessionState);

    const handleLogout = (e)=>{
        if (loggedUser){
            dispatch(logout());
        }
        else if (trainingSession){
            dispatch(quit({
                trainingSessionId: trainingSession.id
            }));
        }
    }

    const onChangeLanguage = (event)=>{
        i18n.changeLanguage(event.target.value);
    }

    return <AppBar className={classes.appBar}>
        <Toolbar>
            <Link to={links.HOME} className={classes.homeLink}>
                <Grid container alignItems={"center"}>
                    <img src={"/logo192.png"} alt={"logo"} className={classes.logo}/>
                    {/*<IconButton edge={"start"} className={classes.homeIcon}>*/}
                    {/*    <HomeOutlined fontSize={"large"}/>*/}
                    {/*</IconButton>*/}
                    <Typography variant="h6">{t("title.app")}</Typography>
                </Grid>
            </Link>
            <div className={classes.userBlock}>
                <Grid container alignItems={"center"} spacing={1} className={classes.userInfos}>
                <FormControl className={classes.selectLanguage}>
                    <Select
                        variant={"filled"}
                        margin={"dense"}
                        labelId="select-language-label"
                        className={classes.selectLanguageMenu}
                        value={i18n.language}
                        onChange={onChangeLanguage}
                        disableUnderline
                    >
                        <MenuItem alignItems={"center"}value={"fr"}><img className={classes.flag} src={frIcon} alt={"fr"}/><Typography component={"span"}>{t("label.french", {lng: "fr"})}</Typography></MenuItem>
                        <MenuItem alignItems={"center"} value={"en"}><img className={classes.flag} src={ukIcon} alt={"en"}/><Typography component={"span"}>{t("label.english", {lng: "en"})}</Typography></MenuItem>
                    </Select>
                </FormControl>
                {(!!user||!!loggedUser) && <React.Fragment>
                    <Grid item>
                        <Typography>{t("text.welcome_user",{user:!!loggedUser?loggedUser.username:user.nickname})}</Typography>
                    </Grid>
                    <Grid item>
                        <Button color={"secondary"} variant={"contained"} onClick={handleLogout}>{t("label.logout")}</Button>
                    </Grid>
                </React.Fragment>}
                </Grid>
            </div>
        </Toolbar>
    </AppBar>;
}

export default withStyles(styles)(withTranslation()(Menu));