import { put, takeEvery, all,call } from 'redux-saga/effects';
import * as apis from "./apis";
import {
    TRAINING_SESSION_GAME_SETTINGS_CHANGE,
    TRAINING_SESSION_GET,
    TRAINING_SESSION_GET_PLAYERS, TRAINING_SESSION_JOIN_GAME,
    TRAINING_SESSION_LOGIN, TRAINING_SESSION_MATCHES_IN_PLAYING, TRAINING_SESSION_MENTOR_STATS,
    TRAINING_SESSION_QUIT
} from "./constants";
import {eventChannel, END} from 'redux-saga';
import TrainingSessionSocket from "../../services/TrainingSessionSocket";
import {getPlayers} from "./actions";

function* handler(action,api,data) {
    try {
        const resJson = yield call(api,data.payload);
        yield put({ type: action.SUCCESS, payload: { data: resJson } });
    } catch (err) {
        yield put({ type: action.FAIL, payload: { error: err.message } });
    }
}

function* callGenerator(action,api) {
    yield takeEvery(action.REQUEST, handler,action,api);
}


export default function* trainingSessionSaga() {
    yield all([
        callGenerator(TRAINING_SESSION_LOGIN,apis.login),
        callGenerator(TRAINING_SESSION_GET,apis.getSession),
        callGenerator(TRAINING_SESSION_QUIT,apis.logout),
        callGenerator(TRAINING_SESSION_GET_PLAYERS,apis.getSessionPlayers),
        callGenerator(TRAINING_SESSION_JOIN_GAME,apis.joinGame),
        callGenerator(TRAINING_SESSION_MENTOR_STATS,apis.getMentorStats),
        callGenerator(TRAINING_SESSION_GAME_SETTINGS_CHANGE,apis.changeGameSettings),
        callGenerator(TRAINING_SESSION_MATCHES_IN_PLAYING,apis.getGamesInPlaying),
    ]);
}