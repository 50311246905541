import * as actions from "./constants";
import axios from "axios";
const LOGGED_USER_DATA="loggedUserData";
let loggedUserData;
try{
    loggedUserData = JSON.parse(localStorage.getItem(LOGGED_USER_DATA));
}
catch (error){
    loggedUserData = null;
}
const initialState = {
    user: !!loggedUserData?loggedUserData.item:null,
    error: null,
    authenticated: !!loggedUserData && !!loggedUserData.item,
    loading: false,
    loginAuto: false,
    jwtToken:!!loggedUserData?loggedUserData.jwtToken:null
};

export default (state = initialState,action)=>{
    if (action.type===actions.LOGIN.FAIL){
        state = {
            ...state,
            error: action.payload.error,
            loading: false
        }
    }
    else if (action.type===actions.LOGIN.SUCCESS){
        const user = action.payload.data.item;
        const jwtToken = action.payload.data.jwtToken;
        axios.defaults.headers.Authorization = "Bearer "+jwtToken;
        localStorage.setItem(LOGGED_USER_DATA,JSON.stringify(action.payload.data));
        state = {
            ...state,
            user:user,
            authenticated:true,
            loading: false,
            jwtToken:jwtToken
        }
    }
    else if (action.type === actions.LOGIN.REQUEST){
        state = {
            ...state,
            error: null,
            loading: true,
            loginAuto: action.payload.auto
        }
    }
    else if (action.type === actions.LOGIN.INPUT_FIELD_CHANGE){
        state = {
            ...state,
            error: null
        }
    }
    else if (action.type===actions.LOGOUT.SUCCESS){
        if (axios.defaults.headers.Authorization){
            delete axios.defaults.headers.Authorization;
        }
        localStorage.removeItem(LOGGED_USER_DATA);
        state = {
            ...state,
            user:null,
            authenticated: false,
            players:[],
            jwtToken:null
        }
    }
    else if (action.type === actions.UPDATE_USER){
        const user = action.payload.data.item;
        localStorage.setItem(LOGGED_USER_DATA,JSON.stringify({
            item:user,
            jwtToken: state.jwtToken
        }));
        state = {
            ...state,
            user:user
        }
    }
    return state;
}