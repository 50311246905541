import {LOADING, SNACKBAR} from "./constants";
const initialState = {
    loading: false,
    snackbar: {
        error:{
            show: false,
            message: null,
            callbackOnClose:null
        },
        success:{
            show: false,
            message: null,
            callbackOnClose:null
        }
    }
}
export default (state=initialState,action)=>{
    if (action.type === LOADING.START){
        state = {
            ...state,
            loading: true
        };
    }
    else if (action.type === LOADING.STOP){
        state = {
            ...state,
            loading: false
        };
    }
    else if (action.type === SNACKBAR.SHOW_SUCCESS){
        state = {
            ...state,
            snackbar: {
                ...state.snackbar,
                success: {
                    show: true,
                    message: action.payload.message,
                    callbackOnClose: action.payload.onClose||null
                }
            }
        }
    }
    else if (action.type === SNACKBAR.HIDE_SUCCESS){
        state = {
            ...state,
            snackbar: {
                ...state.snackbar,
                success: {
                    show: false,
                    message: null
                }
            }
        }
    }
    else if (action.type === SNACKBAR.SHOW_ERROR){
        state = {
            ...state,
            snackbar: {
                ...state.snackbar,
                error: {
                    show: true,
                    message: action.payload.message,
                    callbackOnClose: action.payload.onClose||null
                }
            }
        }
    }
    else if (action.type === SNACKBAR.HIDE_ERROR){
        state = {
            ...state,
            snackbar: {
                ...state.snackbar,
                error: {
                    show: false,
                    message: null
                }
            }
        }
    }
    return state;
}