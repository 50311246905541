import React from "react";

const addUserProfileOverrides = dataProvider => ({
    ...dataProvider,
    updateUserProfile(params) {
        console.log(dataProvider,params);
        return dataProvider.update('users',params);
    }
});

export default addUserProfileOverrides;

