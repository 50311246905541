import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {API_BASE_URL} from "./constants/envs";
import { Provider } from 'react-redux';
import configureStore from "./sagas/store";
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import {ContentMultipleListLoader} from './components/loading/loaders';
import './i18n/i18n';
import {ConnectedRouter} from "connected-react-router";
// Init state
const initialState = {};
// Create browser history
const history = createBrowserHistory();
// Configure store
const {store, persistor} = configureStore(history, initialState);
export const appStore = store;
persistor.purge();

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["Accept"] = "application/json";
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate
              loading={<ContentMultipleListLoader />}
              persistor={persistor}>
              <ConnectedRouter history={history}>
                <App/>
              </ConnectedRouter>
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "dev")
    console.log = () => {};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
