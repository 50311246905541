import React, {useCallback, useMemo, useState} from 'react';
import {
    TextInput, SimpleForm, required, SaveContextProvider, useDataProvider, useNotify, useTranslate
    , Edit, Toolbar, SaveButton,Title
} from 'react-admin';
import {useDispatch, useSelector} from "react-redux";
import {InputAdornment, makeStyles} from "@material-ui/core";
import {Email, PersonRounded, Security, TextFields} from "@material-ui/icons";
import {updateUser} from "../../../sagas/auth/actions";
const styles = {

};

const useStyles = makeStyles(styles);

const EditToolBar = props => {
    const classes = useStyles();
    return <Toolbar {...props} className={classes.toolbar} >
        <SaveButton />
    </Toolbar>
};
export const ProfileEdit = ({ staticContext, ...props }) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();
    const [saving, setSaving] = useState(false);
    const {user,authenticated} = useSelector((state)=>state.authState);
    const dispatch = useDispatch();
    const handleSave = useCallback((values) => {
        setSaving(true);
        dataProvider.updateUserProfile(
            {
                data: {
                    ...values
                },
                id: user.id
            },
            {
                onSuccess: ({ data }) => {
                    setSaving(false);
                    dispatch(updateUser({
                        data:{
                            item:data
                        }
                    }))
                    notify(translate("text.success.default"), "info");
                },
                onFailure: () => {
                    setSaving(false);
                    notify(
                        translate("error.internal_error"),
                        "warning"
                    );
                }
            }
        );
    }, [dataProvider, notify]);
    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    if (!authenticated) {
        return null;
    }

    return (
        <SaveContextProvider value={saveContext}>
            <Title title={translate("backoffice.menu.profile")}/>
            <SimpleForm saving={saving} save={handleSave} record={user ? user : {}} toolbar={<EditToolBar/>}>
                <TextInput InputProps={{
                    startAdornment:<InputAdornment position="start">
                        <PersonRounded />
                    </InputAdornment>
                }} source="username" required validate={required()} label={translate("resources.users.fields.username")} />
                <TextInput InputProps={{
                    startAdornment:<InputAdornment position="start">
                        <PersonRounded />
                    </InputAdornment>,
                    type: "password",
                    autoComplete: "off"
                }} source="" name={"newPassword"} label={translate("resources.users.fields.new_password")} />
                <TextInput InputProps={{
                    type: "email",
                    startAdornment:<InputAdornment position="start">
                        <Email />
                    </InputAdornment>
                }} source="person.email" label={translate("resources.users.fields.person.email")} />
                <TextInput InputProps={{
                    startAdornment:<InputAdornment position="start">
                        <TextFields />
                    </InputAdornment>
                }} source="person.lastName" label={translate("resources.users.fields.person.lastName")} />
                <TextInput InputProps={{
                    startAdornment:<InputAdornment position="start">
                        <TextFields />
                    </InputAdornment>
                }} source="person.firstName" label={translate("resources.users.fields.person.firstName")} />
            </SimpleForm>
        </SaveContextProvider>
    );
};