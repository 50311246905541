import React from "react";
import {
    CircularProgress, Grid,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {PLAYER_STATUS_DISCONNECTED} from "../../../../constants/params";
import {AccountCircleRounded} from "@material-ui/icons";
import * as colors from "../../../../constants/colors";
import clsx from "clsx";
const styles = {
    paper:{
        background:"transparent",
        boxShadow: "none"
    },
    table:{
        margin: "auto",
        "& th":{
            textAlign: "center",
            fontWeight: "bold",
        },
        "& td":{
            textAlign: "center"
        },
        tableLayout: "fixed",
        maxWidth: "1200px",
        background: "transparent",
        boxShadow: "none",
        outline: "none"
    },
    cell:{
        color: "#2d2c2c",
        textAlign: "center",
        padding: 5,
        backgroundColor: "#dbd6cd",
        border: "1px solid black"
    }
};
function PercentageTable(props){
    const {classes,t,data,hasTotalMatches,containerClass} = props;
    return <TableContainer className={clsx(classes.paper,{[containerClass]:!!containerClass})} component={Paper}>
        <div className={classes.tableContainer}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {hasTotalMatches && <TableCell className={classes.cell} colSpan={2}>{t("label.training_session.token")}</TableCell>}
                        {Object.keys(data).map((token)=>{
                            return <TableCell className={classes.cell}>{token}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hasTotalMatches && <TableRow>
                        {hasTotalMatches && <TableCell className={classes.cell} colSpan={2}>{t("label.training_session.total_matches")}</TableCell>}
                        {Object.keys(data).map((token)=>{
                            return <TableCell className={classes.cell}>{data[token].totalMatches||0}</TableCell>
                        })}
                    </TableRow>}
                    <TableRow>
                        {hasTotalMatches && <TableCell className={classes.cell} colSpan={2}>{t("label.training_session.win_rate")}</TableCell>}
                        {Object.keys(data).map((token)=>{
                            return <TableCell className={classes.cell}>{(data[token].value||0)+"%"}</TableCell>
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    </TableContainer>;
}

export default withStyles(styles)(withTranslation()(PercentageTable))