import React from "react";
import {v4 as UUIDV4} from "uuid";
import {io} from "socket.io-client";
const WS_URL = process.env.REACT_APP_WEBSOCKET_URL || "";
export default class TrainingSessionSocket {
    constructor(trainingSessionId,queryParams,callbacks) {
        this.socket = null;
        this.isOpen = false;
        this.uuid = UUIDV4();
        this.queryParams = queryParams||{};
        this.bodyParams = {...this.queryParams};
        this.callbacks = callbacks;
        this.trainingSessionId = trainingSessionId;
    }

    /**
     *  Set up WebSocket connection for a new user and
     *  basic listeners to handle events
     */
    initSocket = () => {
        const queryParams = this.queryParams;
        this.socket = new io(WS_URL,{
            query:queryParams
        });
        this.socket.on("connect",this.onConnOpen);
        this.socket.on("message",this.onMessage);
        this.socket.on("close",this.onConnClose);
        this.socket.on("error",this.onError);
        this.socket.on("newUserConnect",this.onNewUserConnect);
        this.socket.on("userDisconnect",this.onUserDisconnect);
        this.socket.on("startGame",this.onStartGame);
    }

    callback = (eventName,data)=>{
        if (this.callbacks && this.callbacks.hasOwnProperty(eventName)){
            this.callbacks[eventName](data);
        }
    }

    onStartGame = (data)=>{
        this.callback("onStartGame",data);
    }

    onUserDisconnect = (data)=>{
        console.log("disconnected");
        this.callback("onUserDisconnect",data);
    }

    onNewUserConnect = (data)=>{
        console.log("data");
        this.callback("onNewUserConnect",data);
    }

    /**
     *  Show connection status to user
     */
    onConnOpen = () => {
        console.log('Websocket connected for '+this.uuid+'!');
        this.isOpen=true;
        this.callback("onConnect");
    }

    /**
     *  Log lost connection for now
     */
    onConnClose = () => {
        console.log('Websocket closed!');
        this.callback("onClose");
    }

    onError = (error)=>{
        console.log("Socket Error: "+error.toString());
        this.callback("onError");
    }

    /**
     *  Used by application to send message to the WebSocket API Gateway
     *  @param routeKey The route key for WebSocket API Gateway
     *  @param message String message
     *  message {
     *    room,
     *    type,
     *    msg,
     *    username,
     *    for
     *  }
     */
    sendMessage = (routeKey, message) => {
        if(this.socket && this.isOpen){
            console.log("Send message to "+routeKey);
            this.socket.send(JSON.stringify({
                action: routeKey,
                data: JSON.stringify(Object.assign(this.bodyParams,message||{}))
            }));
        }
        else{
            console.log(`Websocket connection not found!!`);
        }
    }

    /**
     * Handler that receives the actual messages from the WebSocket API
     * For now it simply returns the parsed message body
     * @param data Message body received from WebSocket
     */
    onMessage = (data) => {
        console.log(data);
        if (data) {
            this.callback("onMessage",data)
        }
        else {
            console.log('No handler found for message type');
        }
    }

    destroy = ()=>{
        if (this.socket){
            this.socket.close();
        }
        this.socket = null;
    }

    static initWSService(trainingSessionId,queryParams,callbacks) {
        const WSService = new TrainingSessionSocket(trainingSessionId,queryParams,callbacks);
        //WSService.initSocket();
        return WSService;
    }

}