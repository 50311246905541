import {
    TRAINING_SESSION_GET,
    TRAINING_SESSION_LOGIN,
    TRAINING_SESSION_QUIT,
    TRAINING_SESSION_GET_PLAYERS,
    TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
    TRAINING_SESSION_JOIN_GAME,
    TRAINING_SESSION_INIT_SOCKET,
    TRAINING_SESSION_UPDATE_PLAYERS,
    TRAINING_SESSION_START_GAME,
    TRAINING_SESSION_END_GAME,
    TRAINING_SESSION_GAME_ROOM_NEXT_STEP,
    TRAINING_SESSION_QUIT_GAME,
    TRAINING_SESSION_JOIN_WAITING_ROOM,
    TRAINING_SESSION_QUIT_WAITING_ROOM,
    TRAINING_SESSION_CHANGE_STATUS,
    TRAINING_SESSION_MENTOR_STATS,
    TRAINING_SESSION_GOTO_NEW,
    TRAINING_SESSION_TOGGLE_STATS_DIALOG,
    TRAINING_SESSION_SOCKET_CONNECTION,
    TRAINING_SESSION_GAME_SETTINGS_CHANGE,
    TRAINING_SESSION_MATCHES_IN_PLAYING,
    TRAINING_SESSION_START_SEE_GAME, TRAINING_SESSION_RESET
} from "./constants";
import {appStore} from "../../index";
import {getPlayers} from "./actions";
import {
    PLAYER,
    PLAYER_STATUS_DISCONNECTED,
    SESSION_ENDED,
    SESSION_PAUSED, SESSION_STARTED,
    SESSION_STOPPED
} from "../../constants/params";
import {act} from "@testing-library/react";
const initGameRoom = {
    searchRoomInProcess: false,
    messages: [],
    players: [],
    game: null,
    steps: [],
    winner: null,
    requestRoomInterval: null
};
const initialState = {
    user:null,
    canLogged: null,
    trainingSession: null,
    gotoNewTrainingSession: false,
    initTrainingSession: false,
    checkLoginInProcess: false,
    error: null,
    jwtToken:null,
    gamesInPlaying: {
        items: [],
        isLoading: null,
        error: false
    },
    players: {
        isLoading: null,
        items: [],
        error: false
    },
    waitingRoom: {
        inSearching: false,
        played: false,
        messages: []
    },
    gameRoom:{
        ...initGameRoom
    },
    stats:{
        mentor:{
            data: null,
            loading: null,
            error: null
        },
        player:null,
        showStatsDialog: false
    },
    socket: null
};

export default (state = initialState,action)=>{
    if (action.type){
        switch (action.type){
            case TRAINING_SESSION_GET.REQUEST:
                state = {
                    ...state,
                    initTrainingSession: true
                }
                break;
            case TRAINING_SESSION_GET.FAIL:
                state = {
                    ...state,
                    trainingSession: false
                }
                break;
            case TRAINING_SESSION_GET.SUCCESS:
                state = {
                    ...state,
                    trainingSession: action.payload.data
                }
                break;
            case TRAINING_SESSION_GOTO_NEW:
                if (state.socket){
                    state.socket.destroy();
                }
                state = {
                    ...initialState,
                    trainingSession: null,
                    initTrainingSession: false,
                    gotoNewTrainingSession: true
                }
                break;
            case TRAINING_SESSION_LOGIN.REQUEST:
                state = {
                    ...state,
                    checkLoginInProcess: true,
                    gotoNewTrainingSession:false
                }
                break;
            case TRAINING_SESSION_LOGIN.FAIL:
                const isAccountError = ["connected_account","2_connections_same_pc","nickname_exists"].includes(action.payload.error);
                state = {
                    ...state,
                    checkLoginInProcess: false,
                    canLogged: false,
                    error: {
                        ...state.error,
                        password: state.canLogged!==null && !isAccountError?"error."+(action.payload.error||"unknown"):undefined,
                        account: state.canLogged!==null && isAccountError?"error."+(action.payload.error||"unknown"):undefined,
                    }
                }
                break;
            case TRAINING_SESSION_LOGIN.SUCCESS:
                const user = action.payload.data.item;
                state = {
                    ...state,
                    user: user,
                    jwtToken:action.payload.data.jwtToken,
                    canLogged: true,
                    checkLoginInProcess: false,
                    error: {
                        ...state.error,
                        password: undefined
                    }
                }
                break;
            case TRAINING_SESSION_QUIT.SUCCESS:
            case TRAINING_SESSION_RESET:
                if (state.socket){
                    state.socket.destroy();
                }
                state = {
                    ...initialState,
                    canLogged: action.type === TRAINING_SESSION_RESET?null:false
                }
                break;
            case TRAINING_SESSION_QUIT.FAIL:
                state = {
                    ...state,
                    error: {
                        ...state.error,
                        logout: "error.logout_failed"
                    }
                }
                break;
            case TRAINING_SESSION_GET_PLAYERS.REQUEST:
                state = {
                    ...state,
                    players: {
                        ...state.players,
                        isLoading: true,
                        error: false
                    }
                };
                break;
            case TRAINING_SESSION_GET_PLAYERS.SUCCESS:
                state = {
                    ...state,
                    players: {
                        isLoading: false,
                        items: action.payload.data.items,
                        error: false
                    }
                };
                break;
            case TRAINING_SESSION_GET_PLAYERS.FAIL:
                state = {
                    ...state,
                    players: {
                        isLoading: false,
                        items: [],
                        error: true
                    }
                };
                break;
            case TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM:
                const waitingRoomMessages = [...state.waitingRoom.messages];
                waitingRoomMessages.push(action.payload.data.item);
                state = {
                    ...state,
                    waitingRoom: {
                        ...state.waitingRoom,
                        messages: waitingRoomMessages
                    }
                };
                break;
            case TRAINING_SESSION_INIT_SOCKET:
                state = {
                    ...state,
                    socket: action.payload
                }
                state.socket.initSocket();
                break;
            case TRAINING_SESSION_JOIN_GAME.REQUEST:
                state = {
                    ...state,
                    gameRoom: {
                        searchRoomInProcess:true,
                        game: null,
                        messages: []
                    }
                }
                break;
            case TRAINING_SESSION_JOIN_GAME.SUCCESS:
                state = {
                    ...state,
                    gameRoom: {
                        searchRoomInProcess:false,
                        game: action.payload.data.game,
                        players: action.payload.data.players,
                        messages: []
                    }
                }
                break;
            case TRAINING_SESSION_JOIN_GAME.FAIL:
                state = {
                    ...state,
                    gameRoom: {
                        searchRoomInProcess:false,
                        game: null,
                        messages: []
                    }
                }
                break;
            case TRAINING_SESSION_UPDATE_PLAYERS:
                let playerItems = [...state.players.items];
                const {newUsers,disconnectedUsers} = action.payload;
                if (newUsers && newUsers.length){
                    newUsers.forEach((newUser)=>{
                        if (newUser.type === PLAYER){
                            const index = playerItems.findIndex((item)=>item.id===newUser.id);
                            if (index===-1){
                                playerItems.push(newUser);
                            }
                            else{
                                playerItems[index] = newUser;
                            }
                        }
                    });
                }
                if (disconnectedUsers && disconnectedUsers.length){
                    const disconnectedUserIds = disconnectedUsers.map((disconnectedUser)=>{
                        return disconnectedUser.id;
                    });
                    playerItems = playerItems.map((playerItem)=>{
                        if (disconnectedUserIds.includes(playerItem.id)){
                            playerItem.status = PLAYER_STATUS_DISCONNECTED;
                        }
                        return playerItem;
                    })
                }
                state = {
                    ...state,
                    players: {
                        ...state.players,
                        items: playerItems
                    }
                }
                break;
            case TRAINING_SESSION_START_GAME:
                if (state.waitingRoom.requestRoomInterval){
                    clearInterval(state.waitingRoom.requestRoomInterval);
                }
                state = {
                    ...state,
                    waitingRoom: {
                        ...state.waitingRoom,
                        inSearching: false,
                    },
                    gameRoom: {
                        ...state.gameRoom,
                        steps: action.payload.data.steps,
                        players: action.payload.data.players,
                        requestRoomInterval: null
                    }
                }
                break;
            case TRAINING_SESSION_START_SEE_GAME:
                if (state.waitingRoom.requestRoomInterval){
                    clearInterval(state.waitingRoom.requestRoomInterval);
                }
                state = {
                    ...state,
                    waitingRoom: {
                        ...state.waitingRoom,
                        inSearching: false,
                    },
                    gameRoom: {
                        ...state.gameRoom,
                        game: action.payload.data.game,
                        steps: action.payload.data.steps,
                        players: action.payload.data.players,
                        requestRoomInterval: null
                    }
                }
                break;
            case TRAINING_SESSION_END_GAME:
                const stepsEndGame = [...state.gameRoom.steps];
                if (action.payload.data.lastStep){
                    stepsEndGame.push(action.payload.data.lastStep);
                }
                state = {
                    ...state,
                    gameRoom:{
                        ...state.gameRoom,
                        winner:action.payload.data.winner,
                        steps: stepsEndGame,
                        status: action.payload.data.status,
                        nbPointsWinner: action.payload.data.nbPointsWinner,
                        nbPointsLoser: action.payload.data.nbPointsLoser,
                        nbPointsDraw: action.payload.data.nbPointsDraw,
                        gameRanking: action.payload.data.ranking,
                    }
                }
                break;
            case TRAINING_SESSION_GAME_ROOM_NEXT_STEP:
                const steps = [...state.gameRoom.steps];
                steps[steps.length-1] = action.payload.data.lastStep;
                steps.push(action.payload.data.step);
                state = {
                    ...state,
                    gameRoom: {
                        ...state.gameRoom,
                        steps: steps
                    }
                }
                break;
            case TRAINING_SESSION_QUIT_GAME:
                state = {
                    ...state,
                    waitingRoom: {
                        ...state.waitingRoom,
                        played:state.user && state.user.type===PLAYER,
                        inSearching: false,
                    },
                    gameRoom:{
                        ...initGameRoom
                    }
                }
                break;
            case TRAINING_SESSION_JOIN_WAITING_ROOM:
                const sessionStatus = state.trainingSession.status;
                if (state.waitingRoom.requestRoomInterval){
                    clearInterval(state.waitingRoom.requestRoomInterval);
                }
                if (sessionStatus!==SESSION_STARTED){
                    if (action.payload.requestRoomInterval){
                        clearInterval(action.payload.requestRoomInterval);
                    }
                }
                state = {
                    ...state,
                    waitingRoom: {
                        ...state.waitingRoom,
                        inSearching: sessionStatus===SESSION_STARTED,
                        requestRoomInterval: sessionStatus===SESSION_STARTED?action.payload.requestRoomInterval:null
                    }
                }
                break;
            case TRAINING_SESSION_QUIT_WAITING_ROOM:
                state = {
                    ...state,
                    waitingRoom: {
                        ...state.waitingRoom,
                        inSearching: false
                    }
                }
                break;
            case TRAINING_SESSION_CHANGE_STATUS:
                if (state.waitingRoom.requestRoomInterval){
                    clearInterval(state.waitingRoom.requestRoomInterval);
                }
                state = {
                    ...state,
                    trainingSession: {
                        ...state.trainingSession,
                        status: action.payload.data.newStatus,
                        statusChanged: true
                    },
                    waitingRoom: {
                        ...state.waitingRoom,
                        inSearching: false,
                        requestRoomInterval: null
                    }
                }
                break;
            case TRAINING_SESSION_MENTOR_STATS.REQUEST:
                state = {
                    ...state,
                    stats: {
                        ...state.stats,
                        mentor:{
                            ...state.stats.mentor,
                            loading: true
                        }
                    }
                }
                break;
            case TRAINING_SESSION_MENTOR_STATS.SUCCESS:
                state = {
                    ...state,
                    stats: {
                        ...state.stats,
                        mentor:{
                            ...state.stats.mentor,
                            data: action.payload.data.items,
                            loading: false
                        }
                    }
                }
                break;
            case TRAINING_SESSION_MENTOR_STATS.FAIL:
                state = {
                    ...state,
                    stats: {
                        ...state.stats,
                        mentor:{
                            ...state.stats.mentor,
                            loading: false,
                            error: action.payload.error
                        }
                    }
                }
                break;
            case TRAINING_SESSION_TOGGLE_STATS_DIALOG:
                state = {
                    ...state,
                    stats:{
                        ...state.stats,
                        showStatsDialog: action.payload
                    }
                }
                break;
            case TRAINING_SESSION_SOCKET_CONNECTION.SUCCESS:
                state = {
                    ...state,
                    error:{
                        ...state.error,
                        socket: null
                    }
                }
                break;
            case TRAINING_SESSION_SOCKET_CONNECTION.FAIL:
                state = {
                    ...state,
                    error:{
                        ...state.error,
                        socket: action.payload.error??"error.socket_connection_error"
                    },
                    socket: null
                }
                break;
            case TRAINING_SESSION_SOCKET_CONNECTION.DISCCONNECT:
                state = {
                    ...state,
                    socket: null
                }
                break;
            case TRAINING_SESSION_GAME_SETTINGS_CHANGE.SUCCESS:
                state = {
                    ...state,
                    trainingSession: {
                        ...state.trainingSession,
                        gameSettings: Object.assign(state.trainingSession.gameSettings,action.payload.data||[]),
                        changeGameSettingInProcess:false,
                        changeGameSettingError: false
                    }
                }
                break;
            case TRAINING_SESSION_GAME_SETTINGS_CHANGE.REQUEST:
                state = {
                    ...state,
                    trainingSession: {
                        ...state.trainingSession,
                        changeGameSettingInProcess:true,
                        changeGameSettingError: false
                    }
                }
                break;
            case TRAINING_SESSION_GAME_SETTINGS_CHANGE.FAIL:
                state = {
                    ...state,
                    trainingSession: {
                        ...state.trainingSession,
                        changeGameSettingInProcess:false,
                        changeGameSettingError: true
                    }
                }
                break;
            case TRAINING_SESSION_GAME_SETTINGS_CHANGE.CLOSE:
                state = {
                    ...state,
                    trainingSession: {
                        ...state.trainingSession,
                        changeGameSettingInProcess:null,
                        changeGameSettingError: false
                    }
                }
                break;
            case TRAINING_SESSION_MATCHES_IN_PLAYING.REQUEST:
                state = {
                    ...state,
                    gamesInPlaying: {
                        ...state.gamesInPlaying,
                        isLoading: true,
                        error: false
                    }
                }
                break;
            case TRAINING_SESSION_MATCHES_IN_PLAYING.SUCCESS:
                console.log(action.payload.data.items);
                state = {
                    ...state,
                    gamesInPlaying: {
                        ...state.gamesInPlaying,
                        isLoading: false,
                        items: action.payload.data.items||[],
                        error: false
                    }
                }
                break;
            case TRAINING_SESSION_MATCHES_IN_PLAYING.FAIL:
                state = {
                    ...state,
                    gamesInPlaying: {
                        ...state.gamesInPlaying,
                        isLoading: false,
                        error: action.payload.error||true
                    }
                }
                break;
            default:
                break;
        }
    }
    return state;
}