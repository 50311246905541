export const PLAYER = "player";
export const MENTOR = "mentor";
export const PLAYER_STATUS_DISCONNECTED = "disconnected";
export const PLAYER_STATUS_CONNECTED = "connected";
export const PLAYER_STATUS_PLAYING = "playing";
export const SESSION_STARTED = "started";
export const SESSION_PAUSED = "paused";
export const SESSION_STOPPED = "stopped";
export const SESSION_FROZEN = "frozen";
export const SESSION_ENDED = "ended";
export const SESSION_ARCHIVED = "archived";
export const GAME_STARTED = "started";
export const GAME_ABANDONED = "abandoned";
export const GAME_TIMEOVER = "timeover";
export const GAME_STOPPED = "stopped";
export const GAME_ENDED = "ended";
export const GAME_SETTINGS_DASHBOARD_STATS_PERSONAL = "personalStats";
export const GAME_SETTINGS_STATS_PERSONAL = "personalStats";
export const GAME_SETTINGS_TOKEN_DISPLAY_MODE = "tokenDisplayMode";
export const GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL = "spiral";
export const GAME_SETTINGS_TOKEN_DISPLAY_MODE_LINE = "line";
export const GAME_SETTINGS_STATS_OTHER_PLAYERS = "otherPlayersStats";
export const GAME_SETTINGS_DASHBOARD_STATS_SESSION = "competitionStats";
export const GAME_SETTINGS_STATS_SESSION = "competitionStats";
export const GAME_SETTINGS_DASHBOARD_STATS_AI = "aiStats";
export const GAME_SETTINGS_STATS_AI = "aiStats";
export const GAME_SETTINGS_STATS_DASHBOARD = "dashboardStats";
export const GAME_SETTINGS_SHOW_REMAINING_TOKENS = "showRemainingTokens";
export const USER_TYPE_USER = "USER";
export const USER_TYPE_ADMIN = "ADMIN";
export const USER_TYPE_SUPER_ADMIN = "SUPER_ADMIN";