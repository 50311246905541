import {useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {Doughnut} from "react-chartjs-2";
import React from "react";
import {GAME_TABLE_BG, SECONDARY_COLOR_LIGHT} from "../../../../../constants/colors";

const styles = {
    chartContent:{
        maxWidth: 800,
        margin: "auto",
        backgroundColor: "#E2BF34B3",
        borderRadius: 40,
        "& h1":{
            color: "black",
            textAlign: "center",
            padding: "0.5rem",
            lineHeight: 1,
            margin: 0,
            fontSize: 24
        }
    },
    chart:{
        height: 400,
        maxWidth: 800,
        margin: "auto",
        "& canvas":{
            paddingBottom: "1rem"
        }
    }
}
function GameStatsDonutChart(props){
    const {data,t,classes} = props
    const dataCharts = {
        labels: Object.keys(data).map((status)=>data[status].label),
        datasets: [
            {
                data: Object.keys(data).map((status)=>data[status].count),
                backgroundColor: Object.keys(data).map((status)=>data[status].bgColor),
                borderColor: Object.keys(data).map((status)=>data[status].bdColor),
                borderWidth: 1,
            },
        ]
    }
    const dataChartsOptions = {
        aspectRatio: 2,
        maintainAspectRatio:false,
        responsive:true,
        color: "black",
        legend: {
            display: true,
            position: "top",
            labels:{
                fontColor: "black",
                fontSize: 16
            }
        },
        tooltips: {
            backgroundColor: "#393939",
            bodyFontColor: "white",
            bodyFontSize: 14
        },
        // title: {
        //     display: true,
        //     text: t("label.training_session.stats_status"),
        //     fontSize: 20,
        //     position: "top",
        //     color: "black"
        // },
        plugins: {
            datalabels: {
                display: true,
                labels: {
                    value: {
                        color: "black"
                    },
                    title: {
                        color: "black"
                    }
                }
            },
        }
    }
    return <div className={classes.chartContent}>
        <h1>{t("label.training_session.stats_status")}</h1>
        <div className={classes.chart}>
            <Doughnut height={300} options={dataChartsOptions} data={dataCharts} />
        </div>
    </div>;
}

export default withStyles(styles)(withTranslation()(GameStatsDonutChart))