export const LOADING = {
    START: "LOADING_START",
    STOP: "LOADING_STOP"
};

export const SNACKBAR = {
    SHOW_SUCCESS: "SNACKBAR_SHOW_SUCCESS",
    HIDE_SUCCESS: "SNACKBAR_HIDE_SUCCESS",
    SHOW_ERROR: "SNACKBAR_SHOW_ERROR",
    HIDE_ERROR: "SNACKBAR_HIDE_ERROR"
}