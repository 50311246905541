import * as links from "./links";
import TrainingSession from "../components/trainingSession/TrainingSession";
import Login from "../components/auth/Login";

export const routes = [
    {
        path: links.TRAINING_SESSION_PLAYER,
        component: TrainingSession,
        exact: true
    },
    {
        path: links.TRAINING_SESSION_MENTOR,
        component: TrainingSession,
        exact: true
    },
    {
        path: links.LOGIN,
        component: Login,
        exact: true
    }
]