import React from "react";
import {Link, Typography, withStyles} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import * as links from "../../constants/links";
import * as colors from "../../constants/colors";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
const styles = {
    alert: {
        justifyContent: "center",
        margin: "100px auto",
        maxWidth: 800,
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        "& svg":{
            fontSize: "4rem"
        }
    },
    link: {
        "&:visited":{
            textDecoration: "none"
        },
        "color":colors.SECONDARY_COLOR,
        "&:hover":{
            "color": colors.SECONDARY_COLOR_DARK
        }
    }
}
function Error(props){

    const {classes,t} = props;
    return (
        <Alert severity={"warning"} className={classes.alert}>
            {props.code && <Typography variant="h1" style={{fontWeight: 700, marginBottom: '1rem', fontSize: '8rem'}}>{props.code}</Typography>}
            {props.title &&  <Typography variant="h2" style={{marginBottom: '1rem'}}>{props.title}</Typography>}
            {props.goToHome && <Typography variant="caption" style={{fontSize: '1rem'}}>
                <Link className={classes.link} href={"#"} onClick={(e) => {
                    e.preventDefault();
                    window.location.replace(links.HOME);
                }}>{t("label.go_to_home")}</Link>
            </Typography>}
        </Alert>
    );
}

Error.propTypes = {
    title: PropTypes.string.isRequired,
};
export default withStyles(styles)(withTranslation()(Error));
