export const TRAINING_SESSION_LOGIN = {
    REQUEST: "TRAINING_SESSION_LOGIN_REQUEST",
    SUCCESS: "TRAINING_SESSION_LOGIN_SUCCESS",
    FAIL: "TRAINING_SESSION_LOGIN_FAIL"
};

export const TRAINING_SESSION_QUIT = {
    REQUEST: "TRAINING_SESSION_QUIT_REQUEST",
    SUCCESS: "TRAINING_SESSION_QUIT_SUCCESS",
    FAIL: "TRAINING_SESSION_QUIT_FAIL"
};

export const TRAINING_SESSION_GET = {
    REQUEST: "TRAINING_SESSION_GET_REQUEST",
    SUCCESS: "TRAINING_SESSION_GET_SUCCESS",
    FAIL: "TRAINING_SESSION_GET_FAIL"
};

export const TRAINING_SESSION_GET_PLAYERS = {
    REQUEST: "TRAINING_SESSION_GET_PLAYERS_REQUEST",
    SUCCESS: "TRAINING_SESSION_GET_PLAYERS_SUCCESS",
    FAIL: "TRAINING_SESSION_GET_PLAYERS_FAIL"
};

export const TRAINING_SESSION_JOIN_GAME = {
    REQUEST: "TRAINING_SESSION_JOIN_GAME_REQUEST",
    SUCCESS: "TRAINING_SESSION_JOIN_GAME_SUCCESS",
    FAIL: "TRAINING_SESSION_JOIN_GAME_FAIL"
};

export const TRAINING_SESSION_MENTOR_STATS = {
    REQUEST: "TRAINING_SESSION_MENTOR_STATS_REQUEST",
    SUCCESS: "TRAINING_SESSION_MENTOR_STATS_SUCCESS",
    FAIL: "TRAINING_SESSION_MENTOR_STATS_FAIL"
};

export const TRAINING_SESSION_SOCKET_CONNECTION = {
    SUCCESS: "TRAINING_SESSION_SOCKET_CONNECTION_SUCCESS",
    FAIL: "TRAINING_SESSION_SOCKET_CONNECTION_FAIL",
    DISCCONNECT: "TRAINING_SESSION_SOCKET_CONNECTION_DISCCONNECT",
}

export const TRAINING_SESSION_GAME_SETTINGS_CHANGE = {
    REQUEST: "TRAINING_SESSION_GAME_SETTINGS_CHANGE_REQUEST",
    SUCCESS: "TRAINING_SESSION_GAME_SETTINGS_CHANGE_SUCCESS",
    FAIL: "TRAINING_SESSION_GAME_SETTINGS_CHANGE_FAIL",
    CLOSE: "TRAINING_SESSION_GAME_SETTINGS_CHANGE_CLOSE",
}

export const TRAINING_SESSION_MATCHES_IN_PLAYING = {
    REQUEST: "TRAINING_SESSION_MATCHES_IN_PLAYING_REQUEST",
    SUCCESS: "TRAINING_SESSION_MATCHES_IN_PLAYING_SUCCESS",
    FAIL: "TRAINING_SESSION_MATCHES_IN_PLAYING_FAIL"
}

export const TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM = "TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM";
export const TRAINING_SESSION_UPDATE_PLAYERS = "TRAINING_SESSION_UPDATE_PLAYERS";
export const TRAINING_SESSION_ADD_MESSAGE_GAME_ROOM = "TRAINING_SESSION_ADD_MESSAGE_GAME_ROOM";
export const TRAINING_SESSION_INIT_SOCKET = "TRAINING_SESSION_INIT_SOCKET";
export const TRAINING_SESSION_START_GAME = "TRAINING_SESSION_START_GAME";
export const TRAINING_SESSION_START_SEE_GAME = "TRAINING_SESSION_START_SEE_GAME";
export const TRAINING_SESSION_END_GAME = "TRAINING_SESSION_END_GAME";
export const TRAINING_SESSION_JOIN_WAITING_ROOM = "TRAINING_SESSION_JOIN_WAITING_ROOM";
export const TRAINING_SESSION_QUIT_WAITING_ROOM = "TRAINING_SESSION_QUIT_WAITING_ROOM";
export const TRAINING_SESSION_GAME_ROOM_NEXT_STEP = "TRAINING_SESSION_GAME_ROOM_NEXT_STEP";
export const TRAINING_SESSION_QUIT_GAME = "TRAINING_SESSION_QUIT_GAME";
export const TRAINING_SESSION_CHANGE_STATUS = "TRAINING_SESSION_CHANGE_STATUS";
export const TRAINING_SESSION_GOTO_NEW = "TRAINING_SESSION_GOTO_NEW";
export const TRAINING_SESSION_TOGGLE_STATS_DIALOG = "TRAINING_SESSION_TOGGLE_STATS_DIALOG";
export const TRAINING_SESSION_RESET = "TRAINING_SESSION_RESET";