import { createMuiTheme,responsiveFontSizes } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import * as colors from "../constants/colors";
import bgImg from "../images/bg.png";

export const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: colors.PRIMARY_COLOR,
            light: colors.PRIMARY_COLOR_LIGHT,
            dark: colors.PRIMARY_COLOR_DARK
        },
        secondary: {
            main: colors.SECONDARY_COLOR,
            light: colors.SECONDARY_COLOR_LIGHT,
            dark: colors.SECONDARY_COLOR_DARK
        },
        text:{
            primary: colors.TEXT_PRIMARY_COLOR,
            secondary: colors.TEXT_SECONDARY_COLOR,
            disabled: colors.TEXT_DISABLED_COLOR
        },
        background:{
            paper: colors.PRIMARY_COLOR_DARK,
            default: colors.PRIMARY_COLOR_LIGHT,
        },
        borderColor: "black"
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    shape: {
        borderRadius: 6
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    WebkitFontSmoothing: 'auto',
                    height: "100%"
                },
                body:{
                    height: "calc(100% - 64px)",
                    overflow: "auto !important",
                    paddingRight: "0 !important",
                    "& #root":{
                        height: "100%",
                        "& #bg-image": {
                            backgroundImage: `url(${bgImg})`,
                            backgroundSize: "100% auto",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "#545454",
                            position: "absolute",
                            opacity: "0.5",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: "100%",
                            zIndex: "-1"
                        }
                    },
                    backgroundColor:colors.TEXT_PRIMARY_COLOR
                },
            },
        },
        MuiInputBase:{
            input:{
                color: "white"
            }
        },
        MuiFormLabel: {
            root: {
                "&.Mui-focused":{
                    color: colors.TEXT_SECONDARY_COLOR
                }
            },
        },
        MuiOutlinedInput:{
            root: {
                "&.Mui-focused $notchedOutline,&:hover $notchedOutline":{
                    borderColor: colors.SECONDARY_COLOR_DARK
                }
            },
            input: {
                "&:autofill":{
                    boxShadow: "none",
                    textFillColor: "#545454",
                    caretColor: "#545454",
                }
            }
        },
        MuiLink:{
            "& $underlineHover":{
                textDecoration: "none"
            }
        },
        MuiPaper:{
            root: {
                color: "white"
            }
        }
    },
}));