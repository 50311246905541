import * as React from "react";
import { Create,FunctionField, Edit,List, SimpleForm, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton,useTranslate,Toolbar,SaveButton } from 'react-admin';
import {Container, makeStyles} from "@material-ui/core";

const styles = {
    toolbar: {
        backgroundColor: "transparent",
        justifyContent: "center"
    }
};

const useStyles = makeStyles(styles);

export function ParameterList(props){
    console.log(props);
    const translate = useTranslate();
    return <List bulkActionButtons={false} {...props}>
        <Datagrid>
            <FunctionField label={translate("resources.parameters.fields.description")} render={(row)=>translate("resources.parameters.label.label_"+row.code)} />
            <TextField source="value" />
            <EditButton/>
        </Datagrid>
    </List>
}

const EditToolBar = props => {
    const classes = useStyles();
    return <Toolbar {...props} className={classes.toolbar} >
        <SaveButton />
    </Toolbar>
};

export function ParameterEdit(props){
    return <Edit {...props}>
        <SimpleForm toolbar={<EditToolBar/>}>
            <ParameterForm/>
        </SimpleForm>
    </Edit>
}

export function ParameterForm(props){
    const translate = useTranslate();
    return <Container maxWidth={"md"}>
        <TextInput source={""} fullWidth options={{
            rows:3,
            multiline: true
        }} label={translate("resources.parameters.fields.description")} format={(row)=>translate("resources.parameters.label.label_"+row.code)} disabled />
        <TextInput required={true} fullWidth source="value" label={translate("resources.parameters.fields.value")} />
    </Container>;
}