import React from "react";
import {CardContent, Card, withStyles} from "@material-ui/core";
import {Title} from 'react-admin';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

const styles={

}

function Dashboard(props){
    const {t,classes} = props;
    return <Card>
        <Title title={t("backoffice.title.dashboard_admin")} />
        <CardContent>{t("backoffice.title.dashboard_admin_description")}</CardContent>
    </Card>;
}

export default withRouter(withStyles(styles)(withTranslation()(Dashboard)));
