import * as React from 'react';
import { AppBar } from 'react-admin';
import {Box, makeStyles, Typography, withStyles} from '@material-ui/core';
import * as colors from "../../../constants/colors";
const styles = {
    appBar: {
        backgroundColor: "white",
        color: colors.PRIMARY_COLOR
    }
};

const useStyles = makeStyles(styles);

function AdminBar(props){
    const classes = useStyles();
    return <AppBar {...props} position={"absolute"} userMenu={false} className={classes.appBar}>
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title"></Typography>
        </Box>
    </AppBar>
}

export default AdminBar;