import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {
    withStyles,
    Grid,
    Paper,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogContentText, DialogActions, Button, Typography, makeStyles, Tooltip
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import ScoreBoard from "./player/ScoreBoard";
import {
    GAME_SETTINGS_DASHBOARD_STATS_AI,
    GAME_SETTINGS_DASHBOARD_STATS_PERSONAL, GAME_SETTINGS_DASHBOARD_STATS_SESSION, GAME_SETTINGS_STATS_DASHBOARD,
    MENTOR,
    PLAYER,
    PLAYER_STATUS_CONNECTED,
    PLAYER_STATUS_PLAYING, SESSION_ARCHIVED, SESSION_ENDED
} from "../../../constants/params";
import WaitingRoom from "./player/WaitingRoom";
import GameRoom from "./player/GameRoom";
import WaitingRoomChatBox from "./player/WaitingRoomChatBox";
import * as colors from "../../../constants/colors";
import ControlRoom from "./mentor/ControlRoom";
import StatsTable from "./stats/StatsTable";
import {Transition} from "../../../themes/effects";
import {CloseRounded, ExpandMoreRounded, GamepadRounded} from "@material-ui/icons";
import PercentageTable from "./stats/PercentageTable";
import PercentageChart from "./stats/charts/PercentageChart";
import _ from "lodash";
import clsx from "clsx";
import {ReactComponent as PodiumIcon} from "../../../images/podium.svg";
import Podium from "./stats/Podium";
import Timer from "react-compound-timer";
import {quit} from "../../../sagas/trainingSession/actions";
const styles = (theme)=>({
    container: {
        minHeight: 800,
        background: "transparent",
        color: "white",
        height: "70%",
        marginTop: "2rem",
        width: "calc(100% - 20px)",
        margin: "auto"
    },
    main:{
        width: "calc(100% - 400px)",
        minWidth: 270,
        //marginLeft: 20,
        background: "#219887d9",//"#A6927C",
        minHeight: "70%",
        maxWidth: 1280,
        borderRadius: 6,
        marginLeft: "auto",
        marginRight: "auto",
        padding: "3.5rem 2rem",
        position: "relative",
        [theme.breakpoints.down("sm")]:{
            width: "100%",
            marginBottom: "1rem"
        },
        flexBasis: "calc(66.666667% - 20px)",
        "&.noHeader":{
            paddingTop: 5
        }
    },
    content: {
        position: "relative",
        [theme.breakpoints.down("sm")]:{
            flexDirection: "column-reverse"
        }
    },
    rulesBtn:{
        position: "absolute",
        top: 5,
        right:5,
        [theme.breakpoints.down("xs")]:{
            "& .btnLabel":{
                display: "none"
            },
            "& span":{
                marginRight: 0
            }
        }
    },
    gameRulesContent:{
        textAlign: "justify",
        fontSize: "1.1rem",
        whiteSpace: "pre-line"
    },
    popupTitle:{
        fontWeight: "bold"
    },
    percentageChart:{
        maxWidth: "80%",
        minWidth: "unset",
        margin: "0 auto",
        maxHeight: 250
    },
    percentageTable:{
        background: "transparent",
        boxShadow: "none",
        margin: "auto"
    },
    statsBlock:{
        maxHeight: 460,
        overflow: "auto",
        background: "#d5d5d5"
    },
    statsTitle:{
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "1.2rem",
        color: "black",
        width: "100%",
        margin: "0 auto",
        lineHeight: 1
   },
    statsSummary:{
        "& svg":{
            color: "black"
        },
        minHeight: "unset",
        "&.Mui-expanded":{
            minHeight: "unset"
        },
        height: "2.5rem"
    },
    statsSummaryExpandIcon:{
        padding: 0
    },
    statsSummaryContent:{
        margin: "0",
        "&.Mui-expanded":{
            margin: "0",
        },
    },
    statsDetails:{
        padding: 0
    },
    leftCol:{
        [theme.breakpoints.up("lg")]:{
            maxWidth: 400

        },
        [theme.breakpoints.between(650,"sm")]:{
            flexDirection: "row",
            display: "flex",
            alignItems: "stretch",
            marginBottom: "1rem"

        },
        [theme.breakpoints.down(600)]: {
            width: "100%",
            flexDirection: "column"
        }
    },
    podiumBtn:{
        position: "absolute",
        top:0,
        width: 120,
        height: 120,
        left: "calc(50% - 3rem)",
        "&>span":{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            textTransform:"initial",
            fontWeight: "bold",
            fontSize: "1.3rem"
        },
        "& .MuiButton-startIcon":{
            marginRight:0
        }
    },
    waitingRoomContent:{
        paddingTop: 110
    },
    podiumDialogContent:{
        width: 600,
        [theme.breakpoints.down("xs")]:{
            width: 400
        }
    }
});

const useStyles = makeStyles(styles);

function LogoutDialog(props){
    const {t,trainingSessionId} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open,setOpen] = useState(true);
    return <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
    >
        <DialogTitle className={classes.popupTitle}>{t("title.training_session.end_session")}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Timer
                    lastUnit={"s"}
                    initialTime={10000}
                    direction="backward"
                    checkpoints={[
                        {
                            time: 0,
                            callback: ()=>{
                                setOpen(false);
                                dispatch(quit({
                                    trainingSessionId: trainingSessionId
                                }));
                            }
                        }
                    ]}
                >
                    {({getTime}) => (
                        <React.Fragment>
                            {t("text.training_session.logout_after_seconds",{count: Math.round(getTime()/1000)})}
                        </React.Fragment>
                    )}
                </Timer>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} onClick={(e)=>{
                setOpen(false);
                dispatch(quit({
                    trainingSessionId: trainingSessionId
                }));
            }} type={"button"} color="default">
                <CloseRounded fontSize={"large"}/> {t("label.training_session.logout")}
            </Button>
        </DialogActions>
    </Dialog>;
}

export function PodiumBtn(props){
    const classes = useStyles();
    const trans = useTranslation("messages");
    const {setShowPodium} = props;
    return <Tooltip title={trans.t("label.training_session.podium")}><Button onClick={(e)=>{
        setShowPodium(true);
    }} className={classes.podiumBtn} startIcon={<PodiumIcon width={100} height={100}/>}>
        {/*<Typography component={"span"}>{t("label.training_session.podium")}</Typography>*/}
    </Button></Tooltip>;
}

export function RulesBtn(props){
    const classes = useStyles();
    const {t,setShowGameRules,hideBtn,className} = props;
    return !hideBtn?<Button onClick={(e)=>{
        setShowGameRules(true);
    }} color={"primary"} variant={"contained"} startIcon={<GamepadRounded/>} className={clsx(classes.rulesBtn,{[className]:!!className})}><span className={"btnLabel"}>{t("label.training_session.game_rules")}</span></Button>:"";
}

function StatsAccordion(props){
    const {summary,details,isExpanded,setPlayerStatsExpanded} = props;
    const classes = useStyles();
    return <Accordion expanded={isExpanded} onChange={(e,expanded)=>setPlayerStatsExpanded(expanded)} className={classes.statsBlock}>
        <AccordionSummary classes={{content:classes.statsSummaryContent,expandIcon:classes.statsSummaryExpandIcon}} className={classes.statsSummary} expandIcon={<ExpandMoreRounded className={classes.statsSummaryExpandIcon} />}>
            {summary}
        </AccordionSummary>
        <AccordionDetails className={classes.statsDetails}>
            <Grid container>
                {details}
            </Grid>
        </AccordionDetails>
    </Accordion>;
}

function Dashboard(props){
    const {classes,t} = props;
    const {user,gameRoom,stats,trainingSession,players} = useSelector((state)=>state.trainingSessionState);
    const {gameSettings} = trainingSession;
    const dashboardStats = gameSettings[GAME_SETTINGS_STATS_DASHBOARD]??"";
    const [showGameRules,setShowGameRules] = useState(false);
    const [playerStatsExpanded,setPlayerStatsExpanded] = useState(true);
    const [showPodium,setShowPodium] = useState(false);
    const [showDialogLogout,setShowDialogLogout] = useState(false);
    useEffect(()=>{
        setPlayerStatsExpanded(true);
    },[dashboardStats]);
    const sessionStatus = trainingSession.status;

    const getPlayerStats = ()=>{
        if (user.type === PLAYER || gameRoom.game){
            const percentageOptions = {
                legend: {
                    display:false
                },
                aspectRatio: 3,
                tooltips: {
                    callbacks: {
                        label: (item) => `${item.yLabel}%`,
                    },
                },
                scales:{
                    xAxes: [{
                        ticks: {
                            min: 1,
                            stepSize:1
                        },
                        scaleLabel:{
                            display:true,
                            labelString: t("label.training_session.enemy_remaining_tokens")
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function(label, index, labels) {
                                const value = parseInt(label);
                                return value%20===0?label:"";
                            },
                            min: 0,
                            stepSize:10,
                            max: 100
                        },
                        scaleLabel:{
                            display:true,
                            labelString: t("label.training_session.victory_percentage") + " (%)"
                        }
                    }]
                }
            };
            switch (dashboardStats){
                case GAME_SETTINGS_DASHBOARD_STATS_PERSONAL:
                    const playerPercentages = {};
                    const playerStats = {
                        winnerStats: user.otherInfos.winner_step_stats||{},
                        loserStats: user.otherInfos.loser_step_stats||{},
                    };
                    for (const value of _.range(1,trainingSession.tokensPerGame+1)) {
                        playerPercentages[value] = {value: null, totalMatches: 0};
                        if (Object.keys(playerStats)){
                            const totalPlayerWinner = playerStats.winnerStats && playerStats.winnerStats[value]?playerStats.winnerStats[value]:0;
                            const totalPlayerLoser = playerStats.loserStats && playerStats.loserStats[value]?playerStats.loserStats[value]:0;
                            if (totalPlayerLoser || totalPlayerWinner){
                                const totalMatches = totalPlayerWinner+totalPlayerLoser;
                                playerPercentages[value] = {value:Math.round(totalPlayerWinner*100/totalMatches),totalMatches:totalMatches};
                            }
                        }
                    }
                    return <StatsAccordion
                        setPlayerStatsExpanded={setPlayerStatsExpanded}
                        isExpanded={playerStatsExpanded}
                        summary={<Typography component={"div"} className={classes.statsTitle}>{t("label.training_session.stats_dialog.personal_percentage_victory_per_step")}</Typography>}
                        details={<React.Fragment>
                            {/*<PercentageTable containerClass={classes.percentageTable} hasTotalMatches={true} data={playerPercentages}/>*/}
                            <PercentageChart containerProps={{
                                style: {width: "80vw"}
                            }} height={80} className={classes.percentageChart}  options={percentageOptions} data={Object.keys(playerPercentages).map((token)=>{
                                return {
                                    label: token,
                                    value: playerPercentages[token].value
                                }
                            })}/>
                        </React.Fragment>}
                    />;
                case GAME_SETTINGS_DASHBOARD_STATS_SESSION:
                    const playersStats = {};
                    const sessionPercentages = {};
                    const playerItems = players.items||[];
                    playerItems.forEach((playerItem)=>{
                        playersStats[playerItem.id]={
                            winnerStats: playerItem.otherInfos.winner_step_stats||{},
                            loserStats: playerItem.otherInfos.loser_step_stats||{},
                        };
                    });
                    for (const value of _.range(1,trainingSession.tokensPerGame+1)) {
                        sessionPercentages[value] = {value:null,totalMatches:0};
                        let totalWinner = 0;
                        let totalLoser = 0;
                        Object.keys(playersStats).forEach((playerId)=>{
                            const pStats = playersStats[playerId];
                            const totalPlayerWinner = pStats.winnerStats && pStats.winnerStats[value]?pStats.winnerStats[value]:0;
                            const totalPlayerLoser = pStats.loserStats && pStats.loserStats[value]?pStats.loserStats[value]:0;
                            totalWinner+=totalPlayerWinner;
                            totalLoser+=totalPlayerLoser;
                        });
                        if (totalWinner || totalLoser){
                            const totalMatches = totalLoser+totalWinner;
                            sessionPercentages[value] = {value:Math.round(totalWinner*100/(totalMatches)), totalMatches: totalMatches};
                        }
                    }
                    return <StatsAccordion
                        isExpanded={playerStatsExpanded}
                        setPlayerStatsExpanded={setPlayerStatsExpanded}
                        summary={<Typography component={"div"} className={classes.statsTitle}>{t("label.training_session.stats_dialog.session_percentage_victory_per_step")}</Typography>}
                        details={<React.Fragment>
                            {/*<PercentageTable hasTotalMatches={true} data={sessionPercentages}/>*/}
                            <PercentageChart containerProps={{
                                style: {width: "80vw"}
                            }} height={80}  className={classes.percentageChart} options={percentageOptions} data={Object.keys(sessionPercentages).map((token)=>{
                                return {
                                    label: token,
                                    value: sessionPercentages[token].value
                                }
                            })}/>
                        </React.Fragment>}
                    />;
                case GAME_SETTINGS_DASHBOARD_STATS_AI:
                    const aiPercentages = {};
                    for (const value of _.range(1,trainingSession.tokensPerGame+1)) {
                        aiPercentages[value] = {value: null};
                    }
                    aiPercentages["3"] = {value:100};
                    aiPercentages["6"] = {value:100};
                    aiPercentages["9"] = {value:100};
                    aiPercentages["12"] = {value:100};
                    aiPercentages["15"] = {value:100};
                    aiPercentages["18"] = {value:100};
                    return <StatsAccordion
                        isExpanded={playerStatsExpanded}
                        setPlayerStatsExpanded={setPlayerStatsExpanded}
                        summary={<Typography component={"div"} className={classes.statsTitle}>{t("label.training_session.stats_dialog.ai_percentage_victory_per_step")}</Typography>}
                        details={<React.Fragment>
                            {/*<PercentageTable hasTotalMatches={false} data={aiPercentages}/>*/}
                            <PercentageChart containerProps={{
                                style: {width: "80vw"}
                            }} height={80} className={classes.percentageChart}  options={percentageOptions} data={Object.keys(aiPercentages).map((token)=>{
                                return {
                                    label: token,
                                    value: aiPercentages[token].value
                                }
                            })}/>
                        </React.Fragment>}
                    />;
                default:
                    break;
            }
        }
        return "";
    }

    useEffect(()=>{
        if (sessionStatus===SESSION_ENDED && !showDialogLogout && !!trainingSession.statusChanged){
            setShowDialogLogout(true);
        }
    },[sessionStatus]);
    return <div className={classes.container}>
        <Grid container className={classes.content} direction={"row"} alignItems={"stretch"}>
            {!gameRoom.game && <Grid item xs={12} sm={12} md={4} className={classes.leftCol} direction={"column"}>
                <Grid xs={12} sm={12} item>
                    <ScoreBoard/>
                </Grid>
                {/*<Grid xs={12} sm={12} item>*/}
                {/*    <WaitingRoomChatBox/>*/}
                {/*</Grid>*/}
            </Grid>}
            <Grid item xs={12} sm={12} md={8} className={clsx(classes.main,{"noHeader":gameRoom.game,[classes.waitingRoomContent]:!gameRoom.game && user.type === PLAYER})}>
                {getPlayerStats()}
                {gameRoom.game?<GameRoom setShowGameRules={setShowGameRules}/>:(user.type === PLAYER?<WaitingRoom/>:<ControlRoom/>)}
                <RulesBtn t={t} setShowGameRules={setShowGameRules} hideBtn={!!gameRoom.game}/>
                {!gameRoom.game && players && players.items && players.items.length>0 && <PodiumBtn setShowPodium={setShowPodium}/>}
            </Grid>
            <Dialog
                open={showGameRules}
                TransitionComponent={Transition}
                keepMounted
                onClose={(e)=>{
                    setShowGameRules(false);
                }}
            >
                <DialogTitle className={classes.popupTitle}>{t("title.training_session.game_rules")}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.gameRulesContent}>
                        {t("text.training_session.game_rules")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button variant={"contained"} onClick={(e)=>{
                    setShowGameRules(false);
                }} type={"button"} color="secondary">
                    <CloseRounded fontSize={"large"}/> {t("label.close")}
                </Button>
                </DialogActions>
            </Dialog>
        </Grid>
        {showPodium && <Dialog maxWidth={"md"} open={true} onClose={()=>setShowPodium(false)} TransitionComponent={Transition} keepMounted>
            <DialogTitle>
                {t([SESSION_ENDED,SESSION_ARCHIVED].includes(sessionStatus)?"label.training_session.podium":"label.training_session.temporary_podium")}
            </DialogTitle>
            <DialogContent className={classes.podiumDialogContent}>
                <Podium/>
            </DialogContent>
        </Dialog>}
        {showDialogLogout && <LogoutDialog trainingSessionId={trainingSession.id} t={t}/>}
    </div>;
}

export default withStyles(styles)(withTranslation()(Dashboard));