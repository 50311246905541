import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    withStyles,
    Grid,
    Paper,
    Container,
    Button,
    Typography,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Dialog, makeStyles
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    PauseOutlined, PauseRounded,
    PlayArrowOutlined, PlayArrowRounded, StopRounded, HourglassEmptyRounded, CloseRounded
} from "@material-ui/icons";
import {gotoNewSession, joinGame, quit} from "../../../../sagas/trainingSession/actions";
import {LinearIndeterminate} from "../../../loading/loaders";
import {
    SESSION_ARCHIVED,
    SESSION_ENDED,
    SESSION_PAUSED,
    SESSION_STARTED,
    SESSION_STOPPED
} from "../../../../constants/params";
import Podium from "../stats/Podium";
import {Transition} from "../../../../themes/effects";
import {logout} from "../../../../sagas/trainingSession/apis";
import Timer from "react-compound-timer";
const styles = {
    container: {
        //padding: "1rem 2rem"
    },
    joinGameBtn:{
        width: 300,
        padding: "30px",
        marginTop: 50
    },
    pauseGameBtn:{
        width: 300,
        padding: "30px",
        marginTop: 50,
        background: "green",
        color: "white"
    },
    joinWaitingRoomBtn:{
        width: 300,
        padding: "30px",
        marginTop: 50
    },
    title:{
        fontSize: "1.2rem",
        fontWeight: "bold",
        justifyContent: "justify"
    },
    loadingProgress:{
        marginTop: "1.5rem"
    },
    currentStatusTitle:{
        color: "#353535",
        minWidth: 300,
        maxWidth: "600px",
        margin: "2rem auto",
        padding: "1rem",
        backgroundColor: "#49a9e2",
        "&.notStarted":{
            backgroundColor: "#a0a0a0"
        },
        "&.started":{
            backgroundColor: "#257e30"
        },
        "&.ended":{
            backgroundColor: "#dd3636"
        },
        "&.paused":{
            backgroundColor: "#6d6a6a"
        },
        "&.frozen":{
            backgroundColor: "#949292"
        },
        "&.stopped":{
            backgroundColor: "#dd7036"
        },
        "& svg":{
            borderRadius: "50%",
            backgroundColor: "white",
            fontSize: "4rem",
            color: "black"
        }
    },
    statusTitle:{
        color: "white",
        padding: "1rem 2rem",
        fontWeight: "bold",
        textAlign: "center"
    },
    searchRoomIndicator:{
        marginTop: "2rem",
        "& p":{

        }
    },
    statusIcon:{
        textAlign: "center",
    }
};

const useStyles = makeStyles(styles);

function WaitingRoom(props){
    const {t,classes} = props;
    const dispatch = useDispatch();
    const {user,trainingSession,socket,waitingRoom} = useSelector((state)=>state.trainingSessionState);
    const sessionStatus = trainingSession.status;
    const {inSearching,requestRoomInterval,played} = waitingRoom;
    const joinGameRoom = (e)=>{
        socket.sendMessage("joinGame",{});
    }
    const goToWaitingRoom = (e)=>{
        socket.sendMessage("joinWaitingRoom",{});
    }
    const quitWaitingRoom = (e)=>{
        if (requestRoomInterval){
            clearInterval(requestRoomInterval);
        }
        socket.sendMessage("quitWaitingRoom",{});
    }
    return <Grid className={classes.container} container justify={"center"} alignItems={"center"} direction={"column"}>
        {/*<Grid className={classes.title} item>{t("text.game_description")}</Grid>*/}
        {/*<Grid item><Button variant={"contained"} color={"secondary"} onClick={joinGameRoom} className={classes.joinGameBtn}><PlayArrowOutlined fontSize={"large"}/> {t("label.training_session.join_game")}</Button></Grid>*/}
        {SESSION_STARTED!==sessionStatus && <Grid container direction={"column"} className={classes.currentStatusTitle+" "+(sessionStatus||"notStarted")}>
            {sessionStatus === SESSION_PAUSED && <Grid item className={classes.statusIcon}><PauseRounded/></Grid>}
            {sessionStatus === SESSION_STOPPED && <Grid item className={classes.statusIcon}><StopRounded/></Grid>}
            {sessionStatus === null && <Grid item className={classes.statusIcon}><HourglassEmptyRounded/></Grid>}
            <Grid item className={classes.statusTitle}>{t("label.training_session."+(sessionStatus||"not_started")+"_by_mentor")}</Grid>
        </Grid>}
        {/*{[SESSION_ENDED,SESSION_ARCHIVED].includes(sessionStatus) && <Grid item className={classes.podium}><Podium/></Grid>}*/}
        {sessionStatus === SESSION_STARTED && <Grid item><Button variant={"contained"} color={"secondary"} onClick={inSearching?quitWaitingRoom:goToWaitingRoom} className={inSearching?classes.pauseGameBtn:classes.joinWaitingRoomBtn}>{inSearching?<PauseOutlined fontSize={"large"}/>:<PlayArrowRounded fontSize={"large"}/>} {t(inSearching?"label.training_session.pause":(played?"label.training_session.ready_to_replay":"label.training_session.ready_to_play"))}</Button></Grid>}
        {inSearching && <Grid item container direction={"column"} justify={"center"} className={classes.searchRoomIndicator}>
            <Typography align={"center"}>{t("label.training_session.search_room_in_process")}</Typography>
            <LinearIndeterminate color={"secondary"} className={classes.loadingProgress}/>
        </Grid>}
    </Grid>;
}

export default withStyles(styles)(withTranslation()(WaitingRoom));