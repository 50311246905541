export const PRIMARY_COLOR_ADMIN_DARK = "#c8c8c8";
export const PRIMARY_COLOR_ADMIN = "#e0e0e0";
export const PRIMARY_COLOR_ADMIN_LIGHT = "#ffffff";

export const PRIMARY_COLOR_DARK = "#036d9b";
export const PRIMARY_COLOR = "#029cde";
export const PRIMARY_COLOR_LIGHT = "#4dbfef";
export const SECONDARY_COLOR_DARK = "#b19804";
export const SECONDARY_COLOR = "#efcd07";
export const SECONDARY_COLOR_LIGHT = "#f5db49";
export const TEXT_PRIMARY_COLOR = "#545454";
export const TEXT_SECONDARY_COLOR = "#ffffff";
export const TEXT_DISABLED_COLOR = "#ababab";
export const THIRD_COLOR_DARK = "#2d0854";
export const DARK_COLOR = "#242424";
export const THIRD_COLOR = "#392054";
export const THIRD_COLOR_LIGHT = "#4f3d62";
//export const GAME_TABLE_BG = "#6A4B2D";
export const GAME_TABLE_BG = "#8066a6";//"#5c5c5c";
export const GAME_TABLE_TOKEN = "#d68631";//"#5c5c5c";