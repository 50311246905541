import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    withStyles,
    Grid,
    Paper,
    Container,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Tooltip,
    Button,
    Slide,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    Popover,
    makeStyles, useMediaQuery, useTheme
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as colors from "../../../../constants/colors";
import {quitGame} from "../../../../sagas/trainingSession/actions";
import Timer from "react-compound-timer";
import clsx from "clsx";
import logoPlaisirMaths from "../../../../images/logo.png";
import {
    CloseRounded,
    ExitToAppRounded,
    TimerRounded,
    UpdateRounded
} from "@material-ui/icons";
import moment from "moment";
import {TRAINING_SESSION_JOIN_WAITING_ROOM} from "../../../../sagas/trainingSession/constants";
import Phaser from 'phaser';
import { IonPhaser, GameInstance } from '@ion-phaser/react'
import {Transition} from "../../../../themes/effects";
import {
    GAME_ABANDONED,
    GAME_SETTINGS_TOKEN_DISPLAY_MODE, GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL,
    GAME_STOPPED,
    GAME_TIMEOVER
} from "../../../../constants/params";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import * as _ from "lodash";
import {RulesBtn} from "../Dashboard";
import {GAME_TABLE_BG} from "../../../../constants/colors";
const styles = (theme)=>({
    container:{
        marginTop: "1rem",
        "& h2":{
            marginLeft: "2rem"
        }
    },
    main: {
        minHeight: 800,
        background: "transparent",
        color: "white",
        maxWidth: 1280
    },
    gameTable:{
        width: "100%",
        maxWidth: 480,
        minHeight: 240,
        borderRadius: "80px",
        border: "8px solid rgba(107, 43, 43, 0.54)",
        margin: "2rem auto",
        textAlign:"center",
        "& table":{
            tableLayout: "fixed",
            marginTop: "2rem"
        },
        background: GAME_TABLE_BG
    },
    tokenItem:{
        width: "2.5rem",
        height: "2.5rem",
        border: "1px solid white",
        borderRadius: "50%",
        background: "#cff56c",
        color: "black",
        fontWeight: "bold",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center"
    },
    player:{
        width: "10rem",
        backgroundColor: "#a4a4a4",
        justifyContent: "flex-start",
        textAlign: "center",
        margin: "0 10px",
        color: "black",
        height: "10rem",
        padding: "5px",
        display: "inline-flex",
        position: "relative",
        "&.active":{
            transition: "background-color 1.5s ease",
            backgroundColor: colors.PRIMARY_COLOR,
        }
    },
    player2:{
        [theme.breakpoints.down("xs")]:{
            marginTop: 5
        }
    },
    // player1:{
    //     display: "inline-flex",
    //     backgroundColor: "#878686",
    //     color:"white"
    // },
    nbTokens:{
        width: "3rem",
        height: "2rem",
        "& input":{
            padding: 5
        }
    },
    nickname:{
        display: "inline-flex",
        alignItems: "center",
        color: "#353535",
        fontWeight: "bold",
        "&.active":{
            color: "#CFF56C"
        }
    },
    validBtn:{
        padding: 0
    },
    actions:{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center"
    },
    popupTitle:{
        color: "white",
        fontWeight: "bold"
    },
    myToken:{
        backgroundColor: "#575454",
        visibility: "hidden",
        opacity: 0,
        transition: "opacity 3s ease-in-out"
    },
    player2Token:{
        backgroundColor: "white",
        visibility: "hidden",
        opacity: 0,
        transition: "opacity 3s ease-in-out"
    },
    myStep: {
        backgroundColor: "#575454",
        color: "white"
    },
    player2Step:{
        backgroundColor: "white",
        color: "#575454"
    },
    history:{
        marginTop: "1rem",
        listStyle: "none",
        maxWidth: "400px",
        margin: "auto",
        "& li":{
            padding: "10px"
        }
    },
    clickable:{
        backgroundColor:"#ade50f",
        "&:hover":{
            cursor: "pointer",
            backgroundColor:"#709403"
        }
    },
    selectedToken:{
        backgroundColor:"#709403"
    },
    inlineFlex:{
        display: "inline-flex"
    },
    takeTokensBtn:{
        marginTop: ".5rem",
        fontSize: ".7rem",
        padding: ".75rem .5rem",
        lineHeight: "1rem",
        color: "rgba(0, 0, 0, 0.87)",
        "&:not(.Mui-disabled) .MuiButton-label":{
            color: "rgba(0, 0, 0, 0.87)"
        }
    },
    timer:{
        marginTop: "1rem",
        color: colors.SECONDARY_COLOR_DARK,
        display: "inline-flex",
        alignItems: "center",
        position: "absolute",
        bottom: "5px"
    },
    abandonedBtn:{
        background: "#ee5252",
        color: "white",
        "&:hover":{
            background: "#f33131",
        },
        width: 300,
        margin: "auto",
        marginTop: "2rem"
    },
    gameTitle:{
        position: "relative"
    },
    gameTableTest:{
        height: 480,
        width: 800,
        margin: "auto"
    },
    activeTimer:{
        fontWeight: "bold",
    },
    inactiveTimer:{
        color: "#606060 !important"
    },
    timerCountdown:{
        width: 110,
        textAlign: "center",
        fontWeight: "bold"
    },
    timerMessage:{
        color: "#302f60",
        fontWeight: "bold",
        // "&.player1Turn":{
        //     color: "#575454"
        // },
        position: "absolute",
        top: 75,
        width: 120
    },
    nickNameIndicator:{

    },
    playerTimer:{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "5px"
    },
    turnIndicator:{

    },
    playerTimers:{
        position: "absolute",
        top: 15
    },
    playerRank:{
        //fontWeight: "bold",
    },
    gameMessage:{
        color: "#CFF56C",
        fontWeight: "bold",
        fontSize: "1.1rem",
        marginTop: ".25rem"
    },
    gameContainer:{
        marginTop: "5px",
        width: "100%"
    },
    gameInfos:{
        width: 250,
        textAlign: "center"
    },
    timerBlock:{
        marginTop: 8
    },
    scores:{
        position: "relative",
        textAlign: "center",
        [theme.breakpoints.down("xs")]:{
            flexDirection:"column",
            justifyContent: "center",
            margin: "auto",
        }
    },
    rulesBtn:{
        position: "initial !important",
        background: "rgb(69,69,69)",
        minWidth: "2rem",
        "& .btnLabel":{
            //display: "none"
        },
        "& span":{
            //marginRight: 0
        },
        [theme.breakpoints.down("xs")]:{
            position: "absolute",
            right: 0
        }
    },
    playerScoreBlock:{
        display: "inline-flex",
        alignItems: "flex-start",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]:{
            display: "block",
            margin: "auto"
        }
    },
    dialog:{
        opacity: .8
    }
});

class MainScene extends Phaser.Scene {
    init (data) {
        this.cameras.main.setBackgroundColor('#5d5d5d')
    }

    preload(){

    }

    create () {
        this.plaisirMathsText = this.add.text(
            this.cameras.main.centerX,
            this.cameras.main.centerY,
            "Plairsir Maths"
        );
        this.plaisirMathsText.setOrigin(0.5);
    }

    update (time,data) {
        this.plaisirMathsText.angle += 1;
    }
}

function TimerCountdown(props){
    const {winner,duration,initialRemainingTime,otherRemainingTime,isPlayer1Turn,classes,t,checkTimeUp,player2,userInPlaying,player1,forPlayer,stop,setStop} = props;
    return <div className={classes.timerBlock}>
        <CountdownCircleTimer
            isPlaying={initialRemainingTime>0 && !(winner!==null && winner) && !stop && ((isPlayer1Turn && forPlayer===player1) || (!isPlayer1Turn && forPlayer===player2))}
            duration={Math.max(duration,0)}
            initialRemainingTime={Math.max(initialRemainingTime,0)}
            size={100}
            trailColor={"#6b6b73"}
            colors={[["#CFF56C",0.89], ["#f1a214", 0.11], ["#A30000"]]}
            onComplete={() => {
                setStop(true);
                if (userInPlaying && isPlayer1Turn){
                    checkTimeUp();
                }
                return [false, 0];
            }}
        >
            {({ remainingTime }) => {
                const realRemainingTime = remainingTime>=0?remainingTime:0;
                return <Grid container className={classes.timerCountdown} direction={"column"} alignItems={"center"}>
                    <Grid className={clsx(classes.playerTimer,{[classes.activeTimer]:((isPlayer1Turn && forPlayer===player1) || (!isPlayer1Turn && forPlayer===player2))})}>
                        {((isPlayer1Turn && forPlayer===player1) || (!isPlayer1Turn && forPlayer===player2))?<UpdateRounded/>:<TimerRounded/>} {Math.ceil(Math.max((((isPlayer1Turn && forPlayer===player1) || (!isPlayer1Turn && forPlayer===player2))?realRemainingTime:initialRemainingTime),0))}
                    </Grid>
                </Grid>;
            }}
        </CountdownCircleTimer>
    </div>;
}

function GameRoom(props){
    const {t,classes,setShowGameRules} = props;
    const trainingSessionState = useSelector((state)=>state.trainingSessionState);
    const {user,gameRoom,socket,trainingSession} = trainingSessionState;
    const allPlayers = trainingSessionState.players;
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const playerRows = _.orderBy(allPlayers.items.map((player)=>{
        const {scores,nickname,id,status} = player;
        return {
            ...scores,
            nickname,
            id,
            status
        };
    }),["points","wins","matches","nickname","loses"],["desc","desc","desc","asc","asc"]);
    const {game,messages,players,steps,winner,status,nbPointsWinner,nbPointsLoser,nbPointsDraw,gameRanking} = gameRoom;
    const {maxTime,tokens,maxTokenPerStep,startAt} = game;
    const {gameSettings} = trainingSession;
    const nbTokens = Array.from((new Array(tokens)).keys()).map((item)=>item+1);
    const player1Id = user.id===players[1].playerId?players[1].playerId:players[0].playerId;
    const player2Id = user.id===players[1].playerId?players[0].playerId:players[1].playerId;
    const userInPlaying = user.id === player1Id;
    const player1Index = playerRows.findIndex((player)=>player.id===player1Id);
    const player1Rank = player1Index+1;
    const player1 = players.find((item)=>item.playerId===player1Id);
    const player2Index = playerRows.findIndex((player)=>player.id===player2Id);
    const player2Rank = player2Index+1;
    const player2 = players.find((item)=>item.playerId===player2Id);
    const player2MaxTime = player2.maxTime||0;
    const player1MaxTime = player1.maxTime||0;
    const [tokenInput,setTokenInput] = useState(0);
    const currentStep = steps && steps.length?steps[steps.length-1]:null;
    const [initGame,setInitGame] = useState(false);
    const [canvasGameTableScaled,setCanvasGameTableScaled] = useState(false);
    let nbTokensTaken = 0;
    const player1Steps =  [];
    const player2Steps =  [];
    let player1Times = 0;
    let player2Times = 0;
    const [showPopover,setShowPopover] = useState(false);
    const playerBoardRef = useRef();
    const player2BoardRef = useRef();
    if (steps && steps.length){
        for (let i = 0; i < steps.length;i++){
            if (steps[i].playerId === player1Id){
                if (steps[i].times){
                    player1Times+=steps[i].times*1000;
                }
                else{
                    player1Times+=moment().valueOf()-moment(steps[i].createdAt).valueOf();
                }
            }
            else{
                if (steps[i].times){
                    player2Times+=steps[i].times*1000;
                }
                else{
                    player2Times+=moment().valueOf()-moment(steps[i].createdAt).valueOf();
                }
            }
            if (steps[i].tokens){
                for (let j=0;j<steps[i].tokens;j++){
                    if (steps[i].playerId === player1Id){
                        player1Steps.push(tokens-nbTokensTaken-j);
                    }
                    else{
                        player2Steps.push(tokens-nbTokensTaken-j);
                    }
                }
                nbTokensTaken += steps[i].tokens;
            }
        }
    }
    player1Times = player1Times/1000;
    player2Times = player2Times/1000;
    const nbTokenOnTable = tokens-nbTokensTaken;
    const maxTokenToTake = Math.min(tokens-nbTokensTaken,maxTokenPerStep);
    const isPlayer1Turn = currentStep && currentStep.playerId===player1Id;

    const dispatch = useDispatch();
    const handleQuitRoom = (e)=>{
        dispatch(quitGame());
    }

    const handleClickToken = (e,item)=>{
        if (item<=tokens-nbTokensTaken && tokens-nbTokensTaken-maxTokenPerStep<=item && (currentStep && currentStep.playerId===player1Id)){
            setTokenInput(Math.min(tokenInput+1,maxTokenPerStep));
        }
        else{
            e.preventDefault();
        }
    }
    const checkTimeUp = ()=>{
        socket.sendMessage("checkGameTimeOver",{
            gameId: game.id
        });
    }

    const [gameTable, setGameTable] = useState({});

    useEffect(()=>{
        if (!initGame){
            setInitGame(true);
            const gameConfig = {
                width: 800,
                height: 600,
                type: Phaser.AUTO,
                scale: {
                    mode: Phaser.Scale.FIT,
                    autoCenter: Phaser.Scale.CENTER_BOTH
                },
                render: {
                    antialias: false,
                    pixelArt: true,
                    roundPixels: true
                },
                physics: {
                    default: 'arcade',
                    arcade: {
                        gravity: { y: 300 },
                        debug: true
                    }
                },
                scene: MainScene
            };
            setGameTable(Object.assign({}, gameConfig))
            if (isPlayer1Turn && player1MaxTime && player1Times>player1MaxTime){
                checkTimeUp();
            }
        }
    });

    const renderButtonSteps = ()=>{
        if (maxTokenToTake<=3){
            const buttons = [];
            for (let i=1;i<=maxTokenToTake;i++){
                buttons.push(
                    <Button disabled={isPlayer1Turn===false ||!userInPlaying} onClick={(e)=>{
                        setTokenInput(i);
                        setStopTimerPlayer1(true);
                        socket.sendMessage("takeTokens",{
                            tokens: i
                        });
                    }} className={classes.takeTokensBtn} size={"small"} variant={"contained"} color={"secondary"}>{t("label.training_session.take_n_token",{tokens: i})}</Button>
                )
            }
            return buttons;
        }
        return "";
    }

    const [confirmAbandonedGame,setConfirmAbandonedGame] = useState(false);

    const abandonedGame = (e) => {
        if (userInPlaying){
            socket.sendMessage("abandonedGame",{
                gameId: game.id
            });
        }
        else{
            dispatch(quitGame());
        }
    }

    const showConfirmAbandonedGame = (e)=>{
        setConfirmAbandonedGame(true);
    }

    const hideConfirmAbandonedGame = (e)=>{
        setConfirmAbandonedGame(false);
    }

    const getMessageEndGame = ()=>{
        if (!userInPlaying){
            if (Array.isArray(winner)){
                if (status===GAME_STOPPED){
                    return "text.game.draw_message_stop_visitor"
                }
                return "text.game.draw_message_visitor";
            }
            switch (status){
                case GAME_ABANDONED:
                    return winner.playerId === player1Id?"text.game.winner_congratulation_abandoned_visitor":"text.game.looser_message_abandoned_visitor";
                case GAME_TIMEOVER:
                    return winner.playerId === player1Id?"text.game.winner_congratulation_timeover_visitor":"text.game.looser_message_timeover_visitor";
                default:
                    break;
            }
            return winner.playerId === player1Id?"text.game.winner_congratulation_visitor":"text.game.looser_message_visitor";
        }
        else{
            if (Array.isArray(winner)){
                if (status===GAME_STOPPED){
                    return "text.game.draw_message_stop"
                }
                return "text.game.draw_message";
            }
            switch (status){
                case GAME_ABANDONED:
                    return winner.playerId === player1Id?"text.game.winner_congratulation_abandoned":"text.game.looser_message_abandoned";
                case GAME_TIMEOVER:
                    return winner.playerId === player1Id?"text.game.winner_congratulation_timeover":"text.game.looser_message_timeover";
                default:
                    break;
            }
            return winner.playerId === player1Id?"text.game.winner_congratulation":"text.game.looser_message";
        }
    }
    const gameTableRef = useRef();

    const drawTokens = ()=>{
        const nbTokenLastStep = (steps && steps.length>1?steps[steps.length-2].tokens:0)||0;
        const tokenDisplayMode = gameSettings[GAME_SETTINGS_TOKEN_DISPLAY_MODE]??GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL;
        var canvas=document.getElementById("tokens-drawing");
        var ctx=canvas.getContext("2d");
        const limitWidth = Math.min(canvas.width,canvas.parentNode.offsetWidth||canvas.parentNode.width||canvas.width);
        // if (!canvasGameTableScaled && matchesXS){
        //     ctx.scale(350/640,350/640);
        //     setCanvasGameTableScaled(true);
        // }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        //center of the spiral coords:
        if (nbTokenOnTable){
            var centerX = matchesXS?120:canvas.offsetWidth/2;
            var centerY = matchesXS?150:canvas.offsetHeight/2;
            //draw the center of spiral point:
            //drawCirc(centerX, centerY, 10, '#6f0c4f');
            //start point:
            //var startY = centerY;
            if (gameSettings.showRemainingTokens){
                ctx.font = "20px Arial";
                ctx.fillStyle = "rgb(207,245,108)";
                ctx.fillText(t("label.training_session.remaining_tokens",{nb_tokens:nbTokenOnTable}), 40, 40);
            }

            let ind = 0;
            const circToDelelete = [];
            const radius = 9;
            if (tokenDisplayMode===GAME_SETTINGS_TOKEN_DISPLAY_MODE_SPIRAL){
                const startX = matchesXS?centerX+20:centerX+30;
                const gap = 9;
                const STEPS_PER_ROTATION = tokens;
                const rotations = 4;
                const increment = rotations * Math.PI / STEPS_PER_ROTATION;
                let theta = increment;
                theta = Math.abs(((centerX - startX) / Math.cos(theta)) / gap);
                while (theta < rotations * Math.PI * 4 && ind < nbTokenOnTable+nbTokenLastStep) {
                    var newX = centerX + theta * Math.cos(theta) * gap;
                    var newY = centerY + theta * Math.sin(theta) * gap;
                    // if (ind === 0) {
                    //     //draw start point with differnt color to differentiate
                    //     drawCirc(newX, newY, 10, 'orange');
                    // }
                    // else {
                    // }
                    if (ind < nbTokenOnTable){
                        drawCirc(newX, newY, radius);
                    }
                    else if (nbTokenLastStep && ind>=nbTokenOnTable){
                        circToDelelete.push([newX,newY])
                    }
                    ind++;
                    theta = theta + increment;
                }
            }
            else{
                let newX = 30;
                let newY = centerY;
                while (ind < nbTokenOnTable+nbTokenLastStep){
                    if (ind < nbTokenOnTable){
                        drawCirc(newX, newY, Math.min(limitWidth/(tokens),radius));
                    }
                    else if (nbTokenLastStep && ind>=nbTokenOnTable){
                        circToDelelete.push([newX,newY])
                    }
                    if (newX+30<limitWidth-(Math.min(limitWidth/tokens,radius)*5)){
                        newX+=Math.min(limitWidth/(tokens),radius)*2.8;
                    }
                    else{
                        newX = 30;
                        newY += Math.min(limitWidth/(tokens),radius)*2.8;
                    }
                    ind++;
                }
            }

            function drawCirc(x, y, radius = 2,opacity=1, stroke = "rgb(207,245,108)",stroke2) {
                ctx.beginPath();
                ctx.globalAlpha = opacity;
                ctx.arc(x, y, radius, 0, 2 * Math.PI);
                ctx.strokeStyle = stroke;
                ctx.stroke();
                ctx.fillStyle = stroke;
                ctx.fill();
                ctx.beginPath();
                ctx.arc(x, y, radius-5, 0, 2 * Math.PI);
                ctx.strokeStyle = stroke2||"rgba(92, 92, 92,"+opacity+")";
                ctx.stroke();
                ctx.fillStyle = stroke2||"rgba(92, 92, 92,"+opacity+")";
                ctx.fill();
            }

            circToDelelete.reverse().forEach((circle)=>{
                clearCirc(circle[0],circle[1],radius);
            });

            function clearCirc(x, y, radius= 2){
                fadeOutCirc(x,y,radius,1);
            }

            function fadeOutCirc(x,y,radius,opacity){
                drawCirc(x, y, radius+1,1,GAME_TABLE_BG,GAME_TABLE_BG);
                if (opacity >= 0){
                    drawCirc(x, y, radius,opacity,"rgba(207,245,108,"+opacity+")");
                    opacity -= 0.2;
                    setTimeout(function (){
                        fadeOutCirc(x,y,radius,opacity);
                    },100)
                }
            }

        }
    }

    const [stopTimerPlayer1,setStopTimerPlayer1] = useState(false);
    const [stopTimerPlayer2,setStopTimerPlayer2] = useState(false);
    useEffect(()=>{
        drawTokens();
        if (isPlayer1Turn){
            setStopTimerPlayer1(false);
            setStopTimerPlayer2(true);
        }
        else{
            setStopTimerPlayer2(false);
            setStopTimerPlayer1(true);
        }
    },[steps])

    return <div className={classes.container}>
        <Grid container direction={"column"}>
            {/*<h2 className={classes.gameTitle}>*/}
            {/*    /!*{t("label.board_game")} *!/*/}
            {/*</h2>*/}
            <div className={classes.gameContainer}>
                <Container maxWidth={"md"}>
                    <Grid item direction={"row"} alignItems={"flex-start"} justify={"center"} container className={classes.scores}>
                        <Grid item className={classes.playerScoreBlock} justify={"center"} alignItems={"flex-start"}>
                            <Grid item ref={playerBoardRef} alignItems={"center"} direction={"column"}  className={clsx(classes.player+" "+classes.player1+" "+classes.inlineFlex,{"active":isPlayer1Turn})}>
                                <span className={clsx(classes.nickname,{"active":isPlayer1Turn})}>{isPlayer1Turn && <UpdateRounded className={classes.turnIndicator}/>} {userInPlaying?t("label.training_session.you"):player1.player.nickname} {/*(<span className={classes.nickNameIndicator}>{t("label.training_session.you")}</span>)*/}</span>
                                {/*<span className={classes.playerRank}>{t("label.training_session.player_rank",{rank:player1Rank})} ({t("label.training_session.player_point",{count:playerRows[player1Index].points})})</span>*/}
                                <span className={classes.playerRank}>({t("label.training_session.player_point",{count:playerRows[player1Index].points})})</span>
                                {player1MaxTime>0 && <TimerCountdown setStop={setStopTimerPlayer1} stop={stopTimerPlayer1} forPlayer={player1} player1={player1} userInPlaying={userInPlaying} player2={player2} checkTimeUp={checkTimeUp} duration={player1MaxTime} winner={winner} initialRemainingTime={player1MaxTime-player1Times} t={t} otherRemainingTime={(player2MaxTime>0?player2MaxTime-player2Times:player2Times)} classes={classes} isPlayer1Turn={isPlayer1Turn}/>}
                            </Grid>
                            <Grid item className={classes.inlineFlex+" "+classes.gameInfos} alignItems={"center"} direction={"column"}>
                                <Tooltip title={t("label.training_session.game_rules")}>
                                    <RulesBtn t={t} setShowGameRules={setShowGameRules} className={classes.rulesBtn}/>
                                </Tooltip>
                                <Grid className={clsx(classes.gameMessage,{"player1Turn":isPlayer1Turn})} item>{t(isPlayer1Turn && userInPlaying?"label.training_session.your_turn":"label.training_session.player_turn",{player:isPlayer1Turn?player1.player.nickname:player2.player.nickname})}</Grid>
                                {userInPlaying && <Grid item className={classes.actions}>
                                    {renderButtonSteps()}
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item className={classes.playerScoreBlock} justify={"center"} alignItems={"flex-start"}>
                            <Grid ref={player2BoardRef} item className={clsx(classes.player+" "+classes.player2+" "+classes.inlineFlex,{"active":!isPlayer1Turn})} direction={"column"} alignItems={"center"} justify={"center"}>
                                <span className={clsx(classes.nickname,{"active":!isPlayer1Turn})}>{isPlayer1Turn===false && <UpdateRounded className={classes.turnIndicator}/>}{player2 && player2.player && player2.player.nickname?player2.player.nickname:"?"} {/*{userInPlaying && <span className={classes.nickNameIndicator}>({t("label.training_session.your_enemy")})</span>}*/}</span>
                                {/*<span className={classes.playerRank}>{t("label.training_session.player_rank",{rank:player2Rank})} ({t("label.training_session.player_point",{count:playerRows[player2Index].points})})</span>*/}
                                <span className={classes.playerRank}>({t("label.training_session.player_point",{count:playerRows[player2Index].points})})</span>
                                {player2MaxTime>0 && <TimerCountdown setStop={setStopTimerPlayer2} stop={stopTimerPlayer2} forPlayer={player2} player1={player1} userInPlaying={userInPlaying} player2={player2} checkTimeUp={checkTimeUp} duration={player2MaxTime} winner={winner} initialRemainingTime={player2MaxTime-player2Times} t={t} otherRemainingTime={(player1MaxTime>0?player1MaxTime-player1Times:player1Times)} classes={classes} isPlayer1Turn={isPlayer1Turn}/>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*{isPlayer1Turn && playerBoardRef && <Popover TransitionComponent={Fade} transformOrigin={{*/}
                    {/*    vertical: 30,*/}
                    {/*    horizontal: 'center',*/}
                    {/*}} anchorOrigin={{*/}
                    {/*    vertical: "top",*/}
                    {/*    horizontal: "center"*/}
                    {/*}} open={true} anchorEl={playerBoardRef.current}>*/}
                    {/*    {t("text.training_session.game.player2_playing")}*/}
                    {/*</Popover>}*/}
                </Container>
                <Container maxWidth={"lg"}>
                    <div className={classes.gameTable} ref={gameTableRef}>
                        {/*<div className={classes.gameTableTest}>*/}
                        {/*    {initGame && <IonPhaser game={gameTable} />}*/}
                        {/*</div>*/}
                        <canvas width={matchesXS?240:360} height={matchesXS?180:240} id={"tokens-drawing"}/>
                        {/*<Table>*/}
                        {/*    <TableBody>*/}
                        {/*        <TableRow>*/}
                        {/*            {nbTokens.length>0 && nbTokens.filter((item)=>item%2===1).map((item)=>{*/}
                        {/*                return <TableCell><span onClick={(e)=>{*/}
                        {/*                    //handleClickToken(e,item);*/}
                        {/*                }} className={clsx(classes.tokenItem,{[classes.myToken]:player1Steps.includes(item),[classes.player2Token]:player2Steps.includes(item),/*[classes.clickable]:item<=tokens-nbTokensTaken && tokens-nbTokensTaken-maxTokenPerStep<item && (currentStep && currentStep.playerId===player1Id),[classes.selectedToken]:item<=tokens-nbTokensTaken && tokens-nbTokensTaken-tokenInput<=item  && (currentStep && currentStep.playerId===player1Id)*!/)}/></TableCell>;*/}
                        {/*            })}*/}
                        {/*        </TableRow>*/}
                        {/*        <TableRow>*/}
                        {/*            {nbTokens.length>0 && nbTokens.filter((item)=>item%2===0).map((item)=>{*/}
                        {/*                return <TableCell><span onClick={(e)=>{*/}
                        {/*                    //handleClickToken(e,item);*/}
                        {/*                }} className={clsx(classes.tokenItem,{[classes.myToken]:player1Steps.includes(item),[classes.player2Token]:player2Steps.includes(item)/*,[classes.clickable]:item<=tokens-nbTokensTaken && tokens-nbTokensTaken-maxTokenPerStep<item && (currentStep && currentStep.playerId===player1Id),[classes.selectedToken]:item<=tokens-nbTokensTaken && tokens-nbTokensTaken-tokenInput<=item && (currentStep && currentStep.playerId===player1Id)*!/)}/></TableCell>;*/}
                        {/*            })}*/}
                        {/*        </TableRow>*/}
                        {/*    </TableBody>*/}
                        {/*</Table>*/}
                        {winner!==null && winner && <Dialog
                            open={true}
                            TransitionComponent={Transition}
                            keepMounted
                            className={classes.dialog}
                            onClose={(e)=>handleQuitRoom(e)}
                        >
                            <DialogTitle className={classes.popupTitle}>{t("title.end_game")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {t(getMessageEndGame(),Object.assign({player1:player1.player.nickname,player2:player2.player.nickname,nb_points_draw:nbPointsDraw,nb_points_winner:nbPointsWinner,nb_points_loser:nbPointsLoser},userInPlaying?{
                                        rank: gameRanking[player1Id]
                                    }:{}))}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant={"contained"} onClick={(e)=>handleQuitRoom(e)} type={"button"} color="secondary">
                                    {t("label.training_session.quit_room")}
                                </Button>
                            </DialogActions>
                        </Dialog>}
                    </div>
                    {confirmAbandonedGame && <Dialog
                        open={true}
                        TransitionComponent={Transition}
                        className={classes.dialog}
                        keepMounted
                        onClose={(e)=>hideConfirmAbandonedGame(e)}
                    >
                        <DialogTitle className={classes.popupTitle}>{t(userInPlaying?"title.abandoned_game":"title.exit_game")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t(userInPlaying?"text.training_session.confirm_abandoned_game":"text.training_session.confirm_abandoned_see_game")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"contained"} onClick={(e)=>{
                                hideConfirmAbandonedGame(e);
                                abandonedGame(e);
                            }} type={"button"} color="secondary">
                                <ExitToAppRounded/> {t("label.training_session.abandoned")}
                            </Button>
                            <Button variant={"contained"} onClick={(e)=>hideConfirmAbandonedGame(e)} type={"button"} color="default">
                                <CloseRounded/> {t("label.close")}
                            </Button>
                        </DialogActions>
                    </Dialog>}
                </Container>
                <Container maxWidth={"lg"}>
                    <ul className={classes.history}>
                        {steps && steps.filter((item)=>item.tokens>0).length>0 && steps.filter((item)=>item.tokens>0).reverse().slice(0, 2).map((step)=>{
                            return <li className={clsx(classes.stepMessage,{[classes.myStep]:step.playerId===player1Id,[classes.player2Step]:step.playerId!==player1Id})}>{t("text.game.user_take_tokens",{tokens:step.tokens,user:step.playerId===player1Id?player1.player.nickname:player2.player.nickname})}</li>
                        })}
                    </ul>
                </Container>
            </div>
            <Button onClick={(e)=>showConfirmAbandonedGame(e)} variant={"contained"} className={classes.abandonedBtn}><ExitToAppRounded/> {t("label.training_session.abandoned")}</Button>
        </Grid>
    </div>;
}

export default withStyles(styles)(withTranslation()(GameRoom));