import axios from "axios";
import {DEFAULT_PAGE_SIZE} from "../../constants/pagination";
import {GAME_STARTED} from "../../constants/params";

const BASE_URL = `/training-sessions`;

export const login=(payload)=>{
    return axios.post(`${BASE_URL}/${payload.trainingSessionId}/login?forMentor=${!!payload.forMentor?"1":"0"}`, payload.data||{})
    .then((res) => {
        return res.data;
    }).catch((err) => {
        console.log(err);
        if (err.response != null) {
            throw Error(err.response.data.message||"unknown");
        }
        throw Error(err);
    });
};

export const logout=(payload)=>{
    return axios.post(`${BASE_URL}/${payload.trainingSessionId}/logout`)
        .then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
            if (err.response != null) {
                throw Error(err.response.data.message||"unknown");
            }
            throw Error(err);
        });
};

export const getSession=(payload)=>{
    console.log(payload);
    return axios.get(`${BASE_URL}/${payload.id}?forMentor=${!!payload.forMentor?"1":"0"}`)
        .then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
            if (err.response != null) {
                throw Error(err.response.data.message||"unknown");
            }
            throw Error(err);
        });
};

export const getSessionPlayers=(payload)=>{
    console.log(payload);
    return axios.get(`${BASE_URL}/${payload.id}/players?forMentor=${!!payload.forMentor?"1":"0"}`, {
        id: payload.id
    })
        .then((res) => {
            return res.data;
        }).catch((err) => {
            console.log(err);
            if (err.response != null) {
                throw Error(err.response.data.message||"unknown");
            }
            throw Error(err);
        });
};

export const joinGame=(payload)=>{
    console.log(payload);
    return axios.post(`${BASE_URL}/${payload.id}/join-game`, {
        id: payload.id
    })
    .then((res) => {
        return res.data;
    }).catch((err) => {
        console.log(err);
        if (err.response != null) {
            throw Error(err.response.data.message||"unknown");
        }
        throw Error(err);
    });
};

export const getMentorStats=(payload)=>{
    return axios.get(`${BASE_URL}/${payload.id}/mentor/stats?forMentor=${!!payload.forMentor?"1":"0"}`)
        .then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response != null) {
                throw Error(err.response.data.message||"unknown");
            }
            throw Error(err);
        });
};

export const changeGameSettings=(payload)=>{
    return axios.post(`${BASE_URL}/${payload.id}/change-game-settings?forMentor=${!!payload.forMentor?"1":"0"}`, {
        settings:payload.settings
    })
        .then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response != null) {
                throw Error(err.response.data.message||"unknown");
            }
            throw Error(err);
        });
};

export const getGamesInPlaying = (payload)=>{
    return axios.get(`${BASE_URL}/${payload.id}/games?forMentor=${!!payload.forMentor?"1":"0"}`,{
        params: {
            status: GAME_STARTED
        }
    })
        .then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response != null) {
                throw Error(err.response.data.message||"unknown");
            }
            throw Error(err);
        });
}