import React from 'react';
import {List} from 'react-content-loader';
import Container from '@material-ui/core/Container';
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core";

import LinearProgress from '@material-ui/core/LinearProgress';

const useLinearIndeterminateStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export function LinearIndeterminate(props) {
    const classes = useLinearIndeterminateStyles();

    return (
        <div className={classes.root}>
            <LinearProgress {...props} />
        </div>
    );
}

const linearUseStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export function LinearDeterminate(props) {
    const classes = linearUseStyles();
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} {...props} />
        </div>
    );
}

export const ContentMultipleListLoader = () => (
    <Container maxWidth="lg">
        <List />
        <List />
        <List />
    </Container>
)

export const LoadingComponent = ()=>{
    return <Container maxWidth={"lg"}>
        <Skeleton variant="rect" width="100%" height={82} style={{marginBottom: 15}} />
        <Skeleton variant="rect" width="100%" height={82} style={{marginBottom: 15}} />
        <Skeleton variant="rect" width="100%" height={82} style={{marginBottom: 15}} />
        <Skeleton variant="rect" width="100%" height={82} style={{marginBottom: 15}} />
        <Skeleton variant="rect" width="100%" height={82} style={{marginBottom: 15}} />
        <Skeleton variant="rect" width="100%" height={82} style={{marginBottom: 15}} />
    </Container>;
}

const loadingCircularStyles = {
    mainBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: "#5b5b5ba6"
    },
    backgroundContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }
};

const loadingCircularUseStyles = makeStyles(loadingCircularStyles);

export const LoadingCircular = ()=>{
    const classes = loadingCircularUseStyles();
    return <div className={classes.mainBackground}>
        <div className={classes.backgroundContent}>
            <CircularProgress />
        </div>
    </div>;
}