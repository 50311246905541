import React, {Fragment, lazy, Suspense, useEffect} from 'react';
import './App.css';
import {Container, MuiThemeProvider, Snackbar, withStyles} from "@material-ui/core";
import {theme} from "./themes/muiTheme";
import { Route, Switch,Redirect } from 'react-router';
import {ContentMultipleListLoader, LoadingCircular, LoadingComponent} from './components/loading/loaders';
import Error404 from "./components/exception/Error404";
import Menu from "./components/menu/Menu";
import {CssBaseline} from "@material-ui/core";
import * as links from "./constants/links";
import {useDispatch, useSelector} from "react-redux";
import * as appActions from "./sagas/app/actions";
import {Alert} from "@material-ui/lab";
import {withTranslation} from "react-i18next";
import axios from "axios";
import {quit} from "./sagas/trainingSession/actions";
import {routes} from "./constants/routes";
import {Copyright} from "./components/layout/Footer";
import * as colors from "./constants/colors";
import {LOGIN} from "./constants/links";
import AdminHome from "./components/admin/AdminHome";


const Home = lazy(() => import("./components/home/Home"));

const styles = {
  content: {
    marginTop: "64px",
    minHeight: "calc(100% - 50px)",
    overflow: "hidden",
    paddingBottom: 20
  },
  footer:{
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.THIRD_COLOR
  }
};
function App(props){
  const {t,classes} = props;
  const dispatch = useDispatch();
  const {loading,snackbar} = useSelector(state=>state.appState);
  const {user,trainingSession} = useSelector(state=>state.trainingSessionState);
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log(error);
    if (error.response.status === 401) {
      if (!!user){
        dispatch(appActions.snackbarError("text.error.authentication_required"))
      }
      dispatch(quit({
        trainingSessionId: trainingSession.id
      }));
    }
    else if (error.response.status === 403) {
      dispatch(appActions.snackbarError({
        "message": "text.error.access_forbidden"
      }));
    }
    return Promise.reject(error);
  });
  return <MuiThemeProvider theme={theme}>
    <CssBaseline />
      <div id={"bg-image"}/>
      {loading && <LoadingCircular/>}
    {!!snackbar.success.show && <Snackbar anchorOrigin={{vertical:"top",horizontal:"center"}} open={true} onClose={(e)=>{
      if (typeof snackbar.success.callbackOnClose === "function"){
        snackbar.success.callbackOnClose();
      }
      dispatch(appActions.snackbarSuccess(false))
    }} autoHideDuration={5000}>
        <Alert severity={"success"}>
          {t(snackbar.success.message || "text.success.default")}
        </Alert>
      </Snackbar>}
    {!!snackbar.error.show && <Snackbar anchorOrigin={{vertical:"top",horizontal:"center"}} open={true} onClose={(e)=>{
      if (typeof snackbar.error.callbackOnClose === "function"){
        snackbar.error.callbackOnClose();
      }
      dispatch(appActions.snackbarError(false));
    }} autoHideDuration={5000}>
      <Alert severity={"error"}>
        {t(snackbar.error.message || "text.error.default")}
      </Alert>
    </Snackbar>}
      <Menu/>
      <Container maxWidth={"xl"} className={classes.content}>
        <Suspense fallback={<LoadingComponent/>}>
          <Switch>
            {routes.length>0 && routes.map((route)=>{
              return <Route exact={!!route.exact} path={route.path} component={route.component}/>;
            })}
            <Route exact path={"/hex"} render={()=>{
              window.location.href = "https://hex.plaisir-maths.hsngdev.com/training-session/aiforleaders";
              return null;
            }}/>;
            <PrivateRoute exact path={links.HOME} component={Home} />
            <PrivateRoute exact={false} path={links.BACKOFFICE} component={AdminHome} />
            <Route component={Error404} />
          </Switch>
        </Suspense>
      </Container>
      <Container maxWidth={false} className={classes.footer}>
        <Copyright />
      </Container>
  </MuiThemeProvider>;
}

function PrivateRoute ({component: Component, ...rest}) {
  const {authenticated} = useSelector((state)=>state.authState);
  return (
      <Route
          {...rest}
          render={(props) => {
            console.log(props);
            return authenticated === true
                ? <Component {...props} />
                : <Redirect to={{pathname: LOGIN, state: {from: props.location}}} />
          }}
      />
  )
}

export default withStyles(styles)(withTranslation()(App));
