import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import {useMediaQuery, withStyles} from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {HomeRounded, PersonRounded} from "@material-ui/icons";

const styles = {

}

function Menu(props){
    const { onMenuClick, logout,t } = props;
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div>
            <MenuItemLink
                key={"dashboard"}
                to={`/`}
                primaryText={t("backoffice.menu.dashboard")}
                leftIcon={<HomeRounded/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                key={"profile"}
                to={`/profile`}
                primaryText={t("backoffice.menu.profile")}
                leftIcon={<PersonRounded/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options && resource.options.label || resource.name}
                    leftIcon={resource.icon?resource.icon:null}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {isXSmall && logout}
        </div>
    );
}

export default withRouter(withStyles(styles)(withTranslation()(Menu)));
