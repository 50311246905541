import { put, takeEvery, all,call,takeLatest,select } from 'redux-saga/effects';
import {LOGIN, LOGOUT} from "./constants";
import * as apis from "./apis";
import { push } from 'connected-react-router';
import * as links from "../../constants/links";
import {snackbarError, snackbarSuccess} from "../app/actions";

function* handler(action,api,data) {
    try {
        const resJson = yield call(api,data.payload);
        yield put({ type: action.SUCCESS, payload: { data: resJson } });
        // if (action===LOGIN){
        //     console.log(resJson.nextUrlAfterLogin);
        //     if (resJson.nextUrlAfterLogin && resJson.nextUrlAfterLogin!==links.LOGIN){
        //         yield put(push(resJson.nextUrlAfterLogin));
        //     }
        //     else{
        //         yield put(push(links.HOME));
        //     }
        // }

    } catch (err) {
        yield put({ type: action.FAIL, payload: { error: err.message } });
    }
}

function* showLoginError(){
    const {loginAuto} = yield select((state)=>state.authState);
    console.log(loginAuto);
    if (!loginAuto){
        yield put(snackbarError({
            message: "text.error.login"
        }));
    }

}

function* callGenerator(action,api) {
    yield takeEvery(action.REQUEST, handler,action,api);
    yield takeEvery(action.FAIL, showLoginError);
}

function * logoutUser(){
    try{
        yield call(apis.logout);
        yield put({type: LOGOUT.SUCCESS});
        yield put(push(links.HOME));
    }
    catch (err){
        yield put({type: LOGOUT.FAIL});
    }
}

function* logoutSaga() {
    yield takeLatest(LOGOUT.REQUEST,logoutUser);
}


export default function* authSaga() {
    yield all([
        callGenerator(LOGIN,apis.login),
        logoutSaga()
    ]);
}