import {withRouter} from "react-router";
import {Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {Transition} from "../../../../themes/effects";
import React, {useEffect, useRef} from "react";
import {ReactComponent as PodiumIcon} from "../../../../images/podium.svg";
import {useSelector} from "react-redux";
import * as _ from "lodash";
import AppAvatar from "../../../../themes/components/AppAvatar";
import clsx from "clsx";

const styles = {
    podium:{
        position: "relative",
        width: "100%",
        maxWidth: "600px",
        color: "black",
        fontSize: "1.2rem",
        "& span":{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        }
    },
    player1:{
        position: "absolute",
        top: "48%",
        left: "32%",
        width: "35%",
        textAlign: "center",

    },
    player2:{
        position: "absolute",
        top: "64%",
        left: "5%",
        width: "27%",
        textAlign: "center"
    },
    player3:{
        position: "absolute",
        top: "70%",
        right: "7%",
        width: "27%",
        textAlign: "center"
    },
    player:{
        display:"flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#061d24",
        "&>span":{
            width: "90%"
        }
    },
    minSize:{
        width: 350
    }
};

function Podium(props){
    const {t,classes} = props;
    const {players} = useSelector((state)=>state.trainingSessionState);
    const playerRows = _.orderBy(players.items.map((player)=>{
        const {scores,nickname,id,status} = player;
        return {
            ...scores,
            nickname,
            id,
            status
        };
    }),["points","wins","matches","loses","nickname"],["desc","desc","asc","asc","asc"]);
    let player1,player2,player3;
    if (playerRows.length>0){
        player1 = playerRows[0];
    }
    if (playerRows.length>1){
        player2 = playerRows[1];
    }
    if (playerRows.length>2){
        player3 = playerRows[2];
    }
    const podiumRef = useRef();
    const theme = useTheme();
    const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    useEffect(()=>{
        if (podiumRef && podiumRef.current){
            const podiumElem = podiumRef.current;
            if (matchesXS){
                podiumElem.style.width = 350;
            }
            else{
                podiumElem.style.width = 600;
            }
        }
    });
    return <div className={clsx(classes.podium,{[classes.minSize]:matchesXS})} ref={podiumRef}>
        <PodiumIcon width={"100%"}/>
        {<div className={classes.player+" "+classes.player1}><AppAvatar size={"2.5rem"} title={!!player1?t("label.training_session.player_point",{count: player1.points}):""} color={"#FFEF00"} fgColor={"black"} name={"1"} />{!!player1 && <React.Fragment><span className={classes.nickname}>{player1.nickname}</span>{!matchesXS && <span className={classes.nbPoints}>{t("label.training_session.player_point",{count: player1.points})}</span>}</React.Fragment>}</div>}
        {<div className={classes.player+" "+classes.player2}><AppAvatar size={"2.5rem"} title={!!player2?t("label.training_session.player_point",{count: player2.points}):""} color={"#EEEEEE"} fgColor={"black"} name={"2"} />{!!player2 && <React.Fragment><span className={classes.nickname}>{player2.nickname}</span> {!matchesXS && <span className={classes.nbPoints}>{t("label.training_session.player_point",{count: player2.points})}</span>}</React.Fragment>}</div>}
        {<div className={classes.player+" "+classes.player3}><AppAvatar size={"2.5rem"} title={!!player3?t("label.training_session.player_point",{count: player3.points}):""} color={"#FCA53A"} fgColor={"black"} name={"3"} />{!!player3 && <React.Fragment><span className={classes.nickname}>{player3.nickname}</span> {!matchesXS && <span className={classes.nbPoints}>{t("label.training_session.player_point",{count: player3.points})}</span>}</React.Fragment>}</div>}
    </div>;
}

export default withRouter(withStyles(styles)(withTranslation()(Podium)));
