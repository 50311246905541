import axios from "axios";

const BASE_URL = `/users`;

export const login=(payload)=>{
    return axios.post(`${BASE_URL}/login`, payload)
    .then((res) => {
        if (payload.nextUrlAfterLogin){
            console.log(payload.nextUrlAfterLogin);
            res.data.nextUrlAfterLogin = payload.nextUrlAfterLogin;
        }
        return res.data;
    }).catch((err) => {
        if (err.response != null) {
            throw Error(err.response.data.message||"error");
        }
        throw Error(err);
    });
}

export const logout=()=>{
    return axios.post(`${BASE_URL}/logout`)
        .then((res) => {
            return res.data;
        }).catch((err) => {
            if (err.response != null) {
                throw Error(err.response.data.message||"error");
            }
            throw Error(err);
        });
}