import {
   TRAINING_SESSION_LOGIN,
   TRAINING_SESSION_GET,
   TRAINING_SESSION_QUIT,
   TRAINING_SESSION_GET_PLAYERS,
   TRAINING_SESSION_JOIN_GAME,
   TRAINING_SESSION_INIT_SOCKET,
   TRAINING_SESSION_UPDATE_PLAYERS,
   TRAINING_SESSION_START_GAME,
   TRAINING_SESSION_QUIT_GAME,
   TRAINING_SESSION_CHANGE_STATUS,
   TRAINING_SESSION_MENTOR_STATS,
   TRAINING_SESSION_GOTO_NEW,
   TRAINING_SESSION_TOGGLE_STATS_DIALOG,
   TRAINING_SESSION_GAME_SETTINGS_CHANGE,
   TRAINING_SESSION_MATCHES_IN_PLAYING,
   TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM, TRAINING_SESSION_RESET
} from "./constants";
import moment from "moment";

export const login = (payload) => ({
   type: TRAINING_SESSION_LOGIN.REQUEST,
   payload
});

export const quit = (payload) => ({
   type: TRAINING_SESSION_QUIT.REQUEST,
   payload
});

export const quitGame = (payload) => ({
   type: TRAINING_SESSION_QUIT_GAME,
   payload
});

export const getSession = (payload) => ({
   type: TRAINING_SESSION_GET.REQUEST,
   payload
});

export const getPlayers = (payload)=>({
   type: TRAINING_SESSION_GET_PLAYERS.REQUEST,
   payload
});

export const updatePlayers = (payload)=>({
   type: TRAINING_SESSION_UPDATE_PLAYERS,
   payload
});

export const joinGame = (payload) => ({
   type: TRAINING_SESSION_JOIN_GAME.REQUEST,
   payload
});

export const initSocket = (payload) => ({
   type :TRAINING_SESSION_INIT_SOCKET,
   payload
});

export const startGame = (payload) => ({
   type: TRAINING_SESSION_START_GAME,
   payload
});

export const changeSessionStatus = (payload)=>({
   type: TRAINING_SESSION_CHANGE_STATUS,
   payload
});


export const loadMentorStats = (payload)=>({
   type :TRAINING_SESSION_MENTOR_STATS.REQUEST,
   payload
});

export const gotoNewSession = (payload)=>({
   type: TRAINING_SESSION_GOTO_NEW,
   payload
});

export const toggleStatsDialog = (payload)=>({
   type: TRAINING_SESSION_TOGGLE_STATS_DIALOG,
   payload
});

export const updateGameSettings = (payload)=>({
   type: TRAINING_SESSION_GAME_SETTINGS_CHANGE.REQUEST,
   payload
})

export const getGamesInPlaying = (payload)=>({
   type: TRAINING_SESSION_MATCHES_IN_PLAYING.REQUEST,
   payload
});

export const addMessageToWaitingRoom = (payload)=>({
   type: TRAINING_SESSION_ADD_MESSAGE_WAITING_ROOM,
   payload
})

export const resetTrainingSession = ()=>({
   type :TRAINING_SESSION_RESET
});