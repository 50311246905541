import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    TableCell,
    CircularProgress,
    Grid,
    ListItem,
    Tooltip, Button, Box, Tab, Tabs, List, IconButton
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {getGamesInPlaying, getPlayers, toggleStatsDialog} from "../../../../sagas/trainingSession/actions";
import Alert from '@material-ui/lab/Alert';
import {
    GAME_SETTINGS_STATS_AI,
    GAME_SETTINGS_STATS_OTHER_PLAYERS,
    GAME_SETTINGS_STATS_PERSONAL, GAME_SETTINGS_STATS_SESSION,
    MENTOR,
    PLAYER_STATUS_DISCONNECTED
} from "../../../../constants/params";
import {
    AccountCircleRounded,
    BarChart, ClassRounded,
    GraphicEqRounded, HelpRounded, PanoramaFishEyeRounded,
    PeopleAltRounded,
    PersonRounded,
    ShowChartRounded, VisibilityRounded
} from "@material-ui/icons";
import * as _ from "lodash";
import * as colors from "../../../../constants/colors";
import StatsTable from "../stats/StatsTable";
import clsx from "clsx";
import {withRouter} from "react-router";

const styles = {
    paper:{
        background: "#DB9A5B",
        minWidth: 300,
        padding: 0,
        overflow: "hidden",
        position: "relative"
    },
    tableContainer:{
        height: "500px",
        width: "calc(100% - 2rem)",
        overflow: "auto",
        position: "relative",
        margin: "3rem auto 1rem",
        background: "rgb(92, 92, 92)",
    },
    tableContainerNoStats:{
        margin: "1rem auto",
    },
    table:{
        tableLayout: "fixed",
        "& thead tr th":{
            fontWeight: "bold",
            fontSize: "1rem",
            color:"#ffffff",
            backgroundColor: "#313131"
        },
        "& tbody tr":{
            background: "#575454",
            "&:nth-child(2n+1)":{
                background: "#a9a0a0"
            }
        },
        "& th, & td":{
            padding: 5,
            color: "white"
        },
        "& td:first-child,& th:first-child":{
            width: "100px",
        }
    },
    playerIcon:{
        width: "1.5rem"
    },
    nickname:{
        display:"inline-block",
        width: "calc(100% - 2rem)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    statsBlock:{
        position: "absolute",
        top: 5,
        left: 5
    },
    myRow:{
        backgroundColor: colors.PRIMARY_COLOR_LIGHT+" !important",
        "& td":{
            fontWeight :"bold"
        }
    },
    tabs:{

    },
    gameRow:{
        background: "#302f2f",
        color: "white",
        justifyContent:"center",
        marginBottom: "1rem",
        "&:nth-child(2n)":{
            background: "#335260",
        }
    },
    player1Name:{
        color:colors.PRIMARY_COLOR_LIGHT,
        fontWeight: "bold",
    },
    player2Name:{
        color:colors.SECONDARY_COLOR_LIGHT,
        fontWeight: "bold",
    },
    versusGame:{
        display: "inline-flex",
        width: "auto"
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scoreBoard-tabpanel-${index}`}
            aria-labelledby={`stats-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={"tab-content"}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scoreBoard-tab-${index}`,
        'aria-controls': `scoreBoard-tabpanel-${index}`,
    };
}

function ScoreBoard(props){
    const {classes,t} = props;
    const dispatch = useDispatch();
    const {players,stats,user,trainingSession,gamesInPlaying,waitingRoom,gameRoom,socket} = useSelector((state)=>state.trainingSessionState);
    const gamesInPlayingItems = gamesInPlaying.items;
    console.log(gamesInPlaying);
    const gamesInPlayingLoading = gamesInPlaying.isLoading;
    const {gameSettings} = trainingSession;
    const showStatsDialog = !!stats.showStatsDialog;
    const statsKeys = [GAME_SETTINGS_STATS_PERSONAL,GAME_SETTINGS_STATS_OTHER_PLAYERS,GAME_SETTINGS_STATS_AI,GAME_SETTINGS_STATS_SESSION]
    const canShowStats = user.type===MENTOR || Object.keys(gameSettings||{}).filter((statKey)=>statsKeys.includes(statKey)).some((key)=>!!gameSettings[key]);
    const forMentor = user && user.type === MENTOR;
    const playerRows = _.orderBy(players.items.map((player)=>{
        const {scores,nickname,id,status} = player;
        return {
           ...scores,
            nickname,
            id,
            status
       };
    }),["points","wins","matches","nickname","loses"],["desc","desc","desc","asc","asc"]);
    const [selectedTab,setSelectedTab] = useState(0);
    const changeTab = (e, newValue)=>{
        setSelectedTab(newValue);
    }
    useEffect(()=>{
        if (gamesInPlayingLoading===null){
            dispatch(getGamesInPlaying({
                id: trainingSession.id,
                forMentor
            }));
        }
    },[]);
    const inPlaying = gameRoom && gameRoom.players && gameRoom.players.map((player)=>player.playerId).includes(user.id);
    const canSeeOtherMatch = (waitingRoom && !waitingRoom.inSearching) && !inPlaying;
    const handleSeeGame = (game)=>{
        socket.sendMessage("seeGame",{
            gameId: game.id
        });
    }
    return (
        <TableContainer className={classes.paper} component={Paper}>
            {canShowStats && <div className={classes.statsBlock}>
                <Button margin={"dense"} variant={"contained"} color={"primary"} onClick={(e)=>{
                    dispatch(toggleStatsDialog(true));
                }}><ShowChartRounded/> {t("label.training_session.stats")}</Button>
                {showStatsDialog && <StatsTable/>}
            </div>}
            <div className={clsx(classes.tableContainer,{[classes.tableContainerNoStats]:!canShowStats})}>
                <Tabs onChange={changeTab} className={classes.tabs} value={selectedTab} aria-label="simple tabs example">
                    <Tab label={t("label.training_session.score_board.rank")} {...a11yProps(0)} />
                    <Tab label={t("label.training_session.score_board.match_in_process")} {...a11yProps(1)} />
                </Tabs>
                <TabPanel container value={selectedTab} index={0}>
                    <Table stickyHeader className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("label.training_session.score_board.nickname")}</TableCell>
                                {/*<TableCell align="right"><Tooltip title={t("label.training_session.score_board.wins")}><span>{t("label.training_session.score_board.short_key.wins")}</span></Tooltip></TableCell>*/}
                                {/*<TableCell align="right"><Tooltip title={t("label.training_session.score_board.draws")}><span>{t("label.training_session.score_board.short_key.draws")}</span></Tooltip></TableCell>*/}
                                {/*<TableCell align="right"><Tooltip title={t("label.training_session.score_board.loses")}><span>{t("label.training_session.score_board.short_key.loses")}</span></Tooltip></TableCell>*/}
                                <TableCell align="right"><Tooltip title={t("label.training_session.score_board.played_games")}><span>{t("label.training_session.score_board.short_key.matches")}</span></Tooltip></TableCell>
                                <TableCell align="right"><Tooltip title={t("label.training_session.score_board.points")}><span>{t("label.training_session.score_board.short_key.points")}</span></Tooltip></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {players.isLoading && <TableRow><TableCell colSpan={6}><CircularProgress/></TableCell></TableRow>}
                            {!!players.error && <TableRow><TableCell colSpan={6}><Alert severity="error">{t("text.training_session.score_board_unavailable")}</Alert></TableCell></TableRow>}
                            {!players.error && playerRows.length>0 && playerRows.map((playerRow) => {
                                console.log(playerRow);
                                const {id,nickname,status,wins,loses,matches,points,draws} = playerRow;
                                return <TableRow key={id} className={clsx(classes.row,{[classes.myRow]:user.id===id})}>
                                    <TableCell>
                                        <Grid container alignItems={"center"} spacing={1}>
                                            {<Tooltip title={t(status!==PLAYER_STATUS_DISCONNECTED?"label.training_session.score_board.online":"label.training_session.score_board.offline")}><AccountCircleRounded className={classes.playerIcon} style={{color:status!==PLAYER_STATUS_DISCONNECTED?"green":"grey"}}/></Tooltip>}
                                            <span className={classes.nickname}>{nickname}</span>
                                        </Grid>
                                    </TableCell>
                                    {/*<TableCell align="right">{wins||0}</TableCell>*/}
                                    {/*<TableCell align="right">{draws||0}</TableCell>*/}
                                    {/*<TableCell align="right">{loses||0}</TableCell>*/}
                                    <TableCell align="right">{matches||0}</TableCell>
                                    <TableCell align="right">{points || 0}</TableCell>
                                </TableRow>;
                            })}
                            {!players.error && playerRows.length===0 && <TableRow key={"no-result"}><TableCell colSpan={3}>{t("text.list.no_results")}</TableCell></TableRow>}
                        </TableBody>
                    </Table>
                </TabPanel>
                <TabPanel container value={selectedTab} index={1}>
                    <List component={Grid} container direction={"column"} dense>
                        {gamesInPlayingItems && gamesInPlayingItems.length>0 && playerRows && playerRows.length>0 ? gamesInPlayingItems.map((game)=>{
                            const gamePlayers = game.gamePlayers.map((item)=>item.playerId);
                            const player1Id = gamePlayers[0];
                            const player2Id = gamePlayers[1];
                            const player1 = playerRows.find((player)=>player.id===player1Id);
                            const player2 = playerRows.find((player)=>player.id===player2Id);
                            return <ListItem component={Grid} className={classes.gameRow} justify={"center"} alignItems={"flex-start"} spacing={1} container key={game.id}>
                                <Grid item className={classes.player1Name}>{player1.nickname||""}</Grid>
                                <Grid item container className={classes.versusGame} direction={"column"} alignItems={"center"}>
                                    {t("label.game.versus")}
                                    {canSeeOtherMatch && <Tooltip title={t("label.training_session.see_game")}><IconButton size={"small"} onClick={(e)=>handleSeeGame(game)}><VisibilityRounded/></IconButton></Tooltip>}
                                </Grid>
                                <Grid item className={classes.player2Name}>{player2.nickname||""}</Grid>
                            </ListItem>
                        }):<ListItem>{t("text.training_session.no_games_in_playing")}</ListItem>}
                    </List>
                </TabPanel>
            </div>
        </TableContainer>
    );
}

export default withStyles(styles)(withTranslation()(ScoreBoard));